import React, {useContext, useState} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import '../../styles/Slider.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GlobalStateContext} from "../../context/index";

const ApplicationsSlider = ({formData,setFormData}) => {

    const {legalStatus,submission_location} = useContext(GlobalStateContext)
    const [applicationType, setApplicationType] = useState([
        {id: 1, name: "New Application"},
        {id: 2, name: "Lost"},
        {id: 3, name: "Damaged"},
        {id: 4, name: "Renewal"},
        {id: 6, name: "Change Of Details"},
    ]);

    return <>
        <div className="slider-container">
            <Row className="slider-body">
                <Col md={2}>
                    <label className="slider-label">Legal Status</label> 
                    
                    {/* <p className="slider-text">{formData.legal_status}</p> */}
                </Col>
                <Col md={2}>
                    <p className="slider-text">
                        {(() => {
                            for (var i=0; i<legalStatus.length; i++) {
                                
                                if (legalStatus[i].id == formData.legal_status){
                                    return (
                                        <>{legalStatus[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                    </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Type of Application</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">
                    {/* {formData.application_type} */}
                    {(() => {
                        for (var i=0; i<applicationType.length; i++) {
                            
                            if (applicationType[i].id == formData.application_type){
                                return (
                                    <>{applicationType[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                
                </p>

                </Col>
                {/* <Col md={2}>
                    <label className="slider-label">Place Of Submission</label> 
                    <p className="slider-text">{formData.place_of_submission}</p>
                </Col> */}
                {/* <Col md={2}>
                    <p className="slider-text">
                        {(() => {
                            for (var i=0; i<submission_location.length; i++) {
                                
                                if (submission_location[i].id == formData.place_of_submission){
                                    return (
                                        <>{submission_location[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        </p>
                </Col> */}
                <Col md={2}>
                    <label className="slider-label">Details of old/lost CTD</label> 
                </Col>
                <Col md={2}>
                    <p className="slider-text">{formData.previous_travel_document_number}</p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Place of Issue</label> 
                    
                    {/* <p className="slider-text">{formData.place_of_issue}</p> */}
                </Col>
                <Col md={2}>
                    <p className="slider-text">
                        {(() => {
                            for (var i=0; i<submission_location.length; i++) {
                                
                                if (submission_location[i].id == formData.place_of_issue){
                                    return (
                                        <>{submission_location[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        </p>
                </Col>
            </Row>
        </div>
    </>;
}



export default ApplicationsSlider;