import React from 'react';
import "../../../styles/Portal.css";
import "../../../styles/querysteps.css";

const Mothers = () => {
    return <>
    <div className="row mx-1">
    <table className="query-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Applicant Details</th>
                        <th>OPM Details</th>
                    </tr>
                </thead>
                <tbody>
                    
                    <tr>
                        <td className="query-side-label">Surname</td>
                        <td>Olivia</td>
                        <td>Olivia</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Given Name</td>
                        <td>Esther</td>
                        <td>Esther</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Status</td>
                        <td>Alive</td>
                        <td>Alive</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Nationality</td>
                        <td>Kenyan</td>
                        <td>Kenyan</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Country Of Residence</td>
                        <td>Kenyan</td>
                        <td>Kenyan</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">District</td>
                        <td>Some district</td>
                        <td>Some district</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">County</td>
                        <td>Some county</td>
                        <td>Some county</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Sub County</td>
                        <td>Some sub county</td>
                        <td>Some sub county</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Tribe</td>
                        <td>Some tribe</td>
                        <td>Some tribe</td>
                    </tr>
                </tbody>
            </table>
            {/* <div className="col-md-4 query-body-header">
            </div>
            <div className="col-md-4 query-body-header">
                Applicant Details
            </div>
            <div className="col-md-4 query-body-header">
                OPM Details
            </div>
            <div className="col-md-4 query-side-label">
                Surname
            </div>
            <div className="col-md-4">
                Olivia
            </div>
            <div className="col-md-4">
                Olivia
            </div>
            <div className="col-md-4 query-side-label">
                Given Name
            </div>
            <div className="col-md-4">
                Esther
            </div>
            <div className="col-md-4">
                Esther
            </div>
            <div className="col-md-4 query-side-label">
                Status
            </div>
            <div className="col-md-4">
                Alive
            </div>
            <div className="col-md-4">
                Alive
            </div>
            <div className="col-md-4 query-side-label">
                Nationality
            </div>
            <div className="col-md-4">
                Kenyan
            </div>
            <div className="col-md-4">
                Kenyan
            </div>
            <div className="col-md-4 query-side-label">
                Birth Country
            </div>
            <div className="col-md-4">
                Kenyan
            </div>
            <div className="col-md-4">
                Kenyan
            </div>
            <div className="col-md-4 query-side-label">
                Country Of Residence
            </div>
            <div className="col-md-4">
                Kenyan
            </div>
            <div className="col-md-4">
                Kenyan
            </div>
            <div className="col-md-4 query-side-label">
                District
            </div>
            <div className="col-md-4">
                Some district
            </div>
            <div className="col-md-4">
                Some district
            </div>
            <div className="col-md-4 query-side-label">
                County
            </div>
            <div className="col-md-4">
                Some county
            </div>
            <div className="col-md-4">
                Some county
            </div>
            <div className="col-md-4 query-side-label">
                Sub County
            </div>
            <div className="col-md-4">
                Some sub county
            </div>
            <div className="col-md-4">
                Some sub county
            </div>
            <div className="col-md-4 query-side-label">
                Tribe
            </div>
            <div className="col-md-4">
                Some tribe
            </div>
            <div className="col-md-4">
                Some tribe
            </div> */}
            
    </div>
    
</>;;
}


export default Mothers;