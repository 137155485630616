import React, {useState, useEffect, useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import axios from 'axios';
import constants from '../../constants/constants';
import {GlobalStateContext} from "../../context/index";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-date-picker';
import { StringToDateConverter } from '../../services/Services';
import { telephoneChecker, emailChecker } from '../../services/Services';



const SpouseForm = ({formData,setFormData, residentialform, setResidentialform}) => {

    const [data, setData] = useState({"date": ""})
    const [nationality, setNationality] = useState([]);
    const {emailvalidation, setEmailValidation, telephoneValidation, settelephoneValidation,telephoneCheck, NameToIDAsync} = useContext(GlobalStateContext);

    useEffect(() => {
        const fetchNationality = async () => {
            const response = await axios.get(constants.url+"/common/nationalities/")
            setNationality(response.data)

            if (formData.spouse_nationality === "" || formData.spouse_nationality === null) {
                var x = 0
            } else {
                var data = await NameToIDAsync(formData.spouse_nationality, response.data)
                setFormData({...formData, spouse_nationality: data})

            }
        }
        fetchNationality()
    }, [])

    const [phonestatus, setPhonestatus] = useState(false);
    const [emailstatus, setEmailstatus] = useState(false);
    const [phonetwostatus, setPhonetwostatus] = useState(false);

    const validatephone = (phone) => {
        var check = telephoneChecker(phone)
        setPhonestatus(check)
    }

    const validatephonetwo = (phone) => {
        var check = telephoneChecker(phone)
        setPhonetwostatus(check)
    }

    const validateEmail = (email) => {
        var check = emailChecker(email)
        setEmailstatus(check)
    }

    useState(() => {
        const nationalitychecker = async () => {
            // Check if spouce nationality is not empty or null
            if (formData.spouse_nationality === "" || formData.spouse_nationality === null) {
                console.log(formData.spouse_nationality)
            } else {
                console.log(await NameToIDAsync(formData.spouse_nationality))
                setFormData({...formData, spouse_nationality: await NameToIDAsync(formData.spouse_nationality, nationality)})
            }
        }
        nationalitychecker()
    }, [])


    return <div className="form-container">
        <Container>
            <Row>
                <Col md={4}>

                    <div className="form-group required">
                        <label className="mylabel control-label">Surname</label>
                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.spouse_surname} 
                        onChange={(event) => setFormData({...formData, spouse_surname: event.target.value})}/>
                        { residentialform.spouse_surname ? <p className="error-message"> Surname Required</p> : null}
                    </div>

                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Given Name</label>
                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.spouse_given_name} 
                        onChange={(event) => setFormData({...formData, spouse_given_name: event.target.value})}/>
                        { residentialform.spouse_given_name ? <p className="error-message"> Other Names Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Other Names</label>
                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.spouse_maiden_names} 
                        onChange={(event) => setFormData({...formData, spouse_maiden_names: event.target.value})}/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Date of Marriage</label>
                        
                        <DatePicker 
                            // {`form-control myinput ${applicantform.date_of_birth ? 'error': ''}`}
                            className="form-control p-0 myinput"
                            value={StringToDateConverter(formData.date_of_marriage)}
                            onChange={(date) => setFormData({...formData, date_of_marriage: date})}
                            format="dd-MM-y"
                            minDate={new Date('1900-01-01')}
                            maxDate={new Date('2050-01-01')}
                            placeholderText={'dd-MM-yyyy'} 
                            clearIcon={null}
                        />
                        { residentialform.date_of_marriage ? <p className="error-message"> Date Of Marriage Required</p> : null}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group">
                        <label className="mylabel control-label">Place Of Marriage</label>
                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.place_of_marriage} 
                        onChange={(event) => setFormData({...formData, place_of_marriage: event.target.value})}/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Nationality</label>
                        <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                            value={formData.spouse_nationality} 
                            onChange={(event) => setFormData({...formData, spouse_nationality: event.target.value})}>
                                <option selected>-------</option>
                                {nationality.map((option, index) => (
                                        <option key={index} value={option.id}>{option.name}</option>
                                ))}
                        </select>
                        { residentialform.spouse_nationality ? <p className="error-message">Nationality Required</p> : null}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Telephone Number</label>
                        <PhoneInput
                            country={'ug'}
                            inputClass={`phone-input ${phonestatus ? 'error': ''}`}
                            onBlur={(event) => validatephone(event.target.value)}
                            value={formData.spouse_primary_telephone_number} 
                            onChange={(event) => setFormData({...formData, spouse_primary_telephone_number: event})}
                            />
                        { residentialform.spouse_primary_telephone_number ? <p className="error-message">Primary Telephone Number Required</p> : null}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group">
                        <label className="mylabel control-label">Other Telephone Number</label>
                        <PhoneInput
                            country={'ug'}
                            inputClass={`phone-input ${phonetwostatus ? 'error': ''}`}
                            onBlur={(event) => validatephonetwo(event.target.value)}
                            value={formData.spouse_other_telephone_number} 
                            onChange={(event) => setFormData({...formData, spouse_other_telephone_number: event})}
                            />
                       
                    </div>
                </Col>
                <Col md={6}>

                    <div className="form-group">
                        <label className="mylabel control-label">Email Addreess</label>
                        <input placeholder="example@email.com" type="text" className={`form-control myinput ${emailstatus ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.spouse_email_address} 
                        onChange={(event) => setFormData({...formData, spouse_email_address: event.target.value})}
                        onBlur={(event) => validateEmail(event.target.value)}/>
                    </div>
                    
                </Col>
            </Row>
        </Container>
    </div>;
    
}



export default SpouseForm;