import React, {useState, useEffect, useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
import constants from '../../constants/constants';
import axios from 'axios';
import {GlobalStateContext} from "../../context/index";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { telephoneChecker, emailChecker } from '../../services/Services';
import { GetCounties, GetSubCounties, GetParish, GetVillages } from "../../services/Services";


const MothersForm = ({formData,setFormData, motherform, setmotherform}) => {

    // const [nationality, setNationality] = useState([]);
    // const [countries, setCountries] = useState([]);
    const {
        emailvalidation, setEmailValidation, 
        telephoneValidation, 
        settelephoneValidation,telephoneCheck,
        districts, setDistricts, counties, 
        setCounties, subcounties, setSubcounties, 
        parishes, setParish, villages, setVillages, settlements,
        NameToID, nationality, countries
    } = useContext(GlobalStateContext);

    const [address, setAddress] = useState(false);
    const [addressout, setAddressout] = useState(false);

    useEffect(() => {
        // axios.get(constants.url+"/common/nationalities/")
        // .then(res => {
        //     console.log(res.data)
        //     setNationality(res.data)
        // })
        // .catch(err => {
        //     console.error(err)
        // })

        // axios.get(constants.url+"/common/countries/")
        // .then(res => {
        //     setCountries(res.data)
        // })
        // .catch(err => {
        //     console.error(err)
        // })
        setEmailValidation(false);
        settelephoneValidation(false);

    }, []);

    const [phonestatus, setPhonestatus] = useState(false);
    const [emailstatus, setEmailstatus] = useState(false);
    const [phonetwostatus, setPhonetwostatus] = useState(false);

    const validatephone = (phone) => {
        var check = telephoneChecker(phone)
        setPhonestatus(check)
    }

    const validatephonetwo = (phone) => {
        var check = telephoneChecker(phone)
        setPhonetwostatus(check)
    }

    const validateEmail = (email) => {
        var check = emailChecker(email)
        setEmailstatus(check)
    }

    const selectCountry = (event) => {
        setFormData({...formData, mother_residence_country: event.target.value});
        var index = event.nativeEvent.target.selectedIndex;
        var text = event.nativeEvent.target[index].text
        var country = text.toLowerCase();

        if (country === "uganda"){
            setAddress(true);
            setAddressout(false);
        }
        else {
            setAddress(false);
            setAddressout(true);
        }

    }

    const [filteredcounties, setFilteredcounties] = useState([]);
    const [filteredsubcounties, setFilteredsubcounties] = useState([]);
    const [filteredparish, setFilteredparish] = useState([]);
    const [filteredvillage, setFilteredvillage] = useState([]);
    

    const SelectDistict = async (name) => {
        setFormData({...formData, mother_district: name})
        var c = await GetCounties(name)
        setFilteredcounties(c)
    }

    const SelectCounty = async (name) => {
        setFormData({...formData, mother_county: name})
        var s = await GetSubCounties(name)
        setFilteredsubcounties(s)
    }

    const SelectSubCounty = async (name) => {
        setFormData({...formData, mother_sub_county: name})
        var s = await GetParish(name)
        setFilteredparish(s)
    }

    const SelectParish = async (name) => {
        setFormData({...formData, mother_parish: name})
        var p = await GetVillages(name)
        setFilteredvillage(p)
    }

    return <div className="form-container">
        <Container>
            <Row>
                <Col md={4}>

                <div className="form-group required">
                    <label className="mylabel control-label">Surname</label>
                    <input type="text" className={`form-control myinput ${motherform.mother_surname ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                    value={formData.mother_surname} 
                    onChange={(event) => setFormData({...formData, mother_surname: event.target.value})}/>
                    { motherform.mother_surname ? <p className="error-message">Surname Required</p> : null}
                </div>

                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Given Name</label>
                        <input type="text" className={`form-control myinput ${motherform.mother_given_name ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.mother_given_name} 
                        onChange={(event) => setFormData({...formData, mother_given_name: event.target.value})}/>
                        { motherform.mother_given_name ? <p className="error-message">Given Name Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Other Names</label>
                        <input type="text" className={`form-control myinput`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.mother_other_names} 
                        onChange={(event) => setFormData({...formData, mother_other_names: event.target.value})}/>
                        {/* { motherform.father_birth_country ? <p className="error-message">Given Name Required</p> : null} */}
                    </div>
                </Col>
                <Col md={4}>
                <div className="form-group required">
                    <label className="mylabel control-label">Status</label>
                    <select className={`custom-select form-control myinput  ${motherform.mother_status ? 'error': ''}`} id="inputGroupSelect01"
                    value={formData.mother_status} 
                    onChange={(event) => setFormData({...formData, mother_status: event.target.value})}>
                        <option selected>-------</option>
                        <option value="1">Alive</option>
                        <option value="2">Deceased</option>
                        <option value="0">Unknown</option>
                    </select>
                    { motherform.mother_status ? <p className="error-message">Status Required</p> : null}
                </div>
                </Col>
                <Col md={4}>
                <div className="form-group required">
                    <label className="mylabel control-label">Nationality</label>
                    <select className={`custom-select form-control myinput  ${motherform.mother_nationality ? 'error': ''}`} id="inputGroupSelect01"
                    value={NameToID(formData.mother_nationality, nationality)} 
                    onChange={(event) => setFormData({...formData, mother_nationality: event.target.value})}>
                        <option selected>-------</option>
                        {nationality.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                        ))}
                    </select>
                    { motherform.mother_nationality ? <p className="error-message">Nationality Required</p> : null}
                </div>
                </Col>
               
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Birth Country</label>
                        <select className={`custom-select form-control myinput  ${motherform.mother_birth_country ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.mother_birth_country, countries)} 
                        onChange={(event) => setFormData({...formData, mother_birth_country: event.target.value})}>
                            <option selected>-------</option>
                            {countries.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { motherform.mother_birth_country ? <p className="error-message">Birth Country Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Country Of Residence</label>
                        <select className="custom-select form-control myinput" id="inputGroupSelect01"
                        value={NameToID(formData.mother_residence_country, countries)} 
                        onChange={(event) => selectCountry(event)}>
                            <option selected>-------</option>
                            {countries.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                { address &&
                    <>
                        <Col md={4}>
                            <div className="form-group">
                                <label className="mylabel control-label">District</label>
                                <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                                value={NameToID(formData.mother_district, districts)} 
                                onChange={(event) => SelectDistict(event.target.value)}>
                                    <option selected>------</option>
                                    {districts.map((option, index) => (
                                        <option key={index} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                                {/* <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                value={formData.mother_district} 
                                onChange={(event) => setFormData({...formData, mother_district: event.target.value})}/> */}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="form-group">
                                <label className="mylabel control-label">County</label>
                                <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                                value={NameToID(formData.mother_county, filteredcounties)} 
                                onChange={(event) => SelectCounty(event.target.value)}>
                                    <option selected>------</option>
                                    {filteredcounties.map((option, index) => (
                                        <option key={index} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                                {/* <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                value={formData.mother_county} 
                                onChange={(event) => setFormData({...formData, mother_county: event.target.value})}/> */}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="form-group">
                                <label className="mylabel control-label">Sub County</label>
                                <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                                value={NameToID(formData.mother_sub_county, filteredsubcounties)} 
                                onChange={(event) => SelectSubCounty(event.target.value)}>
                                    <option selected>------</option>
                                    {filteredsubcounties.map((option, index) => (
                                        <option key={index} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                                {/* <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                value={formData.mother_sub_county} 
                                onChange={(event) => setFormData({...formData, mother_sub_county: event.target.value})}/> */}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="form-group">
                                <label className="mylabel control-label">Parish</label>
                                <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                                value={NameToID(formData.mother_parish, filteredparish)} 
                                onChange={(event) => SelectParish(event.target.value)}>
                                    <option selected>------</option>
                                    {filteredparish.map((option, index) => (
                                        <option key={index} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                                {/* <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                value={formData.mother_parish} 
                                onChange={(event) => setFormData({...formData, mother_parish: event.target.value})}/> */}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="form-group">
                                <label className="mylabel control-label">Plot Number</label>
                                <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                value={formData.mother_plot_number} 
                                onChange={(event) => setFormData({...formData, mother_plot_number: event.target.value})}/>
                                
                            </div>
                        </Col>
                    </>
                }

                {
                    addressout && 
                    <Col md={4}>
                        <div className="form-group">
                            <label className="mylabel control-label">Address Out Of Uganda</label>
                            <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                            value={formData.mother_foreign_address} 
                            onChange={(event) => setFormData({...formData, mother_foreign_address: event.target.value})}/>
                        </div>
                    </Col>
                }
                
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Tribe</label>
                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.mother_tribe} 
                        onChange={(event) => setFormData({...formData, mother_tribe: event.target.value})}/>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Primary Phone Number</label>
                        <PhoneInput
                            country={'ug'}
                            inputClass={`phone-input ${phonestatus ? 'error': ''}`}
                            onBlur={(event) => validatephone(event.target.value)}
                            value={formData.mother_primary_telephone_number} 
                            onChange={(event) => setFormData({...formData, mother_primary_telephone_number: event})}
                            />
                        {/* <input placeholder="+2567xxxxxxxx" type="text" className={`form-control myinput ${telephoneValidation ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.mother_primary_telephone_number} 
                        onChange={(event) => setFormData({...formData, mother_primary_telephone_number: event.target.value})}
                        onBlur={(event) => telephoneCheck(event.target.value)}/> */}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Other Phone Number</label>
                        <PhoneInput
                            country={'ug'}
                            inputClass={`phone-input ${phonetwostatus ? 'error': ''}`}
                            onBlur={(event) => validatephonetwo(event.target.value)}
                            value={formData.mother_other_telephone_number} 
                            onChange={(event) => setFormData({...formData, mother_other_telephone_number: event})}
                            />
                        {/* <input placeholder="+2567xxxxxxxx" type="text" className={`form-control myinput ${telephoneValidation ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.mother_other_telephone_number} 
                        onChange={(event) => setFormData({...formData, mother_other_telephone_number: event.target.value})}
                        onBlur={(event) => telephoneCheck(event.target.value)}/> */}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group">
                        <label className="mylabel control-label">Email</label>
                        <input placeholder="example@email.com" type="text" className={`form-control myinput ${emailstatus ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.mother_email_address} 
                        onChange={(event) => setFormData({...formData, mother_email_address: event.target.value})}
                        onBlur={(event) => validateEmail(event.target.value)}/>
                    </div>
                </Col>

            </Row>
          
        </Container>
    </div>;
}


export default MothersForm;