import React, {useState, useEffect, useContext, useMemo} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import ImageUpload from "../ImageUpload";
import axios from 'axios';
import constants from '../../constants/constants';
import {GlobalStateContext} from "../../context/index";
import DatePicker from 'react-date-picker';
import { StringToDateConverter } from '../../services/Services';

const ApplicantForm = ({formData,setFormData, applicantform, setApplicantform}) => {

    const [data, setData] = useState({"date": ""})   
    // const [nationality, setNationality] = useState([]) 
    // const [language, setLanguage] = useState([])

    const {occupation, setOccupation, NameToID, nationality, language, professions, setProfessions} = useContext(GlobalStateContext)

    // const [dob, setDob] = useState(new Date())

    useEffect(() => {
        setApplicantform({})
    }, [])

    
    
    return <div className="form-container">

        <Container>
            <Row>
                {/* <Col md={12}>
                    <ImageUpload  formData={formData} setFormData={setFormData} />
                </Col> */}
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Surname</label>
                        <input type="text" className={`form-control myinput ${applicantform.surname ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.surname} 
                        onChange={(event) => setFormData({...formData, surname: event.target.value})}
                        />
                        { applicantform.surname ? <p className="error-message">Surname Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Given Name</label>
                        <input type="text" className={`form-control myinput ${applicantform.given_name ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.given_name} 
                        onChange={(event) => setFormData({...formData, given_name: event.target.value})}/>
                        { applicantform.given_name ? <p className="error-message">Given Name Required</p> : null}
                    </div>
                    
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Other Names</label>
                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.other_names} 
                        onChange={(event) => setFormData({...formData, other_names: event.target.value})}/>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Date of Birth</label>
                        <DatePicker 
                            className = {`form-control myinput p-0 ${applicantform.date_of_birth ? 'error': ''}`}
                            value={StringToDateConverter(formData.date_of_birth)}
                            onChange={(date) => setFormData({...formData, date_of_birth: date})}
                            format="dd-MM-y"
                            minDate={new Date('1900-01-01')}
                            maxDate={new Date('2050-01-01')}
                            placeholderText={'dd-MM-yyyy'} 
                            clearIcon={null}
                        />
                        { applicantform.date_of_birth ? <p className="error-message">Date of Birth Required</p> : null}
                    </div>
                    
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Nationality</label>
                        <select className={`custom-select form-control myinput ${applicantform.nationality ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.nationality, nationality)} 
                        onChange={(event) => setFormData({...formData, nationality: event.target.value})}>
                            <option>-------</option>
                            {nationality.map((option, index) => (
                                <option 
                                    key={index} 
                                    value={option.id}
                                >{option.name}</option>
                            ))}
                        </select>
                        { applicantform.nationality ? <p className="error-message">Nationality Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Marital Status</label>
                        <select className={`custom-select form-control myinput ${applicantform.marital_status ? 'error': ''}`} id="inputGroupSelect01"
                        value={formData.marital_status} 
                        onChange={(event) => setFormData({...formData, marital_status: event.target.value})}>
                            <option value={null} selected>-------</option>
                            <option value="S">Single</option>
                            <option value="M">Married</option>
                            <option value="E">Separated</option>
                            <option value="D">Divorced</option>
                            <option value="W">Widowed</option>
                            <option value="X">Other</option>

                        </select>
                        { applicantform.marital_status ? <p className="error-message">Marital Status Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Gender</label>
                        <select className={`custom-select form-control myinput ${applicantform.sex ? 'error': ''}`} id="inputGroupSelect01"
                        value={formData.sex} 
                        onChange={(event) => setFormData({...formData, sex: event.target.value})}>
                            <option selected>------</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                        { applicantform.sex ? <p className="error-message">Gender Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Profession / Occupation</label>
                        {/* <input type="text" className={`form-control myinput ${applicantform.occupation ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.occupation} 
                        onChange={(event) => setFormData({...formData, occupation: event.target.value})}/> */}
                        <select className={`custom-select form-control myinput ${applicantform.occupation ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.occupation, professions)} 
                        onChange={(event) => setFormData({...formData, occupation: event.target.value})}>
                            <option>-------</option>
                            {professions.map((option, index) => (
                                <option 
                                    key={index} 
                                    value={option.id}
                                >{option.name}</option>
                            ))}
                        </select>

                        { applicantform.occupation ? <p className="error-message">Profession / Occupation</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Place Of Birth</label>
                        <input type="text" className={`form-control myinput ${applicantform.place_of_birth ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.place_of_birth} 
                        onChange={(event) => setFormData({...formData, place_of_birth: event.target.value})}/>
                        { applicantform.place_of_birth ? <p className="error-message">Place Of Birth Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Primary Language</label>
                        <select className={`custom-select form-control myinput  ${applicantform.primary_language ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.primary_language, language)} 
                        onChange={(event) => setFormData({...formData, primary_language: event.target.value})}>
                            <option selected>-------</option>
                            {language.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { applicantform.primary_language ? <p className="error-message">Primary Language Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Other Language</label>
                        <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                        value={NameToID(formData.other_language, NameToID)} 
                        onChange={(event) => setFormData({...formData, other_language: event.target.value})}>
                            <option selected>-------</option>
                            {language.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </Col>
            </Row>
        </Container>

    </div>;
}

export default ApplicantForm;