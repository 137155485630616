import React, { useRef, useContext, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import "../styles/PrintPreview.css";
import Barcode from 'react-barcode';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dcic from '../assets/img/dcic.png';
import coatofarms from '../assets/img/coatofarms.png';
import {GlobalStateContext} from "../context/index";
import constants from '../constants/constants';
import axios from 'axios';
// import { pdfMake } from 'pdfmake/build/vfs_fonts';
import * as pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

// import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const ComponentToPrint = React.forwardRef(({data}, ref) => {

    
    var [previewdata, setPreviewData] = useState({});

    const {formData} = useContext(GlobalStateContext);
    const [nationality, setNationality] = useState([]);
    const [counties, setCounties] = useState([]);
    const [subcounties, setSubcounties] = useState([]);
    const [prn, setPRN] = useState({});
    const [loading, setLoading] = useState(true);
    const [totalPaid, setTotalPaid] = useState(0);

    useEffect(() => {
        var formdata = {}

        for (var key in formData){
            formdata[key] = sessionStorage.getItem(key)
        }

        setPreviewData(formdata);

        // axios.get(constants.url+"/applications/applications/"+formdata.id+"/")
        // .then(res => {
        //     console.log(res.data.prns)
        //     var prnarray = res.data.prns
    
        //     var total = 0
        //     for (var i=0; i<prnarray.length; i++){
        //         total = total + parseInt(prnarray[i].amount)
                
        //     }

        //     setTotalPaid(total)

        //     setPRN(res.data)
        //     setLoading(false);
        // })

        var data = {
            "refugee_No": formdata.refugee_No,
            "phone_number": formdata.phone_number,
            "security_question": formdata.security_question,
            "security_answer": formdata.security_answer,
        }

        axios.post(constants.url+"/applications/resume/", data, {
            headers : {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        .then(res => {
            console.log(res.data.prns)
            var prnarray = res.data.prns
            var total = 0
            for (var i=0; i<prnarray.length; i++){
                total = total + parseInt(prnarray[i].amount)
                
            }
            setTotalPaid(total)
            setPRN(res.data)
            setLoading(false);
        })

        axios.get(constants.url+"/common/nationalities/")
        .then(res => {
            setNationality(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        axios.get(constants.url+"/common/counties/")
        .then(res => {
            setCounties(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        axios.get(constants.url+"/common/sub-counties/")
        .then(res => {
            setSubcounties(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        sessionStorage.setItem("state", "submitted")

    }, [])

    
    

    const Test = () => {
        console.log(data)
    }

    if (loading) {
        return <p>Loading...</p>
    }
    
    return <>
        
        <div className="container print-container" ref={ref}>
            <div className="header-container print-header-border">
                <Row>
                    <Col className="headercol1"><img className="headerimage" src={dcic} alt="Logo" /></Col>
                    <Col xs={6} className="headertext">
                        <p className="mainheadertext">THE REPUBLIC OF UGANDA<br/>MINISTRY OF INTERNAL AFFAIRS</p>
                        <p className="systemtitle">DIRECTORATE OF CITIZENSHIP & IMMIGRATION CONTROL</p>
                    </Col>
                    <Col className="coatimage"><img className="headerimage " src={coatofarms} alt="Logo" /></Col>
                </Row>
                <div className="black"></div>
            </div>
            <div className="row">

                <div className="col-6 print-header">Applicant</div>
                <div className="col-6 print-header">{previewdata.surname} {previewdata.given_name} {previewdata.other_names}</div>
                <div className="col-6 print-header">Place of Submission</div>
                <div className="col-6 print-text">Kampala</div> 
                <div className="col-12 print-text barcode"><Barcode height="80" width="3" value={previewdata.application_id} /></div>
                <div className="col-12 print-text print-label">APPLICATION DETAILS</div>
                <Row>
                    <Col xs={8}>
                        <div className="row">

                            <div className="col-6 print-text">Application Number</div>
                            <div className="col-6 print-text">{previewdata.application_id}</div>
                            {/* <div className="col-6 print-text">Serial Number</div>
                            <div className="col-6 print-text">{previewdata.id}</div> */}
                            <div className="col-6 print-text">Applicant Name</div>
                            <div className="col-6 print-text">{previewdata.surname} {previewdata.given_name} {previewdata.other_names}</div>
                            <div className="col-6 print-text">Date Of Birth</div>
                            <div className="col-6 print-text">{previewdata.date_of_birth}</div>
                            <div className="col-6 print-text">Telephone Number</div>
                            <div className="col-6 print-text">{previewdata.phone_number}</div>
                            <div className="col-6 print-text">Email</div>
                            <div className="col-6 print-text">{previewdata.email_address}</div>
                            {/* <div className="col-6 print-text">ID Number</div>
                            <div className="col-6 print-text">{previewdata.individual_number}</div> */}
                            <div className="col-6 print-text">Signature</div>
                            <div className="col-6 print-text print-signature"></div>

                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="photo-area">
                            <p className="photo-text">
                                PASSPORT PHOTO  <br/>
                                PLEASE PASTE <br/>
                                DO NOT STAPLE <br/>
                                THE PHOTO SHOULD <br/>
                                BE COLOURED WITH A WHITE <br/>
                                BACKGROUND
                            </p>
                            
                        </div>
                    </Col>

                </Row>
                
                <div className="col-12 print-text printer-border"></div>
                {/* <div className="col-6 print-text">Type Of CTD</div>
                <div className="col-6 print-text">{previewdata.application_type}</div> */}
                <div className="col-6 print-text">Type of Application</div>
                <div className="col-6 print-text">{previewdata.application_type}</div>
                <div className="col-6 print-text">Place of Submission</div>
                <div className="col-6 print-text">Kampala</div> 
                <div className="col-6 print-text">Place of Collection</div>
                <div className="col-6 print-text">Kampala</div> 
                <div className="col-12 mt"></div>

                <div className="col-12 print-text print-label">PAYMENT DETAILS</div>
                <table className="print-table">
                    <thead>
                        <tr className="print-table-header print-text tr-bottom">
                            <th className="print-text">Description</th>
                            <th className="print-text">PRN</th>
                            <th className="print-text">Amount (Shs)</th>
                            {/* <th className="print-text">Payment Date</th> */}
                        </tr>
                        
                    </thead>
                    <tbody className="mt">

                        {
                            prn.prns.map((item, index) => (
                                <tr className="tr-bottom-2">
                                    <td className="print-text">{item.tax_head_description}</td>
                                    <td className="print-text">{item.prn}</td>
                                    <td className="print-text">{item.amount}</td>
                                </tr>
                            ))
                        }
                        {/* <tr className="tr-bottom-2">
                            <td className="print-text">CTD</td>
                            {
                                (()=>{
                                    try {
                                        return <td className="print-text">{prn.prn.prn}</td>
                                    } catch (e){
                                        return <td></td>
                                    }
                                })()
                            }
                            {
                                (()=>{
                                    try {
                                        return <td className="print-text">{prn.prn.amount}</td>
                                    } catch (e){
                                        return <td></td>
                                    }
                                })()
                            }
                            
                        </tr> */}
                        <tr className="tr-background">
                            <td className="print-label print-text ">TOTAL PAID</td>
                            <td className="print-label print-text "></td>
                            {/* <td className="print-label print-text ">{prn.prn.amount}</td> */}
                            {
                                (()=>{
                                    try {
                                        return <td className="print-text">{totalPaid}</td>
                                    } catch (e){
                                        return <td></td>
                                    }
                                })()
                            }
                            <td className="print-label print-text "></td>
                        </tr>
                        

                    </tbody>
                </table>

                <div className="col-12 mt print-break"></div>
                <div className="col-12 print-text print-label">NEXT OF KIN DETAILS</div>
                <div className="col-12 print-text print-label">Your next of kin must sign and attach a copy of their ID</div>
                <div className="col-6 print-text">Next Of KIN Name</div>
                <div className="col-6 print-text">{previewdata.next_of_kin_given_name} {previewdata.next_of_kin_surname}</div>
                <div className="col-6 print-text">Next Of KIN Nationality</div>
                <div className="col-6 print-text">
                        {(() => {
                            for (var i=0; i<nationality.length; i++) {
                                
                                if (nationality[i].id == previewdata.next_of_kin_nationality){
                                    return (
                                        <>{nationality[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                </div>
                <div className="col-6 print-text">Address</div>
                <div className="col-6 print-text">
                    {(() => {
                        var address = previewdata.next_of_kin_foreign_address;

                        for (var i=0; i<counties.length; i++) {
                                
                            if (counties[i].id == previewdata.next_of_kin_county){
                                address = address+" "+counties[i].name
                                break;
                            }
                        }

                        for (var i=0; i<subcounties.length; i++) {
                                
                            if (subcounties[i].id == formData.next_of_kin_sub_county){
                                address = address+" "+subcounties[i].name
                                break;
                            }
                        }

                        return (
                            <>{address}</>
                        )

                    })()}
                </div>
                <div className="col-6 print-text">ID Number</div>
                <div className="col-6 print-text">{previewdata.next_of_kin_id_number}</div>
                <div className="col-6 print-text">Phone Number</div>
                <div className="col-6 print-text">{previewdata.next_of_kin_primary_telephone_number}</div>
                <div className="col-6 print-text">Next Of KIN Signature</div>
                <div className="col-6 print-text print-signature"></div>

                <div className="col-12 mt print-break"></div>
                <div className="col-12 print-text print-label">CTD APPLICATION REQUIREMENTS</div>
                <div className="col-12 print-text print-label">You must bring along the following supporting documents for your appointment</div>
                <div className="col-12 print-text">All Applicants</div>
                <div className="col-12 print-text">
                    <ul>
                        <li>Recommendation Letter from OPM</li>
                        <li>Birth Certificates in the case of children</li>
                        <li>Copy of Refugee ID</li>
                        <li>Make payments as described in the fees structure</li>
                        <li>Family Attestation Letter</li>
                        <li>Receipt</li>
                    </ul>
                </div>
                <div className="col-12 print-text">ADDITIONAL ATTACHMENTS</div>
                <div className="col-12 print-text">Renewal</div>
                <div className="col-12 print-text">
                    <ul>
                        <li>Old CTD</li>
                    </ul>
                </div>
                
                <div className="col-12 print-text">Lost CTD Replacement</div>
                <div className="col-12 print-text">
                    <ul>
                        <li>Affidavit</li>
                        <li>Police Letter</li>
                        <li>Payment of fine of Shs 100,000 for the lost CTD</li>
                    </ul>
                </div>
                
                <div className="col-12 print-text">Getting a CTD for a minor (Under 18)</div>
                <div className="col-12 print-text">
                    <ul>
                        <li>Birth Certificate</li>
                        <li>Parents IDs</li>
                        <li>Any of the parents copy of CTDs</li>
                        <li>Letter of concent from parent</li>
                    </ul>
                </div>
                
            </div>
        </div>
    </>;
})

const handleDownload = () => {

    var docDefinition = { 
        info: {
            title: "CTD",
            author: 'DCIC',
            subject: 'CTD Assessment',
        },
        content: [
            // {
            //     image: dcic,
            //     alignment:"center",
            //     width: 60,
            //     margin: [ 0, 2, 0, 2 ] 
            // },
            {
                text: 'THE REPUBLIC OF UGANDA',
                bold: true,
                alignment: "center",
                fontSize: 9,
            },
            {
                text: 'MINISTRY OF INTERNAL AFFAIRS',
                alignment: "center",
                fontSize: 9,
            },
            {
                text: 'DIRECTORATE OF CITIZENSHIP & IMMIGRATION CONTROL',
                alignment: "center",
                fontSize: 9,
            },
            {
                text: '\n',
            },
            {
                table: {
                    widths: ["*", 200],
                    body: [
                        // ['', ''],
                        [
                            {
                                text: 'APPLICANT',
                                alignment: "left",
                                style: "print_header"
                            },
                            {
                                text: "Aaron Omach",
                                alignment: "left",
                                style: "print_text"
                            }
                        ],
                        [
                            {
                                text: 'Place Of Submission',
                                alignment: "left",
                                style: "print_header"
                            },
                            {
                                text: "Kampala",
                                alignment: "left",
                                style: "print_text"
                            }
                        ]

                    ]
                }
            },
            
            {
                text: '\n',
            },
            {
                text: "Barcode",
                alignment: "center",
                style: "print_header"
            },
            {
                text: '\n',
            },
            {
                text: "APPLICATION DETAILS",
                alignment: "left",
                style: "print_header"
            },
            {
                table: {
                    width: ["*",200,200],
                    body: [
                        [
                            {
                                text: "Application Number",
                                style: "print_text",
                            },
                            {
                                text: "12345678",
                                style: "print_text",
                            },
                            {
                                text: "",
                                style: "print_text",
                            }
                        ],
                        [
                            {
                                text: "Applicant Name",
                                style: "print_text",
                            },
                            {
                                text: "Aaron Omach",
                                style: "print_text",
                            },
                            {
                                text: "",
                                style: "print_text",
                            }
                        ],
                        [
                            {
                                text: "Date Of Birth",
                                style: "print_text",
                            },
                            {
                                text: "12/12/1996",
                                style: "print_text",
                            },
                            {
                                text: "",
                                style: "print_text",
                            }
                        ],
                        [
                            {
                                text: "Telephone Number",
                                style: "print_text",
                            },
                            {
                                text: "+256788237108",
                                style: "print_text",
                            },
                            {
                                text: "",
                                style: "print_text",
                            }
                        ],
                        [
                            {
                                text: "Email",
                                style: "print_text",
                            },
                            {
                                text: "aaomach@gmail.com",
                                style: "print_text",
                            },
                            {
                                text: "",
                                style: "print_text",
                            }
                        ],
                        [
                            {
                                text: "Signature",
                                style: "print_text",
                            },
                            {
                                text: "",
                                style: "print_text",
                            },
                            {
                                text: "",
                                style: "print_text",
                            }
                        ]
                    ]

                }
            },
            {
                table: {
                    width: ["*",400],
                    body: [
                        [
                            {
                                text: "Type of Application",
                                style: "print_text"
                            },
                            {
                                text: "Ordinary",
                                style: "print_text"
                            },
                        ],
                        [
                            {
                                text: "Place of Submission",
                                style: "print_text"
                            },
                            {
                                text: "Kampala",
                                style: "print_text"
                            },
                        ],
                        [
                            {
                                text: "Place of Collection",
                                style: "print_text"
                            },
                            {
                                text: "Kampala",
                                style: "print_text"
                            },
                        ],
                    ],
                }
            },
            {
                text: 'PAYMENT DETAILS',
                alignment: "left",
                style: "print_header"
            },
            {
                table: {
                    widths: ["*", 200, 200],
                    body: [
                        ['Description', 'PRN', 'Amount'],
                        ['CTD Ordinary', 'None', '220000'],
                        ['Total Paid', '', '220000']

                    ]
                }
            },
            {
                text: 'NEXT OF KIN DETAILS',
                alignment: "left",
                style: "print_header"
            },
            {
                text: 'Your next of kin must sign and attach a copy of their ID',
                alignment: "left",
                style: "print_header"
            },
            {
                table: {
                    width: ["*", 400],
                    body: [
                        [
                            {
                                text: "Next Of KIN Name",
                                style: "print_header"
                            },
                            {
                                text: "1",
                                style: "print_header"
                            }
                        ],
                        [
                            {
                                text: "Next Of KIN Nationality",
                                style: "print_header"
                            },
                            {
                                text: "2",
                                style: "print_header"
                            }
                        ],
                        [
                            {
                                text: "Address",
                                style: "print_header"
                            },
                            {
                                text: "3",
                                style: "print_header"
                            }
                        ],
                        [
                            {
                                text: "ID Number",
                                style: "print_header"
                            },
                            {
                                text: "4",
                                style: "print_header"
                            }
                        ],
                        [
                            {
                                text: "Phone Number",
                                style: "print_header"
                            },
                            {
                                text: "5",
                                style: "print_header"
                            }
                        ],
                        [
                            {
                                text: "Next Of KIN Signature",
                                style: "print_header"
                            },
                            {
                                text: "6",
                                style: "print_header"
                            }
                        ]
                        
                    ]
                }
            },
            {
                text: 'CTD APPLICATION REQUIREMENTS',
                alignment: "left",
                style: "print_header"
            },
            {
                text: 'You must bring along the following supporting documents for your appointment',
                alignment: "left",
                style: "print_header"
            },
            {
                text: 'All Applicants',
                style: "print_text"
            },
            {
                text: "Recommendation Letter from OPM Birth Certificates in the case of children Copy of Refugee ID Make payments as described in the fees structure Family Attestation Letter Receipt",
                style: "print_text"
            },
            {
                text: 'ADDITIONAL ATTACHMENTS',
                style: "print_text"
            },
            {
                text: 'Renewal Old CTD Lost CTD Replacement Affidavit Police Letter Payment of fine of Shs 100,000 for the lost CTD Getting a CTD for a minor (Under 18) Birth Certificate Parents IDs Any of the parents copy of CTDs Letter of concent from parent',
                style: "print_text"
            }
            
        ],

        styles: {
            print_header: {
                bold: true,
                fontSize: 10
            },
            print_text: {
                fontSize: 8
            }
        }
    };

    pdfMake.createPdf(docDefinition).open()

}

const PrintPreview = ({data}) => {

    const componentRef = useRef();
    const [appid, setAppid] = useState("");

    useEffect(()=>{
        setAppid(sessionStorage.getItem("id"))
    },[])
    const clearMemory = () => {
        sessionStorage.clear();
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const DownloadPDF = () => {
        axios.get(constants.url+"/applications/print-application-pdf/"+appid+"/")
        .then(res => {
            console.log(res)
        })
        .catch(e => {
            console.log(e)
            alert("An Error Occured While Saving Document \nPlease Use Print Instead")
        })


    }

    return <div className="container">
        <button className="btn-grad-secondary btn-padding print-btn" onClick={()=>{
            handlePrint();
            clearMemory();
        }}>Print</button>
        <button className="btn-grad-primary btn-padding print-btn" onClick={() => {
            DownloadPDF();
            clearMemory();
        }}>Save</button>
        <ComponentToPrint data={data} ref={componentRef} />
        
    </div>;
}

export default PrintPreview;