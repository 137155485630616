import React, {useContext, useState} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import '../../styles/Slider.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GlobalStateContext} from "../../context/index";


const MothersSlider = ({formData,setFormData}) => {

    const {relationships, nationality, countries, districts, counties, subcounties, parishes} = useContext(GlobalStateContext)

    const [Status, setStatus] = useState([
        {id: 1, name: "Alive"},
        {id: 2, name: "Deceased"},
        {id: 0, name: "Unknown"}
    ]);

    return <><div className="slider-container">
    <Row className="slider-body">
        <Col md={2}>
            <label className="slider-label">Mother's Surname</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">{formData.mother_surname}</p>
        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Given Name</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">{formData.mother_given_name}</p>
        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Other Name</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">{formData.mother_other_names}</p>
        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Status</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">
            {/* {formData.mother_status} */}
            {(() => {
                for (var i=0; i<Status.length; i++) {
                    
                    if (Status[i].id == formData.mother_status){
                        return (
                            <>{Status[i].name}</>
                        )
                        
                    }
                    
                }
            })()}
        </p>

        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Nationality</label> 
            
        </Col>
        <Col md={2}>
        <p className="slider-text">
            {(() => {
                for (var i=0; i<nationality.length; i++) {
                    
                    if (nationality[i].id == formData.mother_nationality){
                        return (
                            <>{nationality[i].name}</>
                        )
                        
                    }
                    
                }
            })()}
                {/* {formData.mother_nationality} */}
                
            </p>
        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Birth Country</label> 
            
        </Col>
        <Col md={2}>
        <p className="slider-text">
            {(() => {
                for (var i=0; i<countries.length; i++) {
                    
                    if (countries[i].id == formData.mother_birth_country){
                        return (
                            <>{countries[i].name}</>
                        )
                        
                    }
                    
                }
            })()}
                {/* {formData.mother_birth_country} */}
            </p>
        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Country Of Residence</label> 
            
        </Col>
        <Col md={2}>
        <p className="slider-text">
            {(() => {
                for (var i=0; i<countries.length; i++) {
                    
                    if (countries[i].id == formData.mother_residence_country){
                        return (
                            <>{countries[i].name}</>
                        )
                        
                    }
                    
                }
            })()}
                {/* {formData.mother_residence_country} */}
            </p>
        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's District</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">
            {(() => {
                for (var i=0; i<districts.length; i++) {
                    
                    if (districts[i].id == formData.mother_district){
                        return (
                            <>{districts[i].name}</>
                        )
                        
                    }
                    
                }
            })()}
            {/* {formData.mother_district} */}
        </p>

        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's County</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">
            {(() => {
                for (var i=0; i<counties.length; i++) {
                    
                    if (counties[i].id == formData.mother_county){
                        return (
                            <>{counties[i].name}</>
                        )
                        
                    }
                    
                }
            })()}
            {/* {formData.mother_county} */}
        </p>

        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Sub County</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">
            {(() => {
                for (var i=0; i<subcounties.length; i++) {
                    
                    if (subcounties[i].id == formData.mother_sub_county){
                        return (
                            <>{subcounties[i].name}</>
                        )
                        
                    }
                    
                }
            })()}
            {/* {formData.mother_sub_county} */}
        </p>

        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Parish</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">
            {(() => {
                for (var i=0; i<parishes.length; i++) {
                    
                    if (parishes[i].id == formData.mother_parish){
                        return (
                            <>{parishes[i].name}</>
                        )
                        
                    }
                    
                }
            })()}
            {/* {formData.mother_parish} */}
        </p>

        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Plot Number</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">{formData.mother_plot_number}</p>

        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Tribe</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">{formData.mother_tribe}</p>

        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Primary Phone Number</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">{formData.mother_primary_telephone_number}</p>

        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Other Phone Number</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">{formData.mother_other_telephone_number}</p>

        </Col>
        <Col md={2}>
            <label className="slider-label">Mother's Email</label> 
        </Col>
        <Col md={2}>
        <p className="slider-text">{formData.mother_email_address}</p>

        </Col>
    </Row>
</div>
</>;
}



export default MothersSlider;