import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import dcic from '../assets/img/dcic.png';
import coatofarms from '../assets/img/coatofarms.png'; 
import '../styles/Global.css';
import '../styles/Home.css';
import Footer from '../components/Footer';
import HomeMenu from '../components/HomeMenu';
import Button from 'react-bootstrap/Button';
import HomeButton from '../components/HomeButton';
import constants from '../constants/constants';
import passports3 from '../assets/img/passports2.png';

const Home = () => {

    const ctdpage = () => {
        window.location.href = "/ctd";
    }

    const opasapplication = () => {
        window.location.href = constants.opas+'/start'
    }

    const opascontinue = () => {
        window.location.href = constants.opas+'/resume'
    }

    const opasschedule = () => {
        window.location.href = constants.opas+'/payment_status'
    }

    const opasstatus = () => {
        window.location.href = constants.opas+'/check_status'
    }

    return (
        
        <>
            
            <div className="home-container">
                <HomeMenu/>
                <Container className="">
                    
                    <Row className="justify-content-md-center">

                        <Col md={5} className="backgroundimage">
                        {/* imgcontainer buttongroup */}
                            {/* <div className="col-md-4 p-0 "> */}
                                

                                {/* <img src={passports3} className="passportimg"/> */}

                            {/* </div> */}

                        </Col>

                        <Col md={7} className="homepadding">

                            <div className="homecontainer">

                                <h5 className="innertext">Uganda Passport Application</h5>

                                <div className="buttongroup">

                                            <div className="row">

                                                <div className="col-md-6 text-center btn-content">
                                                    <HomeButton btntype="btn-grad" clickeventhandler={opasapplication} title="Apply" body="Apply for Passport" name="Start New Application" variant="success"/>
                                                </div>
                                                <div className="col-md-6 text-center btn-content">
                                                    <HomeButton btntype="btn-grad-secondary" clickeventhandler={opascontinue} title="Continue" body="Continue Application" name="Resume Save Application" variant="success"/>  
                                                </div>
                                                <div className="col-md-6 text-center btn-content">
                                                    <HomeButton btntype="btn-grad" clickeventhandler={opasschedule} title="Schedule" body="All payments must be complete" name="Schedule An Appointment" variant="primary"/>
                                                </div>
                                                <div className="col-md-6 text-center btn-content">
                                                    <HomeButton btntype="btn-grad-secondary" clickeventhandler={opasstatus} title="Status" body="Check Application Status" name="Check Application Status" variant="primary"/>
                                                </div>

                                            </div>                                    

                                </div>

                                
                            </div>


                           
                        </Col>

                        {/* <Col md={3}>

                            <div className="innercontainer">

                                <p className="innertext-two">Other Travel Documents</p>


                                <div className="buttongroup">

                                    <div className="row otherbuttongroup">
                                        <div className="col-md-12 text-center">
                                            <Button onClick={ctdpage} className="otherbuttonstyle buttontextsize btn-grad" variant="secondary" size="lg">Conventional Travel Document</Button>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <Button disabled className="otherbuttonstyle buttontextsize" variant="secondary" size="lg">Interstate Pass</Button>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <Button disabled className="otherbuttonstyle buttontextsize" variant="secondary" size="lg">Temporary Movement Pass</Button>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <Button disabled className="otherbuttonstyle buttontextsize" variant="secondary" size="lg">Emergency Travel Document</Button>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <Button disabled className="otherbuttonstyle buttontextsize" variant="secondary" size="lg">Certificate of Identification</Button>
                                        </div>
                                        
                                    </div>

                                </div>



                            </div>

                        </Col> */}


                    </Row>
                </Container>
                
            </div>
            
            
        </>
        
    );
}

export default Home;