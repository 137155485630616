import React from 'react';

const constants = {
    "url": process.env.REACT_APP_API_URL,
    "auth_url": process.env.REACT_APP_AUTH_URL,
    
    "opas": "https://passports.go.ug",
    "opas_start": "https://passports.go.ug/start",
}



export default constants;