import React, {useState, useEffect, useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import { FiTrash, FiPlus, FiEdit3, FiEdit2 } from "react-icons/fi";
import {GlobalStateContext} from "../../context/index";
import DatePicker from 'react-date-picker';
import { StringToDateConverter } from '../../services/Services';


const DependantForm = ({formData,setFormData}) => {

    const [idcount, setIdcount] =  useState(1);
    const [edit, setEdit] = useState(null);
    const [add, setAdd] = useState(true);

    const {relationships, nationality, countries} = useContext(GlobalStateContext)

    const DependantSelect = (item) => {
        setEdit(item.id)
        setAdd(false)
        setFormData({...formData, 
            dependant_surname: item.dependant_surname, 
            dependent_given_name: item.dependent_given_name,
            dependent_other_names: item.dependent_other_names,
            dependant_sex: item.dependant_sex,
            dependant_dob: item.date_of_birth, dependant_relationship: item.relationship, 
            dependant_nationality: item.dependant_nationality,
            dependant_id_country_of_issurance: item.dependant_id_country_of_issurance,
            dependant_id_type: item.dependant_id_type,
            dependant_id_number: item.dependant_id_number
        })

    }

    const DependantDelete = (item) => {
        
        const check = formData.dependents
        for (var i in check){
            if (check[i].dependant_surname === item.dependant_surname && check[i].date_of_birth === item.date_of_birth){
                const newlist = formData.dependents.filter((items) => items.dependent_given_name !== item.dependent_given_name)
                setFormData({...formData, dependents: newlist})
                
            }
        }

    }

    const AddDependantToList = () => {
        setEdit(null)
        const check = formData.dependents
        console.log(check)
        var count = 0
        for (var i in check){

            if (check[i].dependant_surname === formData.dependant_surname && 
                check[i].date_of_birth === formData.dependant_dob
            ){
                count++;
            }

            if (formData.dependant_surname === ""){
                count++;
            }
            
        }
        if (count > 0){

            setFormData({...formData, 
                dependant_dob: null, 
                dependant_relationship: 0, 
                dependant_nationality: 0,
                dependant_surname: "", 
                dependent_given_name: "", 
                dependent_other_names: "",
                dependant_sex: 0, 
                dependant_id_country_of_issurance: 0,
                dependant_id_type: 0,
                dependant_id_number: ""

            })

        }
        else {

            var dependant_length = 0
            try {
                dependant_length = formData.dependents.length
            } catch (error) {
                dependant_length = 0
            }

            if (dependant_length === 0){
                console.log(formData.dependents)
                const mydepenants = formData.dependents.concat(
                    {
                        id: idcount,
                        dependant_surname: formData.dependant_surname,
                        dependent_given_name: formData.dependent_given_name,
                        dependent_other_names: formData.dependent_other_names,
                        date_of_birth: formData.dependant_dob,
                        dependant_sex: formData.dependant_sex,
                        relationship: formData.dependant_relationship,
                        dependant_nationality: formData.dependant_nationality,
                        dependant_id_country_of_issurance: formData.dependant_id_country_of_issurance,
                        dependant_id_type: formData.dependant_id_type,
                        dependant_id_number: formData.dependant_id_number


                    },
                )
                var newcount = idcount + 1
                setIdcount(newcount)
                setFormData({...formData, dependents: mydepenants})

            }
            else {
                var latestid = formData.dependents[formData.dependents.length - 1].id + 1
                console.log(latestid)
                const mydepenants = formData.dependents.concat(
                    {
                        id: latestid, 
                        // application: "",
                        dependant_surname: formData.dependant_surname,
                        dependent_given_name: formData.dependent_given_name,
                        dependent_other_names: formData.dependent_other_names,
                        date_of_birth: formData.dependant_dob,
                        dependant_sex: formData.dependant_sex,
                        relationship: formData.dependant_relationship,
                        dependant_nationality: formData.dependant_nationality,
                        dependant_id_country_of_issurance: formData.dependant_id_country_of_issurance,
                        dependant_id_type: formData.dependant_id_type,
                        dependant_id_number: formData.dependant_id_number
                    },
                )
                var newcount = latestid + 1
                setIdcount(newcount)
                setFormData({...formData, dependents: mydepenants})

            }
            

        }
        
    }

    const UpdateDependantList = () => {
        setAdd(true)
        var data = []
        for (let i=0; i<formData.dependents.length; i++){
            if (formData.dependents[i].id === edit){
                
                var update = {
                    id: edit, 
                    // application: "",
                    dependant_surname: formData.dependant_surname,
                    dependent_given_name: formData.dependent_given_name,
                    dependent_other_names: formData.dependent_other_names,
                    date_of_birth: formData.dependant_dob,
                    dependant_sex: formData.dependant_sex,
                    relationship: formData.dependant_relationship,
                    dependant_nationality: formData.dependant_nationality,
                    dependant_id_country_of_issurance: formData.dependant_id_country_of_issurance,
                    dependant_id_type: formData.dependant_id_type,
                    dependant_id_number: formData.dependant_id_number
                }
                
                data.push(update)

            }
            else {
                data.push(formData.dependents[i])
            }
            

        }

        setFormData({...formData, dependents: data})

    }


    return <div className="form-container">
        <Container>
            <Row>
                <Col md={8}>
                    <Row>
                        <Col md={6}>

                            <div className="form-group ">
                                <label className="mylabel control-label">Surname</label>
                                <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                value={formData.dependant_surname} 
                                onChange={(event) => setFormData({...formData, dependant_surname: event.target.value})}/>
                            </div>

                        </Col>
                        <Col md={6}>

                            <div className="form-group ">
                                <label className="mylabel control-label">Given Name</label>
                                <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                value={formData.dependent_given_name} 
                                onChange={(event) => setFormData({...formData, dependent_given_name: event.target.value})}/>
                            </div>

                        </Col>
                        <Col md={6}>

                            <div className="form-group ">
                                <label className="mylabel control-label">Other Names</label>
                                <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                value={formData.dependent_other_names} 
                                onChange={(event) => setFormData({...formData, dependent_other_names: event.target.value})}/>
                            </div>

                        </Col>
        
                        <Col md={6}>
                            <div className="form-group ">
                                <label className="mylabel control-label">Date Of Birth</label>
                                <DatePicker
                                    className="form-control p-0 myinput"
                                    value={StringToDateConverter(formData.dependant_dob)}
                                    onChange={(date) => setFormData({...formData, dependant_dob: date})}
                                    format="dd-MM-y"
                                    minDate={new Date('1900-01-01')}
                                    maxDate={new Date('2050-01-01')}
                                    placeholderText={'dd-MM-yyyy'} 
                                    clearIcon={null}
                                />
                                
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group">
                                <label className="mylabel control-label">Gender</label>
                                <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                                value={formData.dependant_sex} 
                                onChange={(event) => setFormData({...formData, dependant_sex: event.target.value})}>
                                    <option selected>------</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group ">
                                <label className="mylabel control-label">Relationship</label>
                                <select className="custom-select form-control myinput" id="inputGroupSelect01"
                                value={formData.dependant_relationship} 
                                onChange={(event) => setFormData({...formData, dependant_relationship: event.target.value})}>
                                    <option value={0} selected>-------</option>
                                    {relationships.map((option, index) => (
                                            <option key={index} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group ">
                                <label className="mylabel control-label">Nationality</label>
                                <select className="custom-select form-control myinput" id="inputGroupSelect01"
                                value={formData.dependant_nationality} 
                                onChange={(event) => {
                                    setFormData({...formData, dependant_nationality: event.target.value});
                                }}
                                >
                                    <option value={0} selected>-------</option>
                                    {nationality.map((option, index) => (
                                            <option key={index} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group">
                                <label className="mylabel control-label">ID Type</label>
                                <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                                value={formData.dependant_id_type} 
                                onChange={(event) => setFormData({...formData, dependant_id_type: event.target.value})}>
                                    <option selected>------</option>
                                    <option value="National ID">National ID</option>
                                    <option value="Passport">Passport</option>
                                    <option value="Refugee ID">Refugee ID</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group ">
                                <label className="mylabel control-label">ID Number</label>
                                <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                value={formData.dependant_id_number} 
                                onChange={(event) => setFormData({...formData, dependant_id_number: event.target.value})}/>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group ">
                                <label className="mylabel control-label">Country Of Issuance</label>
                                <select className="custom-select form-control myinput" id="inputGroupSelect01"
                                value={formData.dependant_id_country_of_issurance} 
                                onChange={(event) => {
                                    setFormData({...formData, dependant_id_country_of_issurance: event.target.value});
                                }}
                                onBlur={AddDependantToList}
                                >
                                    <option value={0} selected>-------</option>
                                    {countries.map((option, index) => (
                                            <option key={index} value={option.id}>{option.name}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col md={12} className="adddependantcont">
                            { add && <btn
                                onClick={() => AddDependantToList()} 
                                className="addbtn" 
                                size="lg">
                                    <FiPlus className="dependantplus"/>
                                    Dependant
                            </btn>
                            }
                            {
                                edit && <btn
                                    onClick={() => UpdateDependantList()} 
                                    className="addbtn" 
                                    size="lg">
                                        <FiEdit3 className="dependantplus"/>
                                        Update
                                </btn>
                            }
                            
                        </Col>
                    </Row>
                </Col>
                <Col md={4}>
                    <ul className="dependantlist">
                        {(() => {
                            try {
                                return <>
                                {formData.dependents.map((item) => (
                                    <li className="dependantstep" >
                                    <Row>
                                        <Col md={1} className="mx-1 my-1">
                                            <div className="dependantstepper">{item.id}</div>
                                        </Col>
                                        <Col md={7}>
                                            <div className="dependantname" onClick={() => {
                                                DependantSelect(item)
                                            }}>{item.dependant_surname} {item.dependent_given_name} {item.dependent_other_names}</div>
                                        </Col>
                                        <Col md={1} sm={1}>
                                            <FiEdit3 className="dependantname" onClick={() => {DependantSelect(item)}}/>
                                        </Col>
                                        <Col md={1}  sm={1}>
                                            <FiTrash className="dependantdelete" onClick={() => {
                                                DependantDelete(item)
                                            }}/>
                                        </Col>
                                    </Row>
                                    
                                    </li>
                                ))}
                                </>;
                            }
                            catch (err) {
                                // console.log(err)
                            }
                        })()}
                        
                    </ul>
                </Col>
            </Row>
            
        </Container>
    </div>;
}

export default DependantForm;