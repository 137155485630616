import React from 'react';
import '../styles/HomeMenu.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const PortalNav = ({setpaymentstatus,setqueried,setscheduled, setrejected, page}) => {

    
    return (
        <>
            <Navbar  className="portalnav-container navbar-dark" expand="lg">
                <Container className="mynav">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className=" mx-auto text-center">
                    <Nav.Link className={`menu-item text-white menu-bold portalnav-width`}></Nav.Link>

                    {/* <Nav.Link className={`menu-item text-white menu-bold portalnav-width ${ page===3 ? "portalnav-active" : ""}`} onClick={setscheduled}>Schedule</Nav.Link> */}
                        {/* <Nav.Link className={`menu-item text-white menu-bold portalnav-width ${ page===1 ? "portalnav-active" : ""}`} onClick={setpaymentstatus}>Payment</Nav.Link> */}
                        {/* <Nav.Link className={`menu-item text-white menu-bold portalnav-width ${ page===2 ? "portalnav-active" : ""}`} onClick={setqueried}>Mismatch</Nav.Link> */}
                        
                        {/* <Nav.Link className={`menu-item text-white menu-bold portalnav-width ${ page===3 ? "portalnav-active" : ""}`} onClick={setscheduled}>Schedule</Nav.Link> */}

                        {/* <Nav.Link className={`menu-item text-white menu-bold portalnav-width ${ page===4 ? "portalnav-active" : ""}`} onClick={setrejected}>Deferred</Nav.Link> */}
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}


export default PortalNav;