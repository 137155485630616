import React from 'react';
import '../styles/Global.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import dcic from '../assets/img/dcic.png';
import coatofarms from '../assets/img/coatofarms.png'; 


const Header = () => {
    return (
        <>
            <div className="header-container">
                <Row>
                    <Col className="headercol1"><img className="headerimage" src={dcic} alt="Logo" /></Col>
                    <Col xs={6} className="headertext">
                        <p className="mainheadertext">THE REPUBLIC OF UGANDA<br/>MINISTRY OF INTERNAL AFFAIRS</p>
                        <p className="systemtitle">DIRECTORATE OF CITIZENSHIP & IMMIGRATION CONTROL</p>
                    </Col>
                    <Col className="coatimage"><img className="headerimage " src={coatofarms} alt="Logo" /></Col>
                </Row>
                <div className="black"></div>
                <div className="yellow"></div>
                <div className="red"></div>
            </div>
        
        </>
    )
}

export default Header;