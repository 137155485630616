import React, {useState, useEffect, useContext} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/Global.css';
import '../../styles/CtdHome.css';
import '../../styles/Application.css';
import '../../styles/Status.css';
import HomeMenu from '../../components/HomeMenu';
import HomeButton from '../../components/HomeButton';
import {GlobalStateContext} from "../../context/index";
import constants from '../../constants/constants';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReCAPTCHA from "react-google-recaptcha";
import Button from 'react-bootstrap/Button';

import { ToastContainer } from 'react-toastify';
import { popup } from '../../services/Services';
import 'react-toastify/dist/ReactToastify.css';

import DatePicker from "react-date-picker";
import { StringToDateConverter } from "../../services/Services";

const StatusForm = ({page, setPage}) => {

    const {setApplicationId,setStatus, checkresumeform, setEmailValidation, resumeFormData, setCheckApplicationForm} = useContext(GlobalStateContext);
    const [email, setEmail] = useState(false);
    const [security, setSecurity] = useState([]);
    const [value, setValue] = useState();
    const [applicationnumber, setApplicantNumber] = useState("");
    const [dob, setDOB] = useState("");
    const [surname, setSurname] = useState("");

    useEffect(() => {
        axios.get(constants.url+"/applications/security-questions/")
        .then(res => {
            setSecurity(res.data)
        })
        .catch(err => {
            console.error(err)
        })
        setEmailValidation(false)
    }, [])

    const StatusCheck = () => {

        // setPage(2)
        
        axios.post(constants.url+"/applications/resume/", resumeFormData, {
            headers : {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        }).then(res => {
            setStatus(res.data.status)
            setApplicationId(res.data.application_id)

            setPage(2)
        }).catch(err => {
            if (err.response.data.response === "Application doesnot exists"){
                popup(err.response.data.response);

            }
            else {
                popup("Form incomplete,\n Please check and try again");

            }
            
        })
      
    }

    const fetchApplication = () => {
        let months = ["01","02","03","04","05","06","07","08","09","10","11","12"];
        let date = dob.getFullYear() + "-" + months[dob.getMonth()] + "-" + dob.getDate();
        const requestForm = {
            application_id: applicationnumber,
            date_of_birth: date,
            surname: surname
        }

        if (applicationnumber==="" || date === "" || surname === ""){
            var errordict = {}
            if (applicationnumber==="") errordict["application_number"] = true
            if (date === "") errordict["dob"] = true
            if (surname === "") errordict["surname"] = true
           
            setCheckApplicationForm(errordict) 
        } else {
            axios
                .post(constants.url + "/applications/application-status/", requestForm)
                .then((res) => {
                    setStatus(res.data.status)
                    setApplicationId(res.data.application_id)
                    setPage(2)
                    
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response.data.response === "Application doesnot exists"){
                        popup(err.response.data.response);
        
                    }
                    else {
                        popup("Form incomplete,\n Please check and try again");
        
                    }
                });
        }
    }

    return <Container className='statusform2'>
            <form className='form-container'>
            <Row>
                <Col md={12}>
                    <h3 className="application-title text-center py-1  mt-4">Check Application Status</h3>
                </Col>
                <Col md={12}>
                    <ToastContainer />

                    <div className="form-group required">
                        <label className="mylabel control-label">
                            Application Number
                        </label>
                        <input
                            type="text"
                            className={`form-control myinput`}
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={applicationnumber}
                            onChange={(event) =>
                                setApplicantNumber(event.target.value)
                            }
                        />
                        { checkresumeform.application_number ?  <p className="error-message">Application Number Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
            
                            <label className="mylabel control-label">
                                Date Of Birth
                            </label>
                            <DatePicker
                                className="form-control p-0 myinput m-0"
                                value={StringToDateConverter(dob)}
                                onChange={(date) => setDOB(date)}
                                format="dd-MM-y"
                                minDate={new Date("1900-01-01")}
                                maxDate={new Date("2050-01-01")}
                                placeholderText={"dd-MM-yyyy"}
                                clearIcon={null}
                            />
                            { checkresumeform.dob ?  <p className="error-message">Date Of Birth Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <label className="mylabel control-label">Surname</label>
                    <input
                        type="text"
                        className={`form-control myinput`}
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={surname}
                        onChange={(event) => setSurname(event.target.value)}
                    />
                    { checkresumeform.surname ?  <p className="error-message">Surname Required</p> : null}
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <ReCAPTCHA
                            sitekey="{process.env.GOOGLE_RECAPTCHA_SITE_KEY}"
                        />
                    </div>
                </Col>

                <Button 
                    className="buttontextsize next-btn  btn-grad-secondary resume-btn  my-4" 
                    variant="" size="lg"
                    onClick={fetchApplication}
                    >Check Application Status</Button>
            </Row>
            </form>
        </Container>;
}



export default StatusForm;