import React, {useContext, useState, useEffect} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import '../../styles/Slider.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GlobalStateContext} from "../../context/index";
import axios from 'axios';
import constants from '../../constants/constants';

const ApplicantSlider = ({formData,setFormData}) => {

    const {nationality} = useContext(GlobalStateContext);
    const [language, setLanguage] = useState([]);
    const [professions, setProfessions] = useState([]);

    useEffect(() => {
        axios.get(constants.url+"/common/languages/")
        .then(res => {
            // console.log(res.data)
            setLanguage(res.data)
        })
        .catch(err => {
            console.log(err)
        })

    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(constants.url+"/common/professions/");
            setProfessions(response.data);
        }
        fetchData();
    },[])

    return <>
        <div className="slider-container">
            {/* {console.log(nationality)} */}
            <Row className="slider-body">
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Surname</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.surname}</p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Given Name</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.given_name}</p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Other Names</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.other_names}</p>

                </Col>
                {/* <Col className="slider-item" md={2}>
                    <label className="slider-label">Previous Travel Document Number</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.previous_travel_document_number}</p>

                </Col> */}
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Date of Birth</label> 
                </Col>
                <Col className="slider-item" md={2}>
                    <p className='slider-text'>{(() => {
                        try {
                            return <>{formData.date_of_birth.toLocaleDateString()}</>
                        }
                        catch (e) {
                            // console.log(e)
                            var x = e
                        }
                    })()}</p>
                {/* <p className="slider-text">{formData.date_of_birth.toLocaleDateString()}</p> */}

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Nationality</label> 
                </Col>
                <Col className="slider-item" md={2}>
                    <p className="slider-text">
                    {(() => {
                        for (var i=0; i<nationality.length; i++) {
                            
                            if (nationality[i].id == formData.nationality){
                                return (
                                    <>{nationality[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                    </p>
                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Marital Status</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.marital_status}</p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Gender</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.sex}</p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Profession / Occupation</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">
                    {/* {formData.occupation} */}
                    {(() => {
                        for (var i=0; i<professions.length; i++) {
                            
                            if (professions[i].id == formData.occupation){
                                return (
                                    <>{professions[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                </p>
                </Col>
                <Col className="slider-item" md={2}>

                    <label className="slider-label">Place Of Birth</label> 
                </Col>
                <Col className="slider-item" md={2}>
                    <p className="slider-text">{formData.place_of_birth}</p>
                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Language</label> 
                </Col>
                <Col className="slider-item" md={2}>
                    <p className="slider-text">
                    {(() => {
                        for (var i=0; i<language.length; i++) {
                            
                            if (language[i].id == formData.primary_language){
                                return (
                                    <>{language[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                    </p>
                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Other Languages</label> 
                </Col>
                <Col className="slider-item" md={2}>
                    <p className="slider-text">
                    {(() => {
                        for (var i=0; i<language.length; i++) {
                            
                            if (language[i].id == formData.other_language){
                                return (
                                    <>{language[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                    </p>
                </Col>
            </Row>
        </div> 
    </>;
}



export default ApplicantSlider;