import React, {useState, useEffect, useMemo} from 'react';
import "../styles/Table.css";
import "../styles/Global.css";
import {useTable, useSortBy, useGlobalFilter, usePagination, useRowSelect} from 'react-table';
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import GlobalFilter from './GlobalFilter';


const Table = ({columns, data, title, onclick}) => {

    const table_columns = useMemo(() => columns, [])
    const table_data = useMemo(() => data, [])

    const tableInstance = useTable({
        columns: columns,
        data: data
    },
    
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        prepareRow,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        setGlobalFilter,
        selectedFlatRows,
    } = tableInstance

    const { globalFilter, pageIndex, pageSize } = state
    
    return (
    <>
        
        <div className="table-header">
            <span className="table-title">{title}</span>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        
        <table {...getTableProps()} className="table">

            <thead>
                {
                    headerGroups.map((headerGroup) => (

                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map( (column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? <FiArrowDown/>: <FiArrowUp/>) : ''}
                                        </span>
                                        
                                    </th>
                                ))
                            }
                            
                        </tr>
                        
                    ))
                }
                
            </thead>
            <tbody {...getTableBodyProps()}>
                {
                    page.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} onClick={() => {onclick(row.original)}}>
                                {
                                    row.cells.map ((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })
                                }
                                
                            </tr>
                        );
                    })
                }
                
            </tbody>

        </table>
        
        <div className="table-footer">
            <span>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong> { ' ' }
            </span>
            <span>
                | Go to page: {' '}
                <input className="table-input table-page-search" type='number' defaultValue={pageIndex+1}
                onChange = {(e) => {
                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(pageNumber)
                }}
                />
            </span>
            
            <button className="right-align tablebtn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}> {'>>'} </button>
            <button className="right-align tablebtn" onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
            <button className="right-align tablebtn" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
            <button className="right-align tablebtn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}> {'<<'} </button>

            <select className="table-input right-align tableselect" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                {
                    [10,25,50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))
                }
            </select>

            
        </div>
    </>
    );
}

export default Table;