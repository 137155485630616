import React from 'react';
import "../../../styles/Portal.css";
import "../../../styles/querysteps.css";

const Application = () => {
    return <>
        <div className="row">

            <div className="col-md-4 query-body-header">
                
            </div>
            <div className="col-md-4 query-body-header">
                Applicant Details
            </div>
            <div className="col-md-4 query-body-header">
                OPM Details
            </div>
            
            

        </div>
    </>;
}


export default Application;