import React, {useEffect, useState, useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import constants from '../../constants/constants';
import axios from 'axios';
import {GlobalStateContext} from "../../context/index";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { telephoneChecker, emailChecker } from '../../services/Services';
import { GetCounties, GetSubCounties, GetParish, GetVillages } from "../../services/Services";


const NOKForm = ({formData,setFormData,nokform, setNokform}) => {

    const {
        setEmailValidation, 
        settelephoneValidation,
        NameToID, countries, districts, relationships,
        nationality
    } = useContext(GlobalStateContext)

    const [address, setAddress] = useState(false);
    const [addressout, setAddressout] = useState(false);

    useEffect(() => {
        setEmailValidation(false);
        settelephoneValidation(false);

    }, [])

    const selectCountry = (event) => {
        setFormData({...formData, next_of_kin_country: event.target.value});
        var index = event.nativeEvent.target.selectedIndex;
        var text = event.nativeEvent.target[index].text
        var country = text.toLowerCase();

        if (country === "uganda"){
            setAddress(true);
            setAddressout(false);
        }
        else {
            setAddress(false);
            setAddressout(true);
        }

    }

    const [phonestatus, setPhonestatus] = useState(false);
    const [emailstatus, setEmailstatus] = useState(false);
    const [phonetwostatus, setPhonetwostatus] = useState(false);

    const validatephone = (phone) => {
        var check = telephoneChecker(phone)
        setPhonestatus(check)
    }

    const validatephonetwo = (phone) => {
        var check = telephoneChecker(phone)
        setPhonetwostatus(check)
    }

    const validateEmail = (email) => {
        var check = emailChecker(email)
        setEmailstatus(check)
    }

    const [filteredcounties, setFilteredcounties] = useState([]);
    const [filteredsubcounties, setFilteredsubcounties] = useState([]);
    const [filteredparish, setFilteredparish] = useState([]);

    const SelectDistict = async (name) => {
        setFormData({...formData, next_of_kin_district: name})
        var c = await GetCounties(name)
        setFilteredcounties(c)
    }

    const SelectCounty = async (name) => {
        setFormData({...formData, next_of_kin_county: name})
        var s = await GetSubCounties(name)
        setFilteredsubcounties(s)
    }

    const SelectSubCounty = async (name) => {
        setFormData({...formData, next_of_kin_sub_county: name})
        var s = await GetParish(name)
        setFilteredparish(s)
    }

    // useEffect(() => {
    //     const ConvertNameToID = async () => {
    //         NameToIDAsync(formData.next_of_kin_nationality, countries)
    //     }
    // },[])

    return <div className="form-container">
        <Container>
            <Row>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Surname</label>
                        <input type="text" className={`form-control myinput ${nokform.next_of_kin_surname ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.next_of_kin_surname} 
                        onChange={(event) => setFormData({...formData, next_of_kin_surname: event.target.value})}/>
                        { nokform.next_of_kin_surname ? <p className="error-message">Surname Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Given Name</label>
                        <input type="text" className={`form-control myinput ${nokform.next_of_kin_given_name ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.next_of_kin_given_name} 
                        onChange={(event) => setFormData({...formData, next_of_kin_given_name: event.target.value})}/>
                        { nokform.next_of_kin_given_name ? <p className="error-message">Given Name Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Other Names</label>
                        <input type="text" className={`form-control myinput `} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.next_of_kin_other_name} 
                        onChange={(event) => setFormData({...formData, next_of_kin_other_name: event.target.value})}/>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Country</label>
                        <select className={`custom-select form-control myinput ${nokform.next_of_kin_country ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.next_of_kin_country, countries)} 
                        onChange={(event) => selectCountry(event)}>
                            <option selected>-------</option>
                            {countries.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { nokform.next_of_kin_country ? <p className="error-message">Country Required</p> : null}
                    </div>
                </Col>
                {address && <><Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">District</label>

                        <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                        value={NameToID(formData.next_of_kin_district, districts)} 
                        onChange={(event) => SelectDistict(event.target.value)}>
                            <option selected>------</option>
                            {districts.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">County</label>

                        <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                        value={NameToID(formData.next_of_kin_county, filteredcounties)} 
                        onChange={(event) => SelectCounty(event.target.value)}>
                            <option selected>------</option>
                            {filteredcounties.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Sub Country</label>

                        <select className={`custom-select form-control myinput`} id="inputGroupSelect01"
                        value={NameToID(formData.next_of_kin_sub_county, filteredsubcounties)} 
                        onChange={(event) => setFormData({...formData, next_of_kin_sub_county: event.target.value})}>
                            <option selected>------</option>
                            {filteredsubcounties.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Plot Number</label>
                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.next_of_kin_plot_number} 
                        onChange={(event) => setFormData({...formData, next_of_kin_plot_number: event.target.value})}/>
                        
                    </div>
                </Col>
                </>
                }

                {
                    addressout && 
                    <Col md={4}>
                        <div className="form-group">
                            <label className="mylabel control-label">Address Out Of Uganda</label>
                            <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                            value={formData.next_of_kin_foreign_address} 
                            onChange={(event) => setFormData({...formData, next_of_kin_foreign_address: event.target.value})}/>
                        </div>
                    </Col>
                }
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Relationship</label>
                        <select className={`form-control myinput ${nokform.next_of_kin_relationship ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.next_of_kin_relationship, relationships)} 
                        onChange={(event) => setFormData({...formData, next_of_kin_relationship: event.target.value})}>
                            <option value={0} selected>-------</option>
                            {relationships.map((option, index) => (
                                    <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { nokform.next_of_kin_relationship ? <p className="error-message">Relationship Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Nationality</label>
                        <select className={`form-control myinput ${nokform.next_of_kin_nationality ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.next_of_kin_nationality, nationality)} 
                        onChange={(event) => setFormData({...formData, next_of_kin_nationality: event.target.value})}
                        >
                            <option value={0} selected>-------</option>
                            {nationality.map((option, index) => (
                                    <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { nokform.next_of_kin_nationality ? <p className="error-message">Nationality Required</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Passport Number/ID Number</label>
                        <input type="text" className={`form-control myinput`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.next_of_kin_id_number} 
                        onChange={(event) => setFormData({...formData, next_of_kin_id_number: event.target.value})}/>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Telephone Number</label>
                        <PhoneInput
                            country={'ug'}
                            inputClass={`phone-input ${phonestatus ? 'error': ''} ${nokform.next_of_kin_primary_telephone_number ? 'error': ''}`}
                            onBlur={(event) => validatephone(event.target.value)}
                            value={formData.next_of_kin_primary_telephone_number} 
                            onChange={(event) => setFormData({...formData, next_of_kin_primary_telephone_number: event})}
                            />
                        { nokform.next_of_kin_primary_telephone_number ? <p className="error-message">Telephone Number Required</p> : null}
                        { phonestatus ? <p className="error-message">Phone Number Should Be In The Format +2567xxxxxxxx</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Other Phone Number</label>
                        <PhoneInput
                            country={'ug'}
                            inputClass={`phone-input ${phonetwostatus ? 'error': ''}`}
                            onBlur={(event) => validatephonetwo(event.target.value)}
                            value={formData.next_of_kin_other_telephone_number} 
                            onChange={(event) => setFormData({...formData, next_of_kin_other_telephone_number: event})}
                            />
                        { phonetwostatus ? <p className="error-message">Phone Number Should Be In The Format +2567xxxxxxxx</p> : null}
                    </div>
                </Col>
                <Col md={4}>
                    <div className="form-group">
                        <label className="mylabel control-label">Email Address</label>
                        <input placeholder="example@email.com" type="text" className={`form-control myinput ${emailstatus ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.next_of_kin_email_address} 
                        onChange={(event) => setFormData({...formData, next_of_kin_email_address: event.target.value})}
                        onBlur={(event) => validateEmail(event.target.value)}/>
                        {emailstatus ? <p className="error-message">Incorrect Email Format</p> : null}
                    </div>
                </Col>
            </Row>
        </Container>
    </div>;
}

export default NOKForm;