import React from 'react';
import '../../styles/Global.css';
import '../../styles/Portal.css';

const OTP = () => {
    return (
    <div className="portalcontainer">
        <div className="otpcontainer">
            {/* <div className="horizontal-orientation"> */}
                <input type="text" className="form-control myinput otpfield" maxlength="1" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
                <input type="text" className="form-control myinput otpfield" maxlength="1" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
                <input type="text" className="form-control myinput otpfield" maxlength="1" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
                <input type="text" className="form-control myinput otpfield" maxlength="1" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
                <input type="text" className="form-control myinput otpfield" maxlength="1" aria-label="Default" aria-describedby="inputGroup-sizing-default"/>
            {/* </div> */}
        </div>
    </div>
    );
}

export default OTP;