import React, {useState, useEffect, useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import constants from '../../constants/constants';
import axios from 'axios';
import {GlobalStateContext} from "../../context/index";

const ApplicationsForm = ({formData,setFormData, setApplicationsform, applicationsform}) => {

    const [data, setData] = useState({"date": ""});
    const [applicationtype, setApplicationtype] = useState([]);

    const {submission_location, NameToID,legalStatus} = useContext(GlobalStateContext)

    const [submission_loc, setSubmission_loc] = useState()
    useEffect(()=>{
        setSubmission_loc(submission_location);

    }, [])

    useEffect(()=>{
        setApplicationsform({})
    }, [])
    
    return <div  className="form-container">
        <Container>
            <Row>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Priority</label>
                        <select className={`custom-select form-control myinput  ${applicationsform.processing_speed ? 'error': ''}`} id="inputGroupSelect01"
                        value={formData.processing_speed} 
                        onChange={(event) => setFormData({...formData, processing_speed: event.target.value})}>
                            {/* <option selected>-------</option> */}
                            <option selected value="1">Standarad</option>
                            <option disabled value="2">Express</option>

                        </select>
                        { applicationsform.application_type ? <p className="error-message">Priority Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Legal Status</label>
                        <select className={`custom-select form-control myinput ${applicationsform.legal_status ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.legal_status, legalStatus)} 
                        onChange={(event) => setFormData({...formData, legal_status: event.target.value})}>
                            <option selected>-------</option>
                            {legalStatus.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { applicationsform.legal_status ? <p className="error-message">Legal Status Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Type of Application</label>
                        <select className={`custom-select form-control myinput  ${applicationsform.application_type ? 'error': ''}`} id="inputGroupSelect01"
                        value={formData.application_type} 
                        onChange={(event) => setFormData({...formData, application_type: event.target.value})}>
                            <option selected>-------</option>
                            <option value="1">New Application</option>
                            <option value="4">Renewal</option>
                            <option value="2">Lost</option>
                            <option value="6">Change Of Details</option>
                            <option value="3">Damaged</option>

                        </select>
                        { applicationsform.application_type ? <p className="error-message">Type of Application Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Place Of Submission</label>
                        <select className={`custom-select form-control myinput ${applicationsform.place_of_submission ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.place_of_submission, submission_location)} 
                        onChange={(event) => setFormData({...formData, place_of_submission: event.target.value})}>
                            <option selected>-------</option>
                            {submission_location.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { applicationsform.place_of_submission ? <p className="error-message">Place Of Submission Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Place of Issue</label>
                        <select className={`custom-select form-control myinput ${applicationsform.place_of_issue ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.place_of_issue, submission_location)} 
                        onChange={(event) => setFormData({...formData, place_of_issue: event.target.value})}>
                            <option selected>-------</option>
                            {submission_location.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { applicationsform.place_of_issue ? <p className="error-message">Place Of Issue Required</p> : null}
                    </div>
                </Col>
                {(() => {
                            
                                
                    if (formData.application_type === "Renewal" || formData.application_type === "Lost" || formData.application_type === "Damaged"){
                        return (
                            <>
                                
                                <Col md={12}>
                                    <div className="form-group">
                                        <label className="mylabel control-label">Details of old/lost CTD</label>
                                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                                        value={formData.previous_travel_document_number} 
                                        onChange={(event) => setFormData({...formData, previous_travel_document_number: event.target.value})}/>
                                    </div>
                                </Col>
                                
                            </>
                        )
                        
                    }
                             
                           
                })()}

            </Row>
        </Container>
    </div>;
}

export default ApplicationsForm;