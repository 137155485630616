import React, {useState, useEffect, useContext} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/Global.css';
import '../../styles/CtdHome.css';
import '../../styles/Application.css';
import '../../styles/Status.css';
import HomeMenu from '../../components/HomeMenu';
import HomeButton from '../../components/HomeButton';
import {GlobalStateContext} from "../../context/index";
import constants from '../../constants/constants';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReCAPTCHA from "react-google-recaptcha";
import Button from 'react-bootstrap/Button'; 
import StatusForm from './StatusForm';
import StatusPage from './StatusPage';

const StatusHome = () => {

    const [email, setEmail] = useState(false);
    const [security, setSecurity] = useState([]);
    const {resumeFormData, setResumeFormData, setStatus, setApplicationId} = useContext(GlobalStateContext);
    const [value, setValue] = useState();
    const [page, setPage] = useState(1);

    useEffect(() => {
        var application_id = sessionStorage.getItem("id")
        if (application_id != null) {
            setStatus(sessionStorage.getItem("status"));
            setApplicationId(sessionStorage.getItem("application_id"));
            sessionStorage.clear();
            setPage(2)
            
        }
    },[])

    

    const handlePage = () => {

        if (page === 1) {
            return (
                <StatusForm setPage={setPage}/>
            );
        } 
        else if (page === 2) {
            return (
                <StatusPage/>
            );
        } 
        else {
            setPage(1)
        }

    }


    return (
    <>
        <div className="home-container">
            <HomeMenu/>
            <div className="status-container">
                {handlePage()}
            </div>
            
        </div>
        
    </>);
}


export default StatusHome;