import React, {useState, useEffect, useContext} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/Global.css';
import '../../styles/CtdHome.css';
import '../../styles/Application.css';
import '../../styles/Status.css';
import HomeMenu from '../../components/HomeMenu';
import HomeButton from '../../components/HomeButton';
import {GlobalStateContext} from "../../context/index";
import constants from '../../constants/constants';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReCAPTCHA from "react-google-recaptcha";
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { FadingBalls } from "react-cssfx-loading";
import Preloader from '../../components/Preloader';
import '../../styles/Status2.css';

// SVG
// import onegreen from '../../assets/svg/onegreen.svg';
// import linegray from '../../assets/svg/linegray.svg';
// import linegreen from '../../assets/svg/linegreen.svg';
// import twogray from '../../assets/svg/twogray.svg';
// import twogreen from '../../assets/svg/twogreen.svg';
// import threegray from '../../assets/svg/threegray.svg';
// import threegreen from '../../assets/svg/threegreen.svg';

// SVG New
import onegreen from '../../assets/svg/update/onegreen.svg';
import onegrey from '../../assets/svg/update/onegrey.svg';
import greyline from '../../assets/svg/update/greyline.svg';
import greenline from '../../assets/svg/update/greenline.svg';
import twogrey from '../../assets/svg/update/twogrey.svg';
import twogreen from '../../assets/svg/update/twogreen.svg';
import threegrey from '../../assets/svg/update/threegrey.svg';
import threegreen from '../../assets/svg/update/threegreen.svg';
import fourgrey from '../../assets/svg/update/fourgrey.svg';
import fourgreen from '../../assets/svg/update/fourgreen.svg';
import fivegrey from '../../assets/svg/update/fivegrey.svg';
import fivegreen from '../../assets/svg/update/fivegreen.svg';

const StatusPage = () => {

    const [lineone, setLineone] = useState(false);
    const [linetwo, setLinetwo] = useState(false);
    const [linethree, setLinethree] = useState(false);
    const [greentwo, setGreenTwo] = useState(false);
    const [greenthree, setGreenThree] = useState(false);
    const [greenfour, setGreenFour] = useState(false);
    const [greenfive, setGreenFive] = useState(false);

    const [paymentpending, setPaymentpending] = useState(false);
    const [paymentconfirmed, setPaymentconfirmed] = useState(false);

    const [pendingscheduling, setPendingscheduling] = useState(false);
    const [scheduled, setScheduled] = useState(false);

    const [pendingenrollment, setPendingenrollment] = useState(false);
    const [enrolled, setenrolled] = useState(false);

    const [pendingprocessing, setPendingprocessing] = useState(false);
    const [proccessed, setproccessed] = useState(false);

    const [complete, setComplete] = useState(false);
    const [missed, setMissed] = useState(false)

    // Context
    const {status, setStatus, applicationid} = useContext(GlobalStateContext);
    const [appointmentdate, setApplicationdate] = useState('');
    const [timeslot, setTimeslot] = useState([]);
    const [specifictime, setSpecificTime] = useState('');

    //Preloader
    const [preloader, setPreloader] = useState(true);

    // Status Change
    useEffect(() => {

        if (status == 'draft' || status == 'submitted') {
            setLineone(true);

            setPaymentpending(true);
            setPaymentconfirmed(false);

        }
        if (status == 'PRN Cleared'){
            setLineone(true);
            setGreenTwo(true);

            setPaymentpending(false);
            setPaymentconfirmed(true);

            setPendingscheduling(true);
            setScheduled(false);
        }
        if (status == 'scheduled' || status == 'appointment missed' || status == 'pulled'){

            setLineone(true);
            setLinetwo(true);
            setGreenTwo(true);
            setGreenThree(true);

            setPaymentpending(false);
            setPaymentconfirmed(true);

            setPendingscheduling(false);
            setScheduled(true);

            if (status == 'appointment missed'){
                setMissed(true)
            }

            if (status == 'pulled'){
                setComplete(true)
            }
        }
        
    },[])


    useEffect(() => {

        axios.get(constants.url+"/reservations/appointment-slots/")
        .then(res => {
            setTimeslot(res.data)
        })
        .catch(err => {
            console.error(err)
        })
        
        axios.get(constants.url+"/reservations/reservations/?search="+applicationid)
        .then(res => {
            if (res.data.count > 0){
                setApplicationdate(res.data.results[0].appointment_date)
                for (var x=0; x < timeslot.length; x++){
                    if (timeslot[x].id == res.data.results[0].time_slot){
                        setSpecificTime(timeslot[x].start_time + " - "+ timeslot[x].end_time)
                    }
                }
            }

        })
        .catch(err => {
            console.error(err)
        })

        

    },[])

    useEffect(() => {
        setPreloader(true);
        setTimeout(() => {
            setPreloader(false);
        }, 1500);
    },[])

    const [hideline, setHideline] = useState(false)

    

    return (
        <>
        { preloader ? <Preloader /> : null }
        {/* <FadingBalls color="#FF0000" duration="3s" /> */}
    <Container className='statusform'>
        
        <div>
            <p className="text-center py-5 header-text">Application Status </p>
        </div>
        <div className='new-svg-container pt-5'>
            <div>
                <img className="statussvg" src={onegreen} alt="One" />
            </div>
            <div>
                <img className="statussvg linesvg" hidden={lineone === true} src={greyline} alt="line gray" />
                <img className="statussvg linesvg" hidden={lineone === false} src={greenline} alt="line gray" />
            </div>
            <div>
                <img className="statussvg" hidden={greentwo === true} src={twogrey} alt="Two Gray" />
                <img className="statussvg" hidden={greentwo === false} src={twogreen} alt="Two Green" />
            </div>
            <div>
                <img className="statussvg linesvg" hidden={linetwo === true} src={greyline} alt="line gray" />
                <img className="statussvg linesvg" hidden={linetwo === false} src={greenline} alt="line gray" />
            </div>
            <div>
                <img className="statussvg" hidden={greenthree === true} src={threegrey} alt="Three Gray" />
                <img className="statussvg" hidden={greenthree === false} src={threegreen} alt="Three Green" />
            </div>
            <div>
                <img className="statussvg linesvg" hidden={linetwo === true} src={greyline} alt="line gray" />
                <img className="statussvg linesvg" hidden={linetwo === false} src={greenline} alt="line gray" />
            </div>
            <div>
                <img className="statussvg" hidden={greenfour === true} src={fourgrey} alt="four Gray" />
                <img className="statussvg" hidden={greenfour === false} src={fourgreen} alt="four Green" />
            </div>
            <div>
                <img className="statussvg linesvg" hidden={linethree === true} src={greyline} alt="line gray" />
                <img className="statussvg linesvg" hidden={linethree === false} src={greenline} alt="line gray" />
            </div>
            <div>
                <img className="statussvg" hidden={greenfour === true} src={fivegrey} alt="Three Gray" />
                <img className="statussvg" hidden={greenfour === false} src={fivegreen} alt="Three Green" />
            </div>
        </div>
        {/* Words */}
        <div className='new-svg-container'>
            <Row className='w-100 p-0 m-0'>
                <Col sm={2}>
                    <p className={` body-text pt-2 ${lineone ? '' : 'text-grey'}`}>Submitted</p>
                    <p className="text-maroon" hidden={paymentpending === false}>Payment Pending</p>
                </Col>
                
                <Col sm={2}>
                    <p className={`textRight body-text pt-2 ${greentwo ? '' : 'text-grey'}`}>Paid</p>
                    <p className="textRight text-maroon" hidden={pendingscheduling == false}>Pending Scheduling</p>
                </Col>
                <Col sm={1}></Col>

                <Col sm={2}>
                    <p className={`text-center body-text pt-2 ${greenthree ? '' : 'text-grey'}`}>Scheduled</p>
                    <p className="text-center text-green" hidden={complete === false}>Application Complete</p>
                    <p className="text-center text-maroon" hidden={missed === false}>Appointment Missed</p>
                    <p className="text-center text-green" hidden={greenthree === false}>For <br/> {appointmentdate} <br/> {specifictime}</p>
                </Col>
            <Col sm={1}></Col>

                <Col sm={2}>
                    <p className={`text-center body-text pt-2 ${greenfour ? '' : 'text-grey'}`}>Enrolled</p>
                    <p className="text-center text-maroon" hidden={pendingenrollment == false}>Pending Document Processing</p>
                </Col>
                <Col sm={2}>
                    <p className={`textRight text-center body-text pt-2 ${greenfive ? '' : 'text-grey'}`}>Processed</p>
                </Col>
            </Row>
        </div>
    </Container>
    </>
    );
}


export default StatusPage;