import React from 'react';
import "../../../styles/Portal.css";
import "../../../styles/querysteps.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from '../../Table';

const Applicant = ({data}) => {
    
    return <>
        <div className="row mx-1">

            <table className="query-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Applicant Details</th>
                        <th>OPM Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="query-side-label">Refugue Number</td>
                        <td>1000001</td>
                        <td>1000001</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Surname</td>
                        <td className="text-red">Aaron</td>
                        <td>Aron</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Other Names</td>
                        <td>Mike</td>
                        <td>Mike</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Gender</td>
                        <td>Male</td>
                        <td>Male</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Date Of Birth</td>
                        <td>01/01/2001</td>
                        <td>01/01/2001</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Occupation</td>
                        <td>Farmer</td>
                        <td>Farmer</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Legal Status</td>
                        <td>Refugee</td>
                        <td>Refugee</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Application Type</td>
                        <td>Express</td>
                        <td>Express</td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Details Of Old/lost CTD</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="query-side-label">Place Of Issue</td>
                        <td>Kampala</td>
                        <td>Kampala</td>
                    </tr>
                </tbody>
            </table>

            {/* <div className="col-md-4 query-body-header">
                
            </div>
            <div className="col-md-4 query-body-header">
                Applicant Details
            </div> */}
            {/* <div className="col-md-4 query-body-header">
                OPM Details
            </div> */}
            {/* <div className="col-md-4 query-side-label">
                Refugue Number
            </div>
            <div className="col-md-4">
                1000001
            </div>
            <div className="col-md-4">
                1000001
            </div> */}
            {/* <div className="col-md-4 query-side-label">
                Surname
            </div>
            <div className="col-md-4">
                Aaron
            </div>
            <div className="col-md-4">
                Aaron
            </div> */}
            {/* <div className="col-md-4 query-side-label">
                Other Names
            </div>
            <div className="col-md-4">
                Mike
            </div>
            <div className="col-md-4">
                Mike
            </div>
            <div className="col-md-4 query-side-label">
                Gender
            </div>
            <div className="col-md-4">
                Male
            </div>
            <div className="col-md-4">
                Male
            </div>
            <div className="col-md-4 query-side-label">
                Date Of Birth
            </div>
            <div className="col-md-4">
                01/01/2001
            </div>
            <div className="col-md-4">
                01/01/2001
            </div>
            <div className="col-md-4 query-side-label">
                Occupation
            </div>
            <div className="col-md-4">
                Farmer
            </div>
            <div className="col-md-4">
                Farmer
            </div>
            <div className="col-md-4 query-side-label">
                Legal Status
            </div>
            <div className="col-md-4">
                Refugee
            </div>
            <div className="col-md-4">
                Refugee
            </div>
            <div className="col-md-4 query-side-label">
                Application Type
            </div>
            <div className="col-md-4">
                Express
            </div>
            <div className="col-md-4">
                Express
            </div>
            <div className="col-md-4 query-side-label">
                Details Of Old/lost CTD
            </div>
            <div className="col-md-4">
                -
            </div>
            <div className="col-md-4">
                -
            </div>
            <div className="col-md-4 query-side-label">
                Place Of Issue
            </div>
            <div className="col-md-4">
                Kampala
            </div>
            <div className="col-md-4">
                Kampala
            </div> */}

        </div>
    </>;
}


export default Applicant;