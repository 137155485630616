import React, {useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import '../../styles/Slider.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GlobalStateContext} from "../../context/index";

const ResidentialSlider = ({formData,setFormData}) => {

    const {countries, setCountries, districts, setDistricts, counties, setCounties, subcounties, setSubcounties, parishes, setParish, villages, setVillages} = useContext(GlobalStateContext)

    return <>
        <div className="slider-container">
            <Row className="slider-body">
                <Col md={2}>
                    <label className="slider-label">Country Of Residence</label> 
                    
                    {/* <p className="slider-text">{formData.country_of_residence}</p> */}
                </Col>
                <Col md={2}>
                    <p className="slider-text">
                        Uganda
                    {/* {(() => {
                            for (var i=0; i<countries.length; i++) {
                                
                                if (countries[i].id == formData.country_of_residence){
                                    return (
                                        <>{countries[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()} */}
                        </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">District</label> 
                    
                    {/* <p className="slider-text">{formData.district}</p> */}
                </Col>
                <Col md={2}>
                <p className="slider-text">
                    {(() => {
                            for (var i=0; i<districts.length; i++) {
                                
                                if (districts[i].id == formData.district){
                                    return (
                                        <>{districts[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">County/Municipality</label>
                    
                    {/* <p className="slider-text">{formData.county}</p> */}
                </Col>
                <Col md={2}>
                <p className="slider-text"> 
                    {(() => {
                            for (var i=0; i<counties.length; i++) {
                                
                                if (counties[i].id == formData.county){
                                    return (
                                        <>{counties[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Sub-County</label>
                    
                    {/* <p className="slider-text">{formData.sub_county}</p> */}
                </Col>
                <Col md={2}>
                <p className="slider-text"> 
                    {(() => {
                            for (var i=0; i<subcounties.length; i++) {
                                
                                if (subcounties[i].id == formData.sub_county){
                                    return (
                                        <>{subcounties[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Parish/Ward</label> 
                    
                    {/* <p className="slider-text">{formData.parish}</p> */}
                </Col>
                <Col md={2}>
                <p className="slider-text">
                    {(() => {
                            for (var i=0; i<parishes.length; i++) {
                                
                                if (parishes[i].id == formData.parish){
                                    return (
                                        <>{parishes[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Village LC 1/Zone</label>
                    
                    {/* <p className="slider-text">{formData.village}</p> */}
                </Col>
                <Col md={2}>
                <p className="slider-text"> 
                    {(() => {
                            for (var i=0; i<villages.length; i++) {
                                
                                if (villages[i].id == formData.village){
                                    return (
                                        <>{villages[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Plot Number</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.plot_number}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Address out of Uganda</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.out_of_uganda_address}</p>

                </Col>
                
            </Row>
        </div>
    </>;
}


export default ResidentialSlider;