import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import dcic from '../assets/img/dcic.png';
import coatofarms from '../assets/img/coatofarms.png'; 
import '../styles/Global.css';
import '../styles/HomeButton.css';
import Footer from './Footer';
import HomeMenu from './HomeMenu';
import Button from 'react-bootstrap/Button';


const HomeButton = (props) =>{

    const GotToCTD = (page) => {
        console.log(page)
        console.log("Me clicked")
    }

    // onClick={() => {GotToCTD(props.page)}}


    return (
        <div className="buttoncontainer">
            <h5 className="buttontext textbold text-white">{props.title}</h5><br/>
            <p className="buttontext otherbuttontext text-white">{props.body}</p>
            {/* variant={props.variant}  */}     
            <Button onClick={props.clickeventhandler}  className={`buttonstyle buttontextsize ${props.btntype}`} size="lg">{props.name}</Button>
        </div>
    )
    
}

export default HomeButton