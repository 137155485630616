import React, {useEffect, useContext, useState} from 'react';
import Card from '../Card';
import "../../styles/Portal.css";
import "../../styles/Global.css";
import Table from "../Table";
import {GlobalStateContext} from "../../context/index";
import constants from '../../constants/constants';
// import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';
import Button from 'react-bootstrap/Button'; 
import { AiOutlineDownSquare } from "react-icons/ai";

const PaymentStatus = () => {

    const [data, setData] = useState([]);
    const [paiddata, setPaidData] = useState([]);

    const {FetchApplications, paymentpage} = useContext(GlobalStateContext);
    
    const columns = [ 
        // { Header: '#', accessor: 'id' }, 
        { Header: 'Application', accessor: 'application_id' }, 
        { Header: 'Surname', accessor: 'surname' }, 
        { Header: 'Given Name', accessor: 'given_name' },  
        { Header: 'Refugee No', accessor: 'refugee_No' },
        { Header: 'Application Type', accessor: 'application_type' },
        { Header: 'Nationality', accessor: 'nationality' },
        { Header: 'Phone Number', accessor: 'phone_number' },
        { Header: 'Place Of Submission', accessor: 'place_of_submission' },
        { Header: 'Sex', accessor: 'sex' },
        { Header: 'Language', accessor: 'primary_language' },

    ]

    const Grouped_columns = [
        { Header: 'Id', accessor: 'id', Footer: 'Id' }, 
        {
            Header: "Name",
            Footer: "Name",
            columns: [
                { Header: 'First Name', accessor: 'first_name', Footer: 'First Name' }, 
                { Header: 'Last Name', accessor: 'last_name', Footer: 'Last Name' },  
            ]
        },
        {
            Header: "Info",
            Footer: "Info",
            columns: [
                { Header: 'Date Of Birth', accessor: 'date_of_birth', Footer: 'Date Of Birth' },
            ]
        }
    ]

    useEffect(() => {

        axiosInstance.get(constants.url+'/opm/un-paid-applications/', 
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        }
        )
        .then(res => {
            setData(res.data.results)
        })

        axiosInstance.get(constants.url+'/opm/paid-applications/', 
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        }
        )
        .then(res => {
            setPaidData(res.data.results)
        })

        
        
    },[])

    return (
    <div className="inner-portal">
        <Card >
            <div className="card-container">
                {(() => {

                    if (paymentpage === 1){
                        return (
                            <Table columns={columns} data={data} title="Table of UnPaid PRNs" />
                        );
                    }

                    else if (paymentpage === 2){
                        return (
                            <Table columns={columns} data={paiddata} title="Table of Paid PRNs" />
                        );
                    }
                    
                })()}
                
            </div>
        </Card>

        {(() => {
            // if (paymentpage === 1){

            //     return (
            //         <div className="text-center">

            //             <Button 
                            
            //                 className="buttontextsize btn-grad-secondary"
            //                 size="lg">
            //                     <AiOutlineDownSquare size={25} className="slider-icon"/> 
            //                     Check Payment Status
            //             </Button>

            //         </div>
                    
            //     );

            // }
            // if (paymentpage === 2){

            //     return (
            //         <div className="text-center">

            //             <Button 
            //                 // onClick={handleShow}
            //                 className="buttontextsize btn-grad-secondary"
            //                 size="lg">
            //                     <AiOutlineDownSquare size={25} className="slider-icon"/> 
            //                     Validate Application
            //             </Button>

            //         </div>
                    
            //     );

            // }
        })()}
    </div>
    );
}


export default PaymentStatus;