import React, {useState, useContext} from 'react';
import '../../styles/Slider.css';
import '../../styles/Global.css';
import '../../styles/Application.css';
import '../../styles/Slider.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'; 
import { FiSend } from "react-icons/fi";
import axios from 'axios';
import constants from '../../constants/constants';
import ConfirmationModal from '../ConfirmationModal';
import {GlobalStateContext} from "../../context/index";

const PaymentSlider = ({formData,setFormData}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {submitApplicationform, applicationsubmit_click, setApplicationSubmitClick} = useContext(GlobalStateContext)

    const SubmitForm = () => { 

        submitApplicationform()
        
        // var form_data = new FormData();
        // for (var key in formData) {
        //     form_data.append(key, formData[key]);
        // }

        
        // axios.post(constants.url+"/applications/applications/", formData)
        // .then(res => {
        //     console.log(form_data);
        //     console.log(res.data)
        // })
        // .catch(err => {
        //     console.log(form_data);
        //     console.error(err)
        // })
    }

    return <>
        <div className="slider-container">
            <Row className="slider-body">
                <Col className="slider-item" md={3}>
                    <label className="slider-label">Payment Mode</label> 
                </Col>
                <Col className="slider-item" md={9}>
                <p className="slider-text">{formData.paymentmode}</p>

                </Col>
                {/* <Col className="slider-item" md={3}>
                    <label className="slider-label">Payment Bank</label> 
                </Col>
                <Col className="slider-item" md={3}>
                <p className="slider-text">{formData.paymentbank}</p>

                </Col> */}
                <Col className="text-center">
                    <Button 
                        onClick={handleShow}
                        className="buttontextsize"
                        variant="success" size="lg"
                        disabled={applicationsubmit_click}>
                            <FiSend size={25} className="slider-icon"/> 
                            Submit Application</Button>                            
                </Col>
            </Row>
            <ConfirmationModal show={show} setShow={setShow} myclick={SubmitForm}/>
        </div>
    </>;
}



export default PaymentSlider;