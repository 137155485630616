import React, {useContext} from 'react';
import {GlobalStateContext} from "../context/index";
import {Navigate, useLocation} from "react-router-dom";

const RequireAuth = ({children}) => {
    const location = useLocation()
    const {user} = useContext(GlobalStateContext);
    const access = sessionStorage.getItem("access")
    const refresh = sessionStorage.getItem("refresh")

    if (access==null) {
        return <Navigate to="/ctd/login" state={{path: location.pathname}} />
    }

    return children;
}


export default RequireAuth;