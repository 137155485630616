import React, {useEffect, useState, useContext} from 'react';
import '../styles/Application.css';
import '../styles/Global.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'; 
import ReCAPTCHA from "react-google-recaptcha";
import {GlobalStateContext} from "../context/index";
import constants from '../constants/constants';
import axios from 'axios';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer } from 'react-toastify';
import { popup } from '../services/Services';
import HomeMenu from '../components/HomeMenu';


const ResumeApplication = () => {

    const [email, setEmail] = useState(false);
    const [security, setSecurity] = useState([]);
    const {checkresumeform, setCheckApplicationForm,useResumeApplication,resumeFormData, setResumeFormData,emailvalidation, setEmailValidation, validateEmail, telephoneValidation, settelephoneValidation,telephoneCheck} = useContext(GlobalStateContext);
    const [value, setValue] = useState()

    useEffect(() => {
        axios.get(constants.url+"/applications/security-questions/")
        .then(res => {
            setSecurity(res.data)
        })
        .catch(err => {
            console.error(err)
            
        })
        setEmailValidation(false)
    }, [])

    return (
        <div className='home-container'>
            <HomeMenu/>
    <div className="application-container">
        
        <Container className="applicationform">
            <ToastContainer/>
            <Row>
                <Col md={12}>
                    <h3 className="application-title text-center py-1 mt-4">Resume Application</h3>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Refugee Number</label> 
                        <input type="text" className={`form-control myinput ${checkresumeform.refugee_No ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default" 
                        onChange={(event) => setResumeFormData({...resumeFormData, refugee_No: event.target.value})}
                        
                        />
                        { checkresumeform.refugee_No ?  <p className="error-message">Refugee Number Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Phone Number</label>
                        <PhoneInput
                            country={'ug'}
                            inputClass={`phone-input ${checkresumeform.phone_number ? 'error': ''}`}
                            onBlur={(event) => telephoneCheck(event.target.value)}
                            value={resumeFormData.phone_number}
                            onChange={(event) => setResumeFormData({...resumeFormData, phone_number: '+'+event})}
                            />
                    </div>
                    

                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Security Question</label>
                        <select className={`custom-select form-control myinput ${checkresumeform.security_question ? 'error': ''} `} id="inputGroupSelect01"
                        onChange={(event) => setResumeFormData({...resumeFormData, security_question: event.target.value})}
                        >
                            
                            <option value="" selected>-------</option>
                            {
                                security.map((option, index) => (
                                    <option key={option.id} value={option.id}>{option.text}</option>
                                ))
                            }
                        </select>
                        {checkresumeform.security_question ? <p className="error-message">Security Question Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Security Answer</label>
                        <input type="text" className={`form-control myinput ${checkresumeform.security_answer ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        onChange={(event) => setResumeFormData({...resumeFormData, security_answer: event.target.value})}
                        />
                        { checkresumeform.security_answer ? <p className="error-message">Security Answer Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <ReCAPTCHA
                            sitekey="{process.env.GOOGLE_RECAPTCHA_SITE_KEY}"
                        />
                    </div>
                </Col>
                
                    <Button 
                        className="buttontextsize next-btn  btn-grad-secondary resume-btn my-4" 
                        variant="" size="lg"
                        onClick={useResumeApplication}
                        >Resume Application</Button>
               
            </Row>
        </Container>
    </div>
    </div>);
}

export default ResumeApplication;