import React, {useState, useEffect, useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
import constants from '../../constants/constants';
import axios from 'axios';
import {GlobalStateContext} from "../../context/index";
import { GetCounties, GetSubCounties, GetParish, GetVillages } from "../../services/Services";

const ResidentialForm = ({formData,setFormData, setResidentialform, residentialform}) => {

    const {countries, setCountries, 
        districts, setDistricts, counties, 
        setCounties, subcounties, setSubcounties, 
        parishes, setParish, villages, setVillages, settlements, uganda, NameToID, NameToIDAsync} = useContext(GlobalStateContext)


    useEffect(()=>{
        setResidentialform({})
    },[])
    
    useEffect(() => {
        axios.get(constants.url+"/common/countries/")
        .then(res => {
            setCountries(res.data)
        })
        .catch(err => {
            console.error(err)
        })

    }, [])

    const [filteredcounties, setFilteredcounties] = useState([]);
    const [filteredsubcounties, setFilteredsubcounties] = useState([]);
    const [filteredparish, setFilteredparish] = useState([]);
    const [filteredvillage, setFilteredvillage] = useState([]);

    useEffect(()=>{

        const fetchResumeData = async () => {
            if (formData.district) {
                var districtid = await NameToIDAsync(formData.district, districts)
                var c = await GetCounties(districtid)
                setFilteredcounties(c)
                if (formData.county) {
                    var countyid = await NameToIDAsync(formData.county, c)
                    var s = await GetSubCounties(countyid)
                    setFilteredsubcounties(s)
                    if (formData.sub_county) {
                        
                        var subcountyid = await NameToIDAsync(formData.sub_county, s)
                        console.log(subcountyid)
                        var p = await GetParish(subcountyid)
                        setFilteredparish(p)
                        console.log(4)
                        if (formData.parish) {
                            var parishid = await NameToIDAsync(formData.parish, p)
                            // console.log(parishid)
                            var v = await GetVillages(parishid)
                            // console.log(v)
                            setFilteredvillage(v)
                            // console.log(5)
                        }
                    }
                }
            }
            
        }

        fetchResumeData()
    }, [])

    // useEffect(()=>{
    //     const fetchData = async () => {
    //         if (formData.district) {
    //             var c = await GetCounties(formData.district)
    //             setFilteredcounties(c)
    //         }

    //         if (formData.county) {
    //             var s = await GetSubCounties(formData.county)
    //             setFilteredsubcounties(s)
    //         }
    //         if (formData.sub_county) {
    //             var p = await GetParish(formData.sub_county)
    //             setFilteredparish(p)
    //         }
    //         if (formData.parish) {
    //             var v = await GetVillages(formData.parish)
    //             setFilteredvillage(v)
    //         }
    //     }

    //     fetchData()
        
    // },[])
    

    const SelectDistict = async (name) => {
        setFormData({...formData, district: name})
        var c = await GetCounties(name)
        setFilteredcounties(c)
    }

    const SelectCounty = async (name) => {
        setFormData({...formData, county: name})
        var s = await GetSubCounties(name)
        console.log(s)
        setFilteredsubcounties(s)
    }

    const SelectSubCounty = async (name) => {
        setFormData({...formData, sub_county: name})
        var s = await GetParish(name)
        setFilteredparish(s)
    }

    const SelectParish = async (name) => {
        setFormData({...formData, parish: name})
        var p = await GetVillages(name)
        setFilteredvillage(p)
    }

    return <div className="form-container">
        <Container>
            <Row>
                <Col md={6}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Country of Residence</label>
                        <select className={`custom-select form-control myinput ${residentialform.country_of_residence ? 'error': ''}`} id="inputGroupSelect01"
                        value={formData.country_of_residence} 
                        onChange={(event) => setFormData({...formData, country_of_residence: event.target.value})}
                        >
                            <option value={uganda} selected>Uganda</option>
                        </select>
                        { residentialform.country_of_residence ? <p className="error-message">Country Of Residence Required</p> : null}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group required">
                        <label className="mylabel control-label">District</label>
                        <select className={`custom-select form-control myinput ${residentialform.district ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.district, districts)}
                        onChange={(event) => SelectDistict(event.target.value)}
                        >
                            <option selected>------</option>
                            {districts.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { residentialform.district ? <p className="error-message">District Required</p> : null}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group required">
                        <label className="mylabel control-label">County/Municipality</label>
                        <select className={`custom-select form-control myinput ${residentialform.county ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.county, filteredcounties)}
                        onChange={(event) => SelectCounty(event.target.value)}
                        >
                            <option selected>------</option>
                            {filteredcounties.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { residentialform.county ? <p className="error-message">County/Municipality Required</p> : null}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Sub-County</label>
                        <select className={`custom-select form-control myinput ${residentialform.sub_county ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.sub_county, filteredsubcounties)} 
                        onChange={(event) => SelectSubCounty(event.target.value)}>
                            <option selected>------</option>
                            {filteredsubcounties.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { residentialform.sub_county ? <p className="error-message">Sub-County Required</p> : null}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Parish/Ward</label>
                        <select className={`custom-select form-control myinput ${residentialform.parish ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.parish, filteredparish)} 
                        onChange={(event) => SelectParish(event.target.value)}>
                            <option selected>------</option>
                            {filteredparish.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                        { residentialform.parish ? <p className="error-message">Parish/Ward Required</p> : null}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Village LC 1/Zone</label>
                        <select className={`custom-select form-control myinput ${residentialform.village ? 'error': ''}`} id="inputGroupSelect01"
                            value={NameToID(formData.village, filteredvillage)} 
                            onChange={(event) => setFormData({...formData, village: event.target.value})}>
                            <option selected>------</option>
                            {filteredvillage.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group">
                        <label className="mylabel control-label">Plot Number</label>
                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.plot_number} 
                        onChange={(event) => setFormData({...formData, plot_number: event.target.value})}/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="form-group">
                        <label className="mylabel control-label">Address out of Uganda</label>
                        <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.out_of_uganda_address} 
                        onChange={(event) => setFormData({...formData, out_of_uganda_address: event.target.value})}/>
                    </div>
                </Col>

            </Row>
        </Container>
    </div>;
}

export default ResidentialForm;