import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/Global.css';
import '../../styles/CtdHome.css';
import HomeMenu from '../../components/HomeMenu';
import HomeButton from '../../components/HomeButton';

const Ctdhowto = () => {
    return (
        <>
            <div className="home-container">
                <HomeMenu/>
                <Container>
                    <Row className="justify-content-md-center">

                        <Col md={12}>

                            <div className="">
                                <p className="innertext">PROCEDURE FOR APPLYING FOR A CONVENTIONAL TRAVEL DOCUMENT</p>
                                <div className="buttongroup">
                                    <div className='howtogroup'>
                                        <div className="row my-auto">
                                            <div className="col-12">
                                                <p className="text-white howtotext">Please follow the following steps to make an online conventional travel document application:</p>
                                            </div>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 1:</span> Navigate to the passport portal.</p>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 2:</span> Click the "Other Travel Documents” link followed by “Conventional Travel Document”</p>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 3:</span> Click the "Start New Application” link to start a new application.</p>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 4:</span> Accept the terms and conditions displayed in the Disclaimer. </p>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 5:</span> Answer simple security questions.</p>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 6:</span> Complete the Conventional Travel Document application form. </p>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 7:</span> Print the Passport Application Payment Advice Form.</p>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 8:</span> Make payments using the selected payment method. </p>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 9:</span> Navigate to "Check Application Status" on the Conventional Travel Document home page to view the application status including your scheduled date of appointment.</p>
                                            <p className="text-white howtotext"> <span className='howtobold'>Step 10:</span> Report to the selected issuing center with the payment advice form and the appropriate application requirements displayed on the website for enrolment.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
                
            </div>
            
            
        </>
    );
}


export default Ctdhowto;