import { toast } from 'react-toastify';
import axios from 'axios';
import constants from '../constants/constants';

export function validateEmail(email) {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result===true) return true
    else return false
}

function ToastClose(e){

    if (e.status == "removed"){
        window.location.href = "/ctd";
    }
}

export function popup(message) {
    toast(message, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
    })
    toast.onChange(e => console.log(e))
}

// add on close
export function popupctdformerror(message) {
    toast(message,
        {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
            
    })

    // Handle on close
    toast.onChange(e => ToastClose(e))
}
export const StringToDateConverter = (date) => {

    // console.log(date instanceof Date)


    if (date instanceof Date) {
        return date
    }

    else {

        if ( date == "Invalid Date" || date == null || date == undefined || date == "") {
        
            return null
        }
        
        var data = date
        var dateParts = data.split("-");
        var x = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])

        return x
    }
    
}

export const telephoneChecker = (phone) => {
    const regExp = /^\+?([0-9]{12})$/;
    const result = regExp.test(phone.replace(/\s/g, ''));
    if (result===true){
        return false
    }
    else {
        return true
    }
}

export const emailChecker = (email) => {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result===true) {
        return false
    }
    else {
        return true
    }
}

// Form Filters
export const  GetCounties = async (name) => {
    var res = await axios.get(constants.url+"/common/counties/?district="+name)
    if (res.status == 200){
        return res.data
    }
    else {
        return []
    }
}

export const GetSubCounties = async (name) => {
    var res = await axios.get(constants.url+"/common/sub-counties/?county="+name)
    if (res.status == 200){
        return res.data
    }
    else {
        return []
    }
}

export const GetParish = async (name) => {
    var res = await axios.get(constants.url+"/common/parishes/?subcounty="+name)
    if (res.status == 200){
        return res.data
    }
    else {
        return []
    }
}

export const GetVillages = async (name) => {
    var res = await axios.get(constants.url+"/common/villages/?parish="+name)
    if (res.status == 200){
        return res.data
    }
    else {
        return []
    }
}
