import react, {useState, useEffect, useContext} from 'react';
import '../styles/Global.css';
import '../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import dcic from '../assets/img/dcic.png';
import coatofarms from '../assets/img/coatofarms.png';
import Button from 'react-bootstrap/Button'; 
import RefugeeForm from '../components/form/RefugeeForm';
import ApplicantForm from '../components/form/ApplicantForm';
import ApplicationsForm from '../components/form/ApplicationsForm';
import ResidentialForm from '../components/form/ResidentialForm';
import SpouseForm from '../components/form/SpouseForm';
import ParentsForm from '../components/form/ParentForm';
import DependantForm from '../components/form/DependantForm';
import NOKForm from '../components/form/NOKForm';
import AttachmentsForm from '../components/form/AttachmentsForm';
import PaymentForm from '../components/form/PaymentForm';
import ReviewForm from '../components/form/ReviewForm';
import MothersForm from '../components/form/MothersForm';
import constants from '../constants/constants';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import RefugeeSlider from '../components/applicationslider/RefugeeSlider';
import ApplicantSlider from '../components/applicationslider/ApplicantSlider';
import ApplicationsSlider from '../components/applicationslider/ApplicationsSlider';
import ResidentialSlider from '../components/applicationslider/ResidentialSlider';
import SpouseSlider from '../components/applicationslider/SpouseSlider';
import ParentsSlider from '../components/applicationslider/ParentsSlider';
import MothersSlider from '../components/applicationslider/MothersSlider';
import DependantSlider from '../components/applicationslider/DependantSlider';
import NOKSlider from '../components/applicationslider/NOKSlider';
import AttachmentsSlider from '../components/applicationslider/AttachmentsSlider';
import PaymentSlider from '../components/applicationslider/PaymentSlider';
import { FiEdit, FiCopy,FiSquare  } from "react-icons/fi";
import ConfirmationModal from '../components/ConfirmationModal';
import {GlobalStateContext} from "../context/index";
// import ConfirmationModal from '../components/ConfirmationModal';
import Preloader from '../components/Preloader';


import { ToastContainer } from 'react-toastify';
import { popup } from '../services/Services';

const ApplicationForm = () => {

    const {
        recaptcha, setRecaptcha, formData, setFormData, submitApplicationform, saveApplicationform,
        applicationsubmit_click, setApplicationSubmitClick, preloader, setPreloader
    } = useContext(GlobalStateContext)

    

    const [page, setPage] = useState(0);
    const [review, setReview] = useState(0);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showpreview, setShowPreview] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShow2 = () => setShow2(true);
    const handlePreviewClose = () => setShowPreview(false);
    const [sliderpage, setSliderPage] = useState(0);
    const [showPreviewBtn, setShowPreviewBtn] = useState(false);
    const [resumeform, setResumeform] = useState({});

    useEffect(() => {

        if (sessionStorage.getItem("state") == "submitted"){
            sessionStorage.clear();
        }

        if (sessionStorage.getItem("refugee_No") == null){
            console.log("");
        }
        else {
            console.log("")
            var formdata = {}
            for (var key in formData) {
                formdata[key] = sessionStorage.getItem(key);
                // console.log(formdata)
                if (key === "dependents"){
                    formdata[key] = JSON.parse(sessionStorage.getItem(key))
                }
            }
            setFormData(formdata);
            sessionStorage.clear();
            sessionStorage.setItem("resume", true);
            sessionStorage.setItem("resume_id", formdata.id)
        }
    },[]);
    
    const NextSlider = (currPage) => {
        setSliderPage((currPage) => currPage+1);
    }
    const PreviousSlider = (currPage) => {
        setSliderPage((currPage) => currPage-1);
    }

    const ReviewPages = () => {
        setPage(() => 0);
        setReview(() => 10);

    }
    const SubmitForm = () => { 

        var form_data = new FormData();
        for (var key in formData) {
            form_data.append(key, formData[key]);
        }
        axios.post(constants.url+"/applications/applications/", form_data)
        .then(res => {
            console.log(res.data)
        })
        .catch(err => {
            console.error(err)
        })
    }
    const SliderPageDisplay = () => {
        if (sliderpage === 0){
            return <RefugeeSlider formData={formData} setFormData={setFormData}/>

        } 
        else if (sliderpage === 1){
            return <ApplicantSlider formData={formData} setFormData={setFormData}/>

        } 
        else if (sliderpage === 2){

            return <ApplicationsSlider formData={formData} setFormData={setFormData}/>

        } 
        else if (sliderpage === 3){

            return <ResidentialSlider formData={formData} setFormData={setFormData}/>

        } 
        else if (sliderpage === 4){

            return <SpouseSlider formData={formData} setFormData={setFormData}/>

        } 
        else if (sliderpage === 5){

            return <ParentsSlider formData={formData} setFormData={setFormData}/>

        } 

        else if (sliderpage === 6){

            return <MothersSlider formData={formData} setFormData={setFormData}/>

        }

        else if (sliderpage === 7){

            return <DependantSlider formData={formData} setFormData={setFormData}/>

        } 
        else if (sliderpage === 8){

            return <NOKSlider formData={formData} setFormData={setFormData}/>

        } 
        else if (sliderpage === 9){

            return <AttachmentsSlider formData={formData} setFormData={setFormData}/>

        } 
        else if (sliderpage === 10){

            return <PaymentSlider formData={formData} setFormData={setFormData}/>

        }
        else {
            console.log("Form Step Error Error")
            console.log(page)
        }


    }

    const [refugeeform, setRefugeeform] = useState({});
    const [applicantform, setApplicantform] = useState({});
    const [applicationsform, setApplicationsform] = useState({});
    const [residentialform, setResidentialform] = useState({});
    const [fatherform, setFatherform] = useState({});
    const [motherform, setmotherform] = useState({});
    const [nokform, setNokform] = useState({});
    const [attachmentform, setattachmentform] = useState({});
    const [paymentform, setpaymentform] = useState({});
    const [spouseform, setSpouseform] = useState({});
    

    const PageDisplay = () => {
        if (page === 0){
            return <RefugeeForm formData={formData} setFormData={setFormData} refugeeform={refugeeform} setRefugeeform={setRefugeeform}/>

        } 
        else if (page === 1){
            return <ApplicantForm formData={formData} setFormData={setFormData} applicantform={applicantform} setApplicantform={setApplicantform}/>

        } 
        else if (page === 2){

            return <ApplicationsForm formData={formData} setFormData={setFormData} applicationsform={applicationsform} setApplicationsform={setApplicationsform}/>

        } 
        else if (page === 3){

            return <ResidentialForm formData={formData} setFormData={setFormData} residentialform={residentialform} setResidentialform={setResidentialform}/>

        } 
        else if (page === 4){

            if (formData.marital_status === "S" || formData.marital_status === "D" || formData.marital_status === "W"){

                setPage((currPage) => currPage+1);

            }
            else {
                return <SpouseForm formData={formData} setFormData={setFormData} residentialform={residentialform} setSpouseform={setSpouseform}/>
            }

        } 
        else if (page === 5){

            return <ParentsForm fatherform={fatherform} setFatherform={setFatherform} formData={formData} setFormData={setFormData}/>

        } 

        else if (page === 6){

            return <MothersForm motherform={motherform} setmotherform={setmotherform} formData={formData} setFormData={setFormData} />

        }

        else if (page === 7){

            return <DependantForm formData={formData} setFormData={setFormData}/>

        } 
        else if (page === 8){

            return <NOKForm nokform={nokform} setNokform={setNokform} formData={formData} setFormData={setFormData}/>

        } 
        else if (page === 9){

            // return <AttachmentsForm attachmentform={attachmentform} setattachmentform={setattachmentform} formData={formData} setFormData={setFormData}/>
            return <PaymentForm paymentform={paymentform} setpaymentform={setpaymentform} formData={formData} setFormData={setFormData}/>


        } 
        // else if (page === 10){

        //     return <PaymentForm paymentform={paymentform} setpaymentform={setpaymentform} formData={formData} setFormData={setFormData}/>

        // } 
        else if (page === 11){

            return <ReviewForm formData={formData} setFormData={setFormData}/>

        } 
        else {
            console.log("Form Step Error Error")
            console.log(page)
        }


    }
    const StepOneDisplay = (p) => {
        if (p == 0) return true
        else return false
    }
    const StepTwoDisplay = (p) => {
        if (p == 1) return true
        else return false
    }
    const StepThreeDisplay = (p) => {
        if (p == 2) return true
        else return false
    }
    const StepFourDisplay = (p) => {
        if (p == 3) return true
        else return false
    }
    const StepFiveDisplay = (p) => {
        if (p == 4) return true
        else return false
    }
    const StepSixDisplay = (p) => {
        if (p == 5) return true
        else return false
    }
    const StepMotherDisplay = (p) => {
        if (p == 6) return true
        else return false
    }
    const StepSevenDisplay = (p) => {
        if (p == 7) return true
        else return false
    }
    const StepEightDisplay = (p) => {
        if (p == 8) return true
        else return false
    }
    const StepNineDisplay = (p) => {
        if (p == 9) return true
        else return false
    }
 
    const PreviousPage = (currPage) => {
        // setPage((currPage) => currPage-1);
        if (formData.marital_status === "Single" && page === 5){
            console.log(2)
            setPage((currPage) => currPage-2);

        }
        else {
            console.log(3)
            setPage((currPage) => currPage-1);
        }
        
    }

    const handlePreviewShow = () => {
        setShowPreviewBtn(true)
        if (formData.paymentmode===""){
            var errordict = {}
            if (formData.paymentmode === "") errordict["paymentmode"] = true
            if (formData.paymentbank === "") errordict["paymentbank"] = true
            console.log(errordict)
            setRefugeeform(errordict)
        }

        else {
            setShowPreview(true);
        }
    }


    // const NextPage = (currPage) => {
    //     setPage((currPage) => currPage+1);
    // }
    
    const NextPage = (currPage) => {
        switch (currPage){
            case 0:
                if (recaptcha === false || formData.refugee_No === "" || formData.phone_number==="" || formData.security_question==="" || formData.security_answer==="" || formData.email_address===""){
                    var errordict = {}
                    
                    if (formData.refugee_No === "") errordict["refugeeid"] = true
                    if (formData.phone_number === "") errordict["phonenumber"] = true
                    if (formData.security_question === "") errordict["securityquestion"] = true
                    if (formData.security_answer === "") errordict["securityanswer"] = true
                    if (formData.email_address === "") errordict["email_address"] = true
                    setRecaptcha(true)
                    setRefugeeform(errordict)
                }
                else {
                    setPage((currPage) => currPage+1);
                }
                break;

            case 1:
                if ( formData.primary_language==="" || formData.surname==="" || formData.given_name==="" || formData.date_of_birth==="" || formData.nationality==="" || formData.marital_status==="" || formData.occupation ==="" || formData.place_of_birth==="" || formData.sex===""){

                    var errordict = {}
                    if (formData.surname === "") errordict["surname"] = true
                    if (formData.given_name === "") errordict["given_name"] = true
                    if (formData.date_of_birth === "") errordict["date_of_birth"] = true
                    if (formData.nationality === "") errordict["nationality"] = true
                    if (formData.marital_status === "") errordict["marital_status"] = true
                    if (formData.sex === "") errordict["sex"] = true
                    if (formData.occupation === "") errordict["occupation"] = true
                    if (formData.place_of_birth === "") errordict["place_of_birth"] = true
                    if (formData.primary_language === "") errordict["primary_language"] = true
                    setApplicantform(errordict)

                }
                else {
                    setPage((currPage) => currPage+1);
                }
                break;

            case 2:
                if (formData.legal_status === "" || formData.application_type === "" || formData.place_of_submission === "" || formData.place_of_issue === ""){

                    var errordict = {}

                    if (formData.legal_status === "") errordict["legal_status"] = true
                    if (formData.application_type === "") errordict["application_type"] = true
                    if (formData.place_of_submission === "") errordict["place_of_submission"] = true
                    if (formData.place_of_issue === "") errordict["place_of_issue"] = true
                    // if (formData.place_of_submission === "") errordict["place_of_submission"] = true

                    setApplicationsform(errordict)
                }
                else {
                    setPage((currPage) => currPage+1);
                }
                break;

            case 3:
                if ( formData.district==="" || formData.county==="" || formData.sub_county==="" || formData.parish==="" || formData.village===""){
                    var errordict = {}

                    // if (formData.country_of_residence === "") errordict["country_of_residence"] = true
                    if (formData.district === "") errordict["district"] = true
                    if (formData.county === "") errordict["county"] = true
                    if (formData.parish === "") errordict["parish"] = true
                    if (formData.village === "") errordict["village"] = true
                    if (formData.sub_county === "") errordict["sub_county"] = true

                    setResidentialform(errordict)

                }

                else {
                    setPage((currPage) => currPage+1);
                }
                break;

            case 4:
                if (formData.marital_status === "Married"){
                    
                    if (formData.spouse_surname===""|| formData.spouse_given_name==="" || formData.date_of_marriage==="" || formData.spouse_nationality==="" || formData.spouse_primary_telephone_number===""){
                        var errordict = {}
                        if (formData.spouse_surname === "") errordict["spouse_surname"] = true
                        if (formData.spouse_given_name === "") errordict["spouse_given_name"] = true
                        if (formData.date_of_marriage === "") errordict["date_of_marriage"] = true
                        if (formData.spouse_nationality === "") errordict["spouse_nationality"] = true
                        if (formData.spouse_primary_telephone_number === "") errordict["spouse_primary_telephone_number"] = true
                        
                        setSpouseform(errordict)
                    }
                    else {
                        setPage((currPage) => currPage+1);
                    }

                    
                } else {
                    setPage((currPage) => currPage+1);
                }
                break;

            case 5:
                if (formData.father_surname===""|| formData.father_given_name==="" || formData.father_status==="" || formData.father_nationality==="" || formData.father_birth_country===""){

                    var errordict = {}
                    if (formData.father_surname === "") errordict["father_surname"] = true
                    if (formData.father_given_name === "") errordict["father_given_name"] = true
                    if (formData.father_status === "") errordict["father_status"] = true
                    if (formData.father_nationality === "") errordict["father_nationality"] = true
                    if (formData.father_birth_country === "") errordict["father_birth_country"] = true
                    setFatherform(errordict)
                }
                else {
                    setPage((currPage) => currPage+1);
                }
                break;

            case 6:
                if (formData.mother_surname===""|| formData.mother_given_name==="" || formData.mother_status==="" || formData.mother_nationality==="" || formData.father_birth_country===""){

                    var errordict = {}
                    if (formData.mother_surname === "") errordict["mother_surname"] = true
                    if (formData.mother_given_name === "") errordict["mother_given_name"] = true
                    if (formData.mother_status === "") errordict["mother_status"] = true
                    if (formData.mother_nationality === "") errordict["mother_nationality"] = true
                    if (formData.mother_birth_country === "") errordict["mother_birth_country"] = true
                    setmotherform(errordict)
                }
                else {
                    setPage((currPage) => currPage+1);
                }
                break;

            case 7:
                setPage((currPage) => currPage+1);
                break;

            case 8:

                if (formData.next_of_kin_surname=="" || formData.next_of_kin_given_name==="" || formData.next_of_kin_relationship==="" || formData.next_of_kin_nationality==="" || formData.next_of_kin_primary_telephone_number===""){
                    var errordict = {}
                    if (formData.next_of_kin_surname === "") errordict["next_of_kin_surname"] = true
                    if (formData.next_of_kin_given_name === "") errordict["next_of_kin_given_name"] = true
                    if (formData.next_of_kin_country === "") errordict["next_of_kin_country"] = true
                    // if (formData.next_of_kin_district === "") errordict["next_of_kin_district"] = true
                    // if (formData.next_of_kin_county === "") errordict["next_of_kin_county"] = true
                    // if (formData.next_of_kin_sub_county === "") errordict["next_of_kin_sub_county"] = true
                    if (formData.next_of_kin_relationship === "") errordict["next_of_kin_relationship"] = true
                    if (formData.next_of_kin_nationality === "") errordict["next_of_kin_nationality"] = true
                    if (formData.next_of_kin_id_number === "") errordict["next_of_kin_id_number"] = true
                    if (formData.next_of_kin_primary_telephone_number === "") errordict["next_of_kin_primary_telephone_number"] = true

                    setNokform(errordict)
                    
                }

                else {
                    setPage((currPage) => currPage+1);
                }
                break;

            case 9:

                if (formData.opm_authorization_letter===""|| formData.refugee_id_file==="" || formData.receipt==="" || formData.refugee_family_attestation===""){

                    var errordict = {}
                    if (formData.opm_authorization_letter === "") errordict["opm_authorization_letter"] = true
                    if (formData.refugee_id_file === "") errordict["refugee_id_file"] = true
                    if (formData.receipt === "") errordict["receipt"] = true
                    if (formData.refugee_family_attestation === "") errordict["refugee_family_attestation"] = true
                    setattachmentform(errordict)
                    
                }
                else {
                    setPage((currPage) => currPage+1);
                }
                break;

            

        }
    
    }

    const [fullscreen, setFullscreen] = useState(false);

    // T & C
    const [tc, setTC] = useState(true);
    const handletcopen = () => setTC(true);
    const handletcclose = () => setTC(false);
    const [tcdata, setTCData] = useState({});
    useEffect(()=> {
        axios.get(constants.url+"/common/terms-and-conditions/")
        .then(res => {
            setTCData(res.data[0])
        })
        .catch(err => {
            console.error(err)
        })
    },[])

    return (
        <>
        
        <div className="application-container">

            <ToastContainer/>

            <Container className="applicationform">
                <Row>

                    <Col md={2} className="step-container mx-0 px-0">
                        <div className={`steps ${StepOneDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}` }>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            {/* <p></p> */}
                            START
                        </div>
                        <div className={`steps ${StepTwoDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            THE APPLICANT</div>
                        <div className={`steps ${StepThreeDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            THE APPLICATION</div>
                        <div className={`steps ${StepFourDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            RESIDENTIAL ADDRESS</div>
                        <div className={`steps ${StepFiveDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            SPOUSE</div>
                        <div className={`steps ${StepSixDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            FATHER'S DETAILS</div>
                        <div className={`steps ${StepMotherDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            MOTHER'S DETAILS</div>
                        <div className={`steps ${StepSevenDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            DEPENDANTS</div>
                        <div className={`steps ${StepEightDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            NEXT OF KIN</div>
                        {/* <div className={`steps ${StepNineDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            ATTACHMENTS</div> */}
                        <div className={`steps ${StepNineDisplay(page) ? 'activestep': ''} ${review === 10 ? 'activestepreview': ''}`}>
                            {(() => {
                                if (review === 10){
                                    return (
                                        <>
                                            REVIEW-
                                        </>
                                    )
                                }
                            })()}
                            PAYMENTS</div>
                    </Col>

                    <Col md={10}>
                        <div className="">
                        {PageDisplay()}
                        </div>
                        <div>

                            <Row>
                                <Col>
                                    <Button 
                                        disabled={page == 0}
                                        hidden={page === 0}
                                        onClick={() => {PreviousPage(page)}} 
                                        className="control-btn buttontextsize prev-btn btn-grad text-white" 
                                        variant="" size="lg">Previous</Button>
                                </Col>

                                <Col>
                                    {

                                        (()=>{
                                            if (page === 9 && review === 0){
                                                return (
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <Button 
                                                        onClick={handlePreviewShow}
                                                        className="control-btn buttontextsize next-btn btn-grad-secondary text-white" 
                                                        variant="" size="lg">Review</Button>
                                                    </div>
                                                    
                                                )
                                            }

                                            else if (page === 10 && review === 10){
                                                return (

                                                    <Button 
                                                        onClick={handleShow}
                                                        className="control-btn buttontextsize next-btn text-white" 
                                                        variant="success" size="lg">Submit</Button>
                                                )
                                            }

                                            else {

                                            
                                                return (
                                                    <div className="d-flex align-items-center justify-content-end">
                                                    
                                                    <Button 
                                                        onClick={handlePreviewShow}
                                                        hidden={showPreviewBtn === false || page === 10}
                                                        className="control-btn buttontextsize next-btn btn-grad-secondary text-white" 
                                                        variant="" size="lg">Review</Button>
                                                   
                                                    <Button 
                                                        onClick={handleShow2}
                                                        hidden={page === 0 || showPreviewBtn === true}
                                                        className="control-btn buttontextsize next-btn save-btn" 
                                                        variant="secondary" size="lg">Save & Exit</Button>
                                                    <Button 
                                                        disabled= {page == 10}
                                                        hidden={showPreviewBtn === true}
                                                        onClick={() => {NextPage(page)}} 
                                                        className="control-btn buttontextsize next-btn text-white" 
                                                        variant="success" size="lg">Next</Button>
                                                    </div>
                                                    
                                                    
                                                )
                                            }

                                            
                                        })()
                                    }
                                    
                                </Col>
                            </Row> 

                        </div>
                         

                    </Col>
                    
                </Row>
                
            </Container>

            <Modal
                show={tc}
                onHide={handletcclose}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
                contentClassName="model-content-design"
            >
                <Modal.Header>
                    <Modal.Title>Terms & Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {tcdata.text} */}
                    {
                        (()=>{
                            try {
                                return (
                                    <div
                                        className="text-display"
                                        dangerouslySetInnerHTML={{ __html: tcdata.text }}
                                 ></div>
                                )
                            }
                            catch(e){
                                return <></>
                            }
                        })()
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="control-btn buttontextsize next-btn btn-grad-primary text-white" href="/ctd">
                        Reject
                    </Button>
                    <Button className="control-btn buttontextsize next-btn btn-grad-secondary text-white" onClick={handletcclose}>Accept</Button>
                </Modal.Footer>
            </Modal>
            <Modal centered size="xl" fullscreen={fullscreen} show={showpreview} onHide={handlePreviewClose} contentClassName="model-content-design" aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header className="model-header-content" closeButton>
                    
                {/* justify-content-end */}
                    <div className="d-flex align-items-center d-flex justify-content-between w-100 px-4">
                        <Modal.Title className="model-title-style pl-5" >REVIEW APPLICATION</Modal.Title>
                        <btn
                            onClick={() => {
                                setFullscreen(!fullscreen);
                            }}
                            className="close-edit" 
                            size="lg">
                                {(() => {
                                    if (fullscreen === true){
                                        return <FiCopy size={18} className="close-icon"/>;
                                    }
                                    else {
                                        return <FiSquare size={18} className="close-icon"/>;
                                    }
                                })()}
                                
                                
                        </btn>
                    </div>
                    
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} xs={12}>
                            <p className="sliderheader">Refugee Number</p>
                            <RefugeeSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(0);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit Refugee Number
                            </btn>
                            <hr/>
                            <p className="sliderheader">The Applicant</p>
                            <ApplicantSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(1);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit The Applicant
                            </btn>
                            <hr/>
                            <p className="sliderheader">The Application</p>
                            <ApplicationsSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(2);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit The Application
                            </btn>
                            <hr/>
                            <p className="sliderheader">Residential Address</p>
                            <ResidentialSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(3);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit Residential Address
                            </btn>
                            <hr/>
                            <p className="sliderheader">Spouse</p>
                            <SpouseSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(4);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit Spouse
                            </btn>
                            <hr/>
                            <p className="sliderheader">Father's Details</p>
                            <ParentsSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(5);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit Father's Details
                            </btn>
                            <hr/>
                            <p className="sliderheader">Mother's Details</p>
                            <MothersSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(6);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit Mother's Details
                            </btn>
                            <hr/>
                            <p className="sliderheader">Dependant</p>
                            <DependantSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(7);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit Dependant
                            </btn>
                            <hr/>
                            <p className="sliderheader">Next Of Kin</p>
                            <NOKSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(8);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit Next Of Kin
                            </btn>
                            <hr/>
                            <p className="sliderheader">Payment</p>
                            <PaymentSlider formData={formData} setFormData={setFormData}/>
                            <btn
                                onClick={() => {
                                    setPage(10);
                                    handlePreviewClose();
                                }}
                                className="slider-edit" 
                                size="lg">
                                    <FiEdit size={20} className="slider-icon"/> 
                                    Edit Payment
                            </btn>
                            
                        </Col>
                        
                    </Row>
                    
                </Modal.Body>
            </Modal>
            {/* applicationsubmit_click, setApplicationSubmitClick
             */}
            <ConfirmationModal show={show} setShow={setShow} myclick={submitApplicationform}/>
            
            <ConfirmationModal show={show2} setShow={setShow2} myclick={saveApplicationform}/>

           
        </div>
        {
            preloader ? <Preloader/> : null
        }
        </>
        
    );
}

export default ApplicationForm;