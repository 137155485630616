import React from 'react';
import '../styles/Preloader.css';

const Preloader = () => {
    return (
    <div className='ripple-body'>
        <div className='ripple'></div>
    </div>
    );
}


export default Preloader;