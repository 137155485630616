import React, {useState} from 'react';
import '../../styles/Global.css';
import '../../styles/Portal.css';
import Sidebar from '../../components/Sidebar';
import PortalNav from "../../components/PortalNav";
import PaymentStatus from "../../components/portal/PaymentStatus";
import Queried from "../../components/portal/Queried";
import Rejected from "../../components/portal/Rejected";
import Scheduled from "../../components/portal/Scheduled";


const Portal = () => {
    const [isopen, setIsOpen] = useState(true);
    const [page, setPage] = useState(3);
    const pageDisplay = () => {
        if (page == 1){
            return <PaymentStatus/>;
        }
        else if (page == 2){
            return <Queried />;
        }
        else if (page == 3){
            return <Scheduled />;
        }
        else if (page == 4){
            
            return <Rejected/>;
        }

        else {
            return <>Error</>;
        }
    }


    return (
        <>
        <PortalNav page={page} setpaymentstatus={() => {setPage(1)}} setqueried={() => {setPage(2)}} setscheduled={() => {setPage(3)}} setrejected={() => {setPage(4)}}/>
        <div className="portalcontainer horizontal-flex">
            <Sidebar isopen={isopen} toggle={() => {setIsOpen(isopen => !isopen)}} page={page}/>
            <div className="vertical-flex m-t">
                {pageDisplay()}
            </div>
        </div>
        </>
    );
}


export default Portal;