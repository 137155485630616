import React, {useContext, useEffect, useState} from 'react';
import "../styles/Global.css";
import "../styles/Sidebar.css";
import "../styles/Portal.css";
import { FaAlignRight, FaAlignLeft } from 'react-icons/fa';
import { AiOutlineCheckSquare, AiOutlineCloseSquare, AiOutlineDown, AiOutlineDownSquare, AiFillSchedule, AiOutlineSchedule   } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import {GlobalStateContext} from "../context/index";
import jwt_decode from "jwt-decode";

const Sidebar = ({isopen, toggle, page}) => {

    const {paymentpage, setPaymentPage, ValidateApplications, setSchedulePage, schedulePage} = useContext(GlobalStateContext);
    const [name, setName] = useState("Test User");

    const logout = () => {
        sessionStorage.removeItem("access")
        sessionStorage.removeItem("refresh")
        window.location.href = "/backoffice";
    }

    useEffect(() => {
        var token = sessionStorage.getItem("access");
        var decoded = jwt_decode(token);
        var n = decoded.first_name + " " + decoded.last_name
        setName(n)
    },[])

    return (
    <div className={`sidebarcontainer ${isopen ? "sidebaropen": "sidebarclosed"}`}>
        
        
        <div className="main-container">
            <img className={`${isopen ? "sidebar-image": "sidebar-image-toggle"}`} src={require("../assets/img/avatar.png")} id="file-ip-1-preview"/>
            <p className={`sidebartext mt ${isopen ? "" : "text-hide"}`}>{name}</p>
            <p className={`sidebarsubtitle textitalic ${isopen ? "" : "text-hide"}`}>Conventional Travel Document</p>
            <p className={`sidebarsubtitle textitalic ${isopen ? "" : "text-hide"}`}>Scheduler</p>

            {(() => {
                if (page === 1) {
                    return (
                        <div className={`sidebar-item-container ${isopen ? "" : "toggle-height"}`}>
                            <div className={`horizontal-flex sidebar-item-select ${paymentpage === 2 ? "sidebar-active" : ""}`} onClick={() => {setPaymentPage(2)}} >
                                <AiOutlineCheckSquare className={`sidebar-icons ${isopen ? "" : "sidebar-icons-toggle"}`} />
                                <p className = {` sidebar-item ${isopen ? "" : "text-hide"}`} >Paid Applications</p>
                            </div>
                            <div className={`horizontal-flex sidebar-item-select ${paymentpage === 1 ? "sidebar-active" : ""}`} onClick={() => {setPaymentPage(1)}}>
                                <AiOutlineCloseSquare className={`sidebar-icons ${isopen ? "" : "sidebar-icons-toggle"}`} />
                                <p className = {` sidebar-item ${isopen ? "" : "text-hide"}`}>Unpaid Applications</p>
                            </div>
                            
                            {/* {(() => {
                                if (paymentpage === 2){

                                    return (
                                        <div className="horizontal-flex sidebar-item-select">
                                            <AiOutlineDownSquare className={`sidebar-icons ${isopen ? "" : "sidebar-icons-toggle"}`}  onClick={ValidateApplications} />
                                            <p className = {` sidebar-item ${isopen ? "" : "text-hide"}`}  onClick={ValidateApplications} >Validate</p>
                                        </div>
                                    );

                                }
                            })()} */}
                            
                        </div>
                    );
                }

                if (page === 3){
                    return (
                        <div className={`sidebar-item-container ${isopen ? "" : "toggle-height"}`}>
                            <div className={`horizontal-flex sidebar-item-select ${schedulePage === 1 ? "sidebar-active" : ""}`} onClick={() => {setSchedulePage(1)}}>
                                <AiFillSchedule className={`sidebar-icons ${isopen ? "" : "sidebar-icons-toggle"}`} />
                                <p className = {` sidebar-item ${isopen ? "" : "text-hide"}`}>Unscheduled</p>
                            </div>
                            <div className={`horizontal-flex sidebar-item-select ${schedulePage === 2 ? "sidebar-active" : ""}`} onClick={() => {setSchedulePage(2)}} >
                                <AiOutlineSchedule className={`sidebar-icons ${isopen ? "" : "sidebar-icons-toggle"}`} />
                                <p className = {` sidebar-item ${isopen ? "" : "text-hide"}`} >Scheduled</p>
                            </div>
                        </div>
                    );
                }
            })()}

            {/* <div className={`sidebar-item-container ${isopen ? "" : "toggle-height"}`}> */}
                <div className={`horizontal-flex sidebar-item-select`} onClick={() => {logout()}} >
                    <FiLogOut className={`sidebar-icons ${isopen ? "" : "sidebar-icons-toggle"}`} />
                    <p className = {` sidebar-item ${isopen ? "" : "text-hide"}`} >Sign Out</p>
                </div>
            {/* </div> */}
            
        
        </div>

        <div className="toggle-container">
            {(() => {

                if (isopen == true){
                    return (
                        <FaAlignRight className="toggleicon" onClick={toggle}/>
                    );
                }
                else {
                    return (
                        <FaAlignLeft className="toggleicon" onClick={toggle}/>
                    );
                }

            })()}
            
        </div>
        
    </div>
    );
}

export default Sidebar;