import React, {useEffect, useContext, useState} from 'react';
import Card from '../Card';
import "../../styles/Portal.css";
import "../../styles/Global.css";
import Table from "../Table";
import {GlobalStateContext} from "../../context/index";
import constants from '../../constants/constants';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import QueryReview from './QueryReview';

const Queried = () => {

    const columns = [ 
        { Header: '#', accessor: 'id' }, 
        { Header: 'Surname', accessor: 'surname' }, 
        { Header: 'Given Name', accessor: 'given_name' },  
        { Header: 'Refugee No', accessor: 'refugee_No' },
        { Header: 'Application Type', accessor: 'application_type' },
        { Header: 'Nationality', accessor: 'nationality' },
        { Header: 'Phone Number', accessor: 'phone_number' },
        { Header: 'Place Of Submission', accessor: 'place_of_submission' },
        { Header: 'Sex', accessor: 'sex' },

    ]

    const data = [
        {
            "id": 13,
            "refugee_No": "1000001",
            "application_type": "Normal",
            "place_of_submission": "Kampala",
            "legal_status": null,
            "ctd_number": "",
            "place_of_issue": "Kampala",
            "expiry_date": null,
            "surname": "Omach",
            "given_name": "Aaron",
            "maiden_name": "",
            "other_names": "",
            "phone_number": "+256788237108",
            "sex": "Male",
            "date_of_birth": null,
            "place_of_birth": null,
            "marital_status": "",
            "occupation": null,
            "individual_number": "",
            "household_number": "",
            "email_address": "aaomach@gmail.com",
            "nationality": "South Sudanese",
            "signature": null,
            "previous_travel_document_number": "",
            "photo": null,
            "fingerprints": null,
            "address_in_uganda": true,
            "country_of_residence": null,
            "district": null,
            "county": null,
            "sub_county": null,
            "parish": null,
            "village": null,
            "plot_number": "",
            "out_of_uganda_address": "",
            "spouse_surname": "",
            "spouse_given_name": "",
            "spouse_maiden_name": null,
            "date_of_marriage": null,
            "place_of_marriage": "Kampala",
            "spouse_nationality": null,
            "spouse_primary_telephone_number": "",
            "spouse_other_telephone_number": "",
            "spouse_email_address": "",
            "father_status": "",
            "father_surname": "",
            "father_given_name": "",
            "father_birth_country": null,
            "father_place_of_birth": null,
            "father_residence_country": null,
            "father_district": "",
            "father_county": "",
            "father_sub_county": "",
            "father_parish": "",
            "father_plot_number": "",
            "father_tribe": "",
            "father_nationality": null,
            "father_primary_telephone_number": "",
            "father_other_telephone_number": "",
            "father_email_address": "",
            "mother_status": "",
            "mother_surname": "",
            "mother_given_name": "",
            "mother_birth_country": null,
            "mother_place_of_birth": "",
            "mother_residence_country": null,
            "mother_district": "",
            "mother_county": "",
            "mother_sub_county": "",
            "mother_parish": "",
            "mother_plot_number": "",
            "mother_tribe": "",
            "mother_nationality": null,
            "mother_primary_telephone_number": "",
            "mother_other_telephone_number": "",
            "mother_email_address": "",
            "next_of_kin_surname": null,
            "next_of_kin_given_name": null,
            "next_of_kin_district": null,
            "next_of_kin_county": null,
            "next_of_kin_plot_number": "",
            "next_of_kin_relationship": null,
            "next_of_kin_nationality": null,
            "next_of_kin_id_number": "",
            "next_of_kin_occupation": null,
            "next_of_kin_primary_telephone_number": "",
            "next_of_kin_other_telephone_number": "",
            "next_of_kin_email_address": "",
            "opm_authorization_letter": null,
            "refugee_id_card": null,
            "refugee_family_attestation": null,
            "security_question": 1,
            "security_answer": "yes"
        },
        
    ]

    const [showPreview, setShowPreview] = useState(false)

    const [selectedRow, setSelectedRow] = useState({})
    
    const ViewApplication = (data) => {
        setSelectedRow(data)
        setShowPreview(true)
    }

    const handlePreviewClose = () => {
        setShowPreview(false)
    }


    return (
        <div className="inner-portal">
            <Card >
                <div className="card-container">
                    <Table columns={columns} data={data} onclick={ViewApplication} title="Table of Mismatch Applications" />
                </div>
            </Card>
            <QueryReview showPreview={showPreview} handlePreviewClose={handlePreviewClose} data={selectedRow}/>
        </div>
    );
}


export default Queried;