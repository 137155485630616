import React, {useState} from 'react';
import '../styles/Global.css';
import '../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'; 

const ConfirmationModal = ({show, setShow, myclick}) => {
    
    // const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        // window.location.href = "/";
    }

    return <>
        <Modal show={show} onHide={handleClose} centered contentClassName="model-content-design confirmationmodel" className="">
            <Modal.Header className="model-header-content" closeButton>
                <Modal.Title >Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to Save</Modal.Body>
            <Modal.Footer className="model-footer-content">
                <Button variant="secondary" className="control-btn"onClick={handleClose}>
                    No
                </Button>
                <Button variant="primary" className="control-btn" onClick={() => {
                    handleClose();
                    myclick();
                }}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}


export default ConfirmationModal;