import React from 'react';
import "../../../styles/Portal.css";
import "../../../styles/querysteps.css";

const Attachments = () => {
    return <div>Attachements</div>;
}



export default Attachments;