import React, {useContext} from 'react';
import '../../styles/Slider.css';
import '../../styles/Application.css';
import '../../styles/Slider.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GlobalStateContext} from "../../context/index";


const DependantSlider = ({formData,setFormData}) => {

    const {relationships, nationality} = useContext(GlobalStateContext)


    return <>
        <div className="slider-container">
            {(() => {
                try {
                    return (
                        <>
                        {formData.dependents.map((item) => (
                            <Row className="slider-body">
                                <Col md={2}>
                                    <label className="slider-label">Surname</label> 
                                    
                                </Col>
                                <Col md={2}>
                                    <p className="slider-text">{item.dependant_surname}</p>
                                </Col>
                                <Col md={2}>
                                    <label className="slider-label">Given Name</label> 
                                </Col>
                                <Col md={2}>
                                    <p className="slider-text">{item.dependent_given_name}</p>
                                </Col>
                                <Col md={2}>
                                    <label className="slider-label">Other Names</label> 
                                </Col>
                                <Col md={2}>
                                <p className="slider-text">{item.dependent_other_names}</p>
                                </Col>
                                <Col md={2}>
                                    <label className="slider-label">Sex</label> 
                                </Col>
                                <Col md={2}>
                                <p className="slider-text">{item.dependant_sex}</p>
                                </Col>
                                <Col md={2}>
                                    <label className="slider-label">Date Of Birth</label> 
                                </Col>
                                <Col md={2}>
                                <p className="slider-text">{item.date_of_birth.toLocaleDateString()}</p>
                                </Col>
                                <Col md={2}>
                                    <label className="slider-label">Relationship</label> 
                                    
                                </Col>
                                <Col md={2}>
                                <p className="slider-text">
                                    {(() => {
                                        for (var i=0; i<relationships.length; i++) {
                                            
                                            if (relationships[i].id == item.relationship){
                                                return (
                                                    <>{relationships[i].name}</>
                                                )
                                                
                                            }
                                        
                                        }
                                    })()}
                                    </p>
                                </Col>
                                <Col md={2}>
                                    <label className="slider-label">Nationality</label> 
                                    
                                    {/* <p className="slider-text">{item.dependant_nationality}</p> */}
                                </Col>
                                <Col md={2}>
                                <p className="slider-text">
                                    {(() => {
                                        for (var i=0; i<nationality.length; i++) {
                                            
                                            if (nationality[i].id == item.dependant_nationality){
                                                return (
                                                    <>{nationality[i].name}</>
                                                )
                                                
                                            }
                                        
                                        }
                                    })()}
                                    </p>
                                </Col>
                            </Row>
                        ))}
                        </>
                    );
                }
                catch(err){
                    console.log(err)
                }
            })()}
            
            
        </div>
    </>;
}



export default DependantSlider;