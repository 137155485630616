import React, {useEffect, useContext, useState} from 'react';
import Card from '../Card';
import "../../styles/Portal.css";
import "../../styles/Global.css";
import Table from "../Table";
import {GlobalStateContext} from "../../context/index";
import constants from '../../constants/constants';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import "../../styles/querysteps.css";
import Applicant from "./querysteps/Applicant";
import Application from "./querysteps/Application";
import Attachments from "./querysteps/Attachments";
import Dependants from "./querysteps/Dependants";
import Fathers from "./querysteps/Fathers";
import Mothers from "./querysteps/Mothers";
import NOK from "./querysteps/NOK";
import Spouse from "./querysteps/Spouse";
import Button from 'react-bootstrap/Button'; 
import { FiChevronRight, FiChevronLeft, FiEdit } from "react-icons/fi";

const QueryReview = ({showPreview,handlePreviewClose, data}) => {

    const [page, setPage] = useState(1);

    const PageDisplay = () => {

        if (page === 1) return <Applicant data={data}/>
        else if (page === 2) return <Application/>
        else if (page === 3) return <Attachments/>
        else if (page === 4) return <Dependants/>
        else if (page === 5) return <Fathers/>
        else if (page === 6) return <Mothers/>
        else if (page === 7) return <NOK/>
        else if (page === 8) return <Spouse />

    };
    return (
        <Modal centered size="xl" show={showPreview} onHide={handlePreviewClose} contentClassName="portal-content-design" aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header className="model-header-content" closeButton>
                <Modal.Title className="model-title-style" >Review Mismatch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="horizontal-flex query-container">

                    <div className="query-step-container">
                        <div onClick={() => {setPage(1)}} className = {`query-steps ${page === 1 ? 'query-steps-activate': ''}`}>Applicant</div>
                        <div onClick={() => {setPage(5)}}  className = {`query-steps ${page === 5 ? 'query-steps-activate': ''}`}>Father</div>
                        <div onClick={() => {setPage(6)}}  className = {`query-steps ${page === 6 ? 'query-steps-activate': ''}`}>Mother</div>
                        <div  className = {`query-steps ${page === 3 ? 'query-steps-activate': ''}`}></div>
                        <div  className = {`query-steps ${page === 4 ? 'query-steps-activate': ''}`}></div>
                        <div  className = {`query-steps ${page === 2 ? 'query-steps-activate': ''}`}></div>
                        
                        <div  className = {`query-steps ${page === 7 ? 'query-steps-activate': ''}`}></div>
                        <div className = {`query-steps ${page === 8 ? 'query-steps-activate': ''}`}></div>
                        
                    </div>
                    <div className="vertical-flex query-body">
                        {PageDisplay()}
                        <div className="horizontal-flex btn-right">
                            <Button 
                                // onClick={handleShow}
                                className="buttontextsize"
                                variant="danger" size="lg">
                                    {/* <FiSend size={25} className="slider-icon"/>  */}
                                Deffer  
                                    
                            </Button>
                            <Button 
                                // onClick={handleShow}
                                className="buttontextsize btn-grad-secondary"
                                variant="success" size="lg">
                                    {/* <FiSend size={25} className="slider-icon"/>  */}
                                    Update
                            </Button>
                        </div>
                    </div>
                    
                </div>
            </Modal.Body>
        </Modal>
    );
}



export default QueryReview;