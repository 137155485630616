import React , {useContext} from 'react';
import '../../styles/Slider.css';
import '../../styles/Global.css';
import '../../styles/Application.css';
import '../../styles/Slider.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GlobalStateContext} from "../../context/index";

const SpouseSlider = ({formData,setFormData}) => {

    const {nationality} = useContext(GlobalStateContext);

    return <>
        <div className="slider-container">
            <Row className="slider-body">
                <Col md={2}>
                    <label className="slider-label">Spouse's Surname</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.spouse_surname}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Spouse's Given Name</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.spouse_given_name}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Spouse's Maiden Names</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.spouse_maiden_names}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Spouse's Date of Marriage</label> 
                    {/* formData.date_of_marriage.toLocaleDateString() */}
                    
                </Col>
                <Col md={2}>
                    <p className="slider-text">
                    {
                        (()=>{

                            if (formData.date_of_marriage===""){
                                return (
                                    <>{formData.date_of_marriage}</>
                                )
                            }
                            else {
                                try {
                                    return (
                                        <>{formData.date_of_marriage.toLocaleDateString()}</>
                                    )
                                } catch (e) {
                                    return (
                                        <>{formData.date_of_marriage}</>
                                    )
                                }
                                
                            }

                        })()
                    }
                    </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Spouse's Place Of Marriage</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.place_of_marriage}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Spouse's Nationality</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">
                    {/* {formData.spouse_nationality} */}
                    {(() => {
                        for (var i=0; i<nationality.length; i++) {
                            
                            if (nationality[i].id == formData.spouse_nationality){
                                return (
                                    <>{nationality[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                </p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Spouse's Telephone Number</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.spouse_primary_telephone_number}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Spouse's Other Telephone Number</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.spouse_other_telephone_number}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Spouse's Email Addreess</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.spouse_email_address}</p>

                </Col>
            </Row>
        </div>
    </>;
}



export default SpouseSlider;