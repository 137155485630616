import React, {useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import '../../styles/Slider.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GlobalStateContext} from "../../context/index";



const NOKSlider = ({formData,setFormData}) => {

    const {relationships, nationality, countries, districts, counties, subcounties} = useContext(GlobalStateContext)

    return <>
        <div className="slider-container">
            <Row className="slider-body">
                
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Surname</label> 
                    {/* <p className="slider-text">{formData.next_of_kin_surname}</p> */}
                </Col>
                <Col className="slider-item" md={2}>
                    <p className="slider-text">{formData.next_of_kin_surname}</p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Given Name</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.next_of_kin_given_name}</p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Country</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">
                {(() => {
                            for (var i=0; i<countries.length; i++) {
                                
                                if (countries[i].id == formData.next_of_kin_country){
                                    return (
                                        <>{countries[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                    {/* {formData.next_of_kin_country} */}
                </p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's District</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">
                    {(() => {
                            for (var i=0; i<districts.length; i++) {
                                
                                if (districts[i].id == formData.next_of_kin_district){
                                    return (
                                        <>{districts[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                </p>
                    
                {/* <p className="slider-text">{formData.next_of_kin_district}</p> */}

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's County</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">
                    {(() => {
                            for (var i=0; i<counties.length; i++) {
                                
                                if (counties[i].id == formData.next_of_kin_county){
                                    return (
                                        <>{counties[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                </p>
                        
                {/* <p className="slider-text">{formData.next_of_kin_county}</p> */}

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Sub Country</label> 
                </Col>
                <Col className="slider-item" md={2}>
                {/* <p className="slider-text">{formData.next_of_kin_sub_county}</p> */}
                <p className="slider-text">
                    {(() => {
                            for (var i=0; i<subcounties.length; i++) {
                                
                                if (subcounties[i].id == formData.next_of_kin_sub_county){
                                    return (
                                        <>{subcounties[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                </p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Plot Number</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.next_of_kin_plot_number}</p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Relationship</label> 
                    
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">
                    {(() => {
                            for (var i=0; i<relationships.length; i++) {
                                
                                if (relationships[i].id == formData.next_of_kin_relationship){
                                    return (
                                        <>{relationships[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        {/* {formData.next_of_kin_relationship} */}
                    </p>
                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Nationality</label> 
                    
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">
                    {(() => {
                            for (var i=0; i<nationality.length; i++) {
                                
                                if (nationality[i].id == formData.next_of_kin_nationality){
                                    return (
                                        <>{nationality[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        {/* {formData.next_of_kin_nationality} */}
                    </p>
                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Identity Card Number</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.next_of_kin_id_number}</p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Telephone Number</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.next_of_kin_primary_telephone_number}</p>

                </Col>
                <Col className="slider-item" md={2}>
                    <label className="slider-label">Next Of Kin's Other Phone Number</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.next_of_kin_other_telephone_number}</p>

                </Col>
                <Col className="slider-item" md={12}>
                    <label className="slider-label">Next Of Kin's Email Address</label> 
                </Col>
                <Col className="slider-item" md={2}>
                <p className="slider-text">{formData.next_of_kin_email_address}</p>

                </Col>
            </Row>
        </div>
    </>;
}



export default NOKSlider;