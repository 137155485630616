import React, {useState, useContext} from 'react';
import {GlobalStateContext} from "../../context/index";
import {useNavigate, useLocation} from "react-router-dom";
import '../../styles/Global.css';
import '../../styles/Portal.css';
import coatofarms from '../../assets/img/coatofarms.png';
import Button from 'react-bootstrap/Button';  
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import constants from '../../constants/constants';

const Login = () => {

    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const {login} = useContext(GlobalStateContext)
    const navigate = useNavigate()
    const location = useLocation()
    const redirectpath = location.state?.path || '/'

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const handleLogin = () => {
        login(user, password)
        navigate(redirectpath, {replace: true})


        // axios.post(constants.url+'/auth/login/', {username: user, password: password})
        // .then(res => {
        //     var results = res.data
        //     sessionStorage.setItem("access", results["access"])
        //     sessionStorage.setItem("refresh", results["refresh"])
        //     window.location.href = "/backoffice";
        //     navigate(redirectpath, {replace: true})
        // })
        // .catch(err => {
        //     console.log("-----------------")
        //     console.log("Here")
        //     toast("Test")
        //     return "error"
        // })
        
    }

    return (
    <div className="portalcontainer">
        <ToastContainer />
        <div className="logincontainer">
            <div className="login">
                <img className="loginlogo" src={coatofarms} alt="Logo" />
                <p className="logintitle">CONVENTIONAL TRAVEL DOCUMENT <br/> SCHEDULER</p>
                <div className="form-group">
                    <label className="mylabel control-label loginlabel">Username</label>
                    <input type="text" className="form-control myinput" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                    onChange={event => {setUser(event.target.value)}}
                    />
                </div>
                <div className="form-group">
                    <label className="mylabel control-label loginlabel">Password</label>
                    <input type="password" className="form-control myinput" aria-label="Default" 
                    aria-describedby="inputGroup-sizing-default"
                    onChange={event => {setPassword(event.target.value)}}
                    />
                </div>
                <p className="forgot-password">Forgot Password</p>
               
                <Button 
                    onClick={handleLogin}
                    className="control-btn buttontextsize text-white loginbutton" 
                    variant="success" size="lg">Sign In</Button>

            </div>
            
        </div>
    </div>
    );
}



export default Login;