import React from 'react';
import "../../../styles/Portal.css";
import "../../../styles/querysteps.css";

const Dependants = () => {
    return <div>Dependants</div>;
}



export default Dependants;