import React, {useEffect, useContext, useState} from 'react';
import Card from '../Card';
import "../../styles/Portal.css";
import "../../styles/Global.css";
import Table from "../Table";
import {GlobalStateContext} from "../../context/index";
import constants from '../../constants/constants';
import axios from 'axios';
import CalenderView from "./CalenderView";
import axiosInstance from '../../services/axiosInstance';
import Preloader from '../Preloader';


const Scheduled = () => {

    const {schedulePage, setSchedulePage} = useContext(GlobalStateContext);
    const [unscheduled, setUnscheduled] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [timeslots, setTimeslots] = useState([]);
    const [preloader, setPreloader] = useState(false);

    const UnScheduledcolumns = [ 
        { Header: 'Application Number', accessor: 'application_id' }, 
        { Header: 'Surname', accessor: 'surname' }, 
        { Header: 'Given Name', accessor: 'given_name' },  
        { Header: 'Refugee No', accessor: 'refugee_No' },
        { Header: 'Application Type', accessor: 'application_type' },
        { Header: 'Nationality', accessor: 'nationality' },
        { Header: 'Phone Number', accessor: 'phone_number' },
        // { Header: 'Place Of Submission', accessor: 'place_of_submission' },
        { Header: 'Sex', accessor: 'sex' },
        { Header: 'Language', accessor: 'primary_language' },

    ]

    const reservationColumns = [

        { Header: 'Application', accessor: 'application_id' }, 
        { Header: 'Applicant Name', accessor: 'applicant' }, 
        { Header: 'Appointment Date', accessor: 'appointment_date' }, 
        { Header: 'Time Slot', accessor: 'slot_details' }, 
        // { Header: 'Service Type', accessor: 'reservation_type' }, 

    ]

    

    useEffect(()=> {

        axiosInstance.get(constants.url+'/reservations/appointment-slots/', 
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        }
        )
        .then(res => {
            // console.log(res.data)
            setTimeslots(res.data)
        })

        axiosInstance.get(constants.url+'/opm/unscheduled-applications/', 
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        }
        )
        .then(res => {
            // console.log(res.data.results)
            setUnscheduled(res.data.results)
        })


        axiosInstance.get(constants.url+'/reservations/reservations/?search=scheduled', 
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        }
        )
        .then(res => {
            var rs = []
            for (let x = 0; x < timeslots.length; x++) {
                var t = timeslots[x].start_time + " - " + timeslots[x].end_time
                // console.log(t)
                for (let y = 0; y < res.data.results.length; y++) {
                    // console.log(y)
                    if (res.data.results[y].time_slot == timeslots[x].id) {
                        res.data.results[y].slot_details = t
                        rs.push(res.data.results[y])
                    }
                }
            }
            // console.log(res.data.results)
            // var data = res.data.results
            // console.log(data)
            // setReservations(data)
            // console.log(rs);
            setReservations(rs);
        })



    },[])

    

    const [showPreview, setShowPreview] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})

    const ViewShedule = (data) => {
        setSelectedRow(data)
        setShowPreview(true)
    }

    const handlePreviewClose = () => {
        axiosInstance.get(constants.url+'/opm/unscheduled-applications/', 
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        }
        )
        .then(res => {
            console.log(res.data.results)
            setUnscheduled(res.data.results)
        })

        axiosInstance.get(constants.url+'/reservations/reservations/?search=scheduled', 
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        }
        )
        .then(res => {
            // console.log("--------------------")
            // console.log(res.data.results)
            setReservations(res.data.results)
        })

        setShowPreview(false)
    }

    useEffect(() => {
        axiosInstance.get(constants.url+'/reservations/available-slots/',
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        })
        .then(res => {
            console.log(res.data)
            
        })
    }, [])

    useEffect(() => {
        setPreloader(true);
        setTimeout(() => {
            setPreloader(false);
        }, 1500);
    },[])

    return (
        <>
            { preloader ? <Preloader /> : null }
            <div className="inner-portal">
                <Card >
                    <div className="card-container">
                        {(() => {

                            if (schedulePage === 1){
                                return (
                                    <Table columns={UnScheduledcolumns} data={unscheduled} onclick={ViewShedule} title="Unscheduled Applications" />
                                );
                            }

                            else if (schedulePage === 2){
                                return (
                                    <Table columns={reservationColumns} data={reservations} title="Scheduled Applications" />
                                );
                            }

                        })()}
                    </div>
                </Card>
                <CalenderView showPreview={showPreview} handlePreviewClose={handlePreviewClose} data={selectedRow} />
            </div>
        </>
    );
}



export default Scheduled;