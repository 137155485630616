import React, {useEffect, useState, useContext} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/Global.css';
import '../styles/CtdHome.css';
import "../styles/HomeMenu.css";
import "../styles/Application.css";
import "../styles/Status.css";
import HomeMenu from '../components/HomeMenu';
import constants from '../constants/constants';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import { popup } from '../services/Services';
import 'react-toastify/dist/ReactToastify.css';
import {GlobalStateContext} from "../context/index";
import Button from 'react-bootstrap/Button';
import CalenderView from '../components/portal/CalenderView';
import CalenderPublic from '../components/CalenderPublic';
import DatePicker from "react-date-picker";
import { StringToDateConverter } from "../services/Services";


export default function ScheduleAppointment() {

    const {applicationid, setApplicationId,status, setStatus,statusform, setStatusForm, checkresumeform, setCheckApplicationForm,useResumeApplication,emailvalidation, setEmailValidation, validateEmail, telephoneValidation, settelephoneValidation,telephoneCheck, CheckApplicationStatus, setapplicationForm} = useContext(GlobalStateContext);
    const [security, setSecurity] = useState([]);
    const [page, setPage] = useState(1);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [getapplicationForm, setApplicationForm] = useState({
        refugee_No: "",
        phone_number: "",
        security_question: "",
        security_answer: "",
    });

    const [refugee_No, setRefugee_No] = useState("");
    const [phone_number, setPhone_number] = useState("");
    const [security_question, setSecurity_question] = useState("");
    const [security_answer, setSecurity_answer] = useState("");

    const [applicationnumber, setApplicantNumber] = useState("");
    const [dob, setDOB] = useState("");
    const [surname, setSurname] = useState("");

    const [prns, setPrns] = useState([])
    const [is_clicked, setIsClicked] = useState(false)

    useEffect(() => {
        axios.get(constants.url+"/applications/security-questions/")
        .then(res => {
            setSecurity(res.data)
        })
        .catch(err => {
            console.error(err)
            
        })
        setEmailValidation(false)
    }, [])

    const RetriveApplication = () => {
        setPage(2)
    }

    const handlePreviewClose = () => {
        setShowPreview(false)
    }

    const fetchApplication = () => {
        let months = ["01","02","03","04","05","06","07","08","09","10","11","12"];
        let date = dob.getFullYear() + "-" + months[dob.getMonth()] + "-" + dob.getDate();
        const requestForm = {
            application_id: applicationnumber,
            date_of_birth: date,
            surname: surname
        }

        if (applicationnumber==="" || date === "" || surname === ""){
            var errordict = {}
            if (applicationnumber==="") errordict["application_number"] = true
            if (date === "") errordict["dob"] = true
            if (surname === "") errordict["surname"] = true
           
            setCheckApplicationForm(errordict) 
        } else {
            axios
                .post(constants.url + "/applications/application-status/", requestForm)
                .then((res) => {
                    console.log(res.data.prns)
                    setPrns(res.data.prns)

                    if (res.data.prn_reconciled === false){
                        setIsClicked(true)
                        alert("Application not yet paid for \nPlease pay for application to continue")
                    }

                    else {
                        if (res.data.status === "scheduled"){
                        
                            alert("Application already scheduled")
                        }
                        else if (res.data.status === "pulled"){
                            alert("Application already processing")
                        }
                        else {
                            sessionStorage.setItem("application", JSON.stringify(res.data))
                            setPage(2)
                        }
                    }
                    
                    
                })
                .catch((e) => {
                    alert(
                        "Application Not Found \nPlease Check Your Details And Try Again"
                    );
                });
        }
    }

    const ScheduleCalender = () => {

        return (
            <Container className='statusform'>
                <Col md={12}>
                    <h3 className="application-title text-center py-1  mt-4">Select Appointment Day</h3>
                </Col>
                <div>
                    <CalenderPublic />
                </div>
            </Container>
        )

    }


    return (
        <>
            <div className="home-container">
                <HomeMenu/>
                {
                    (page === 1) ? (
                        <Container className='statusform2'>
                            <form className='form-container'>
                                <Row>
                                    <Col md={12}>
                                        <h3 className="application-title text-center py-1  mt-4">Schedule Appointment</h3>
                                    </Col>
                                    <Col md={12}>
                                        <ToastContainer />
                                        <div className="form-group required">
                                            <label className="mylabel control-label">
                                                Application Number
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control myinput`}
                                                aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={applicationnumber}
                                                onChange={(event) =>
                                                    setApplicantNumber(event.target.value)
                                                }
                                            />
                                            { checkresumeform.application_number ?  <p className="error-message">Application Number Required</p> : null}
                                        </div>

                                    </Col>
                                    <Col md={12}>

                                        <div className="form-group required">
            
                                                <label className="mylabel control-label">
                                                    Date Of Birth
                                                </label>
                                                <DatePicker
                                                    className="form-control p-0 myinput m-0"
                                                    value={StringToDateConverter(dob)}
                                                    onChange={(date) => setDOB(date)}
                                                    format="dd-MM-y"
                                                    minDate={new Date("1900-01-01")}
                                                    maxDate={new Date("2050-01-01")}
                                                    placeholderText={"dd-MM-yyyy"}
                                                    clearIcon={null}
                                                />
                                                { checkresumeform.dob ?  <p className="error-message">Date Of Birth Required</p> : null}
                                        </div>
                                        
                                    </Col>
                                    <Col md={12}>
                                        
                                        <label className="mylabel control-label">Surname</label>
                                        <input
                                            type="text"
                                            className={`form-control myinput`}
                                            aria-label="Default"
                                            aria-describedby="inputGroup-sizing-default"
                                            value={surname}
                                            onChange={(event) => setSurname(event.target.value)}
                                        />
                                        { checkresumeform.surname ?  <p className="error-message">Surname Required</p> : null}
                                        
                                    </Col>
                                    <Button 
                                        className="buttontextsize next-btn  btn-grad-secondary resume-btn  my-4" 
                                        variant="" size="lg"
                                        onClick={fetchApplication}
                                        >Next</Button>
                                </Row>
                                
                            </form>
                            {
                                    is_clicked ? <div className='pb-1 d-block'>

                                        <table className='table mb-5 p-2 w-75 mx-auto'>
                                            <tr className='py-2'>
                                                <th className="px-1">PRN</th>
                                                <th className="px-1">Amount</th>
                                                <th className="px-1">Status</th>
                                            </tr>
                                            {
                                                prns.map((prn, index) => (
                                                    <tr>
                                                        <td className="px-1">{prn.prn}</td>
                                                        <td className="px-1">{prn.amount}</td>
                                                        <td className="px-1">
                                                        {
                                                            prn.date_paid==="" || prn.date_paid == null ?
                                                                <p className='text-danger'>Not paid</p>
                                                             :
                                                                <p>Paid</p>
                                                            
                                                        }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    
                                    </div>: null
                                }
                            </Container>
                    ) : (
                        <ScheduleCalender/>
                    )
                }
            </div>
        </>
    )
}
