import React from 'react';
import '../styles/GlobalFilter.css';

const GlobalFilter = ({filter, setFilter}) => {
    return (
        <span className="filter-container">
            Search: {' '}
            <input className="filter-input" value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
            />
        </span>
    );
}


export default GlobalFilter;