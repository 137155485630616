import React, {useState, useEffect} from 'react';
import constants from '../constants/constants';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {popup, popupctdformerror} from '../services/Services';

const globalState = {
};
  
export const GlobalStateContext = React.createContext();
  
export default function GlobalContextProvider ({children}) {

    const [time, setTime] = useState(1);

    const [uganda, setUganda] = useState("");

    const [preloader, setPreloader] = useState(false)

    useEffect(()=> {
        axios.get(constants.url+"/common/countries/")
        .then(res => {

            for (var i=0; i < res.data.length; i++) {
                if (res.data[i].name.toLowerCase() == "uganda"){
                    setUganda(res.data[i].id);
                    break;
                }
            }
        })
        .catch(err => {
            console.error(err)
        })
    },[])

    // Application form state
    const [formData, setFormData] = useState({

        refugee_No:"", application_type:"",place_of_submission:"",ctd_number:"",place_of_issue:"",expiry_date:"",
        surname:"",given_name:"",maiden_name:"",other_names:"",phone_number:"",sex:"",date_of_birth: "",place_of_birth:"",
        marital_status:"",occupation:"",individual_number:"",household_number:"",email_address:"",nationality:"",
        signature:"",previous_travel_document_number:"",photo:"",fingerprints:"",address_in_uganda:"",country_of_residence: uganda,
        district:"",county:"",sub_county:"",parish:"",village:"",plot_number:"",out_of_uganda_address:"",
        legal_status: "",
        spouse_surname:"",spouse_given_name:"",
        spouse_maiden_names:"",date_of_marriage:"",place_of_marriage:"",spouse_nationality:"",spouse_primary_telephone_number:"",
        spouse_other_telephone_number:"",spouse_email_address:"",
        father_status:"",father_surname:"",father_given_name:"", 
        father_birth_country:"",father_residence_country:"",father_district:"",father_county:"",father_sub_county:"",
        father_parish:"",father_plot_number:"",father_tribe:"",father_nationality:"",father_primary_telephone_number:"",
        father_other_telephone_number:"",father_email_address:"", father_other_names: "", father_foreign_address: "", next_of_kin_foreign_address: "",
        mother_status:"",mother_surname:"",mother_given_name:"",mother_birth_country:"",mother_place_of_birth:"",mother_residence_country:"",
        mother_district:"",mother_county:"",mother_sub_county:"",mother_parish:"",mother_plot_number:"",mother_tribe:"",mother_nationality:"",
        mother_primary_telephone_number:"",mother_other_telephone_number:"",mother_email_address:"", mother_other_names: "", mother_foreign_address: "",
        
        dependents: [], dependant_id: "", dependant_application: "", dependant_name: "",
        dependant_surname: "", dependent_given_name: "", dependent_other_names: "", dependant_sex: "",
        dependant_relationship: "", dependant_nationality: "", dependant_dob: "",
        dependant_id_number: "", dependant_id_type: "", dependant_id_country_of_issurance:"",

        next_of_kin_surname:"",next_of_kin_given_name:"",next_of_kin_district:"",next_of_kin_county:"",next_of_kin_plot_number:"",next_of_kin_relationship:"",
        next_of_kin_nationality:"",next_of_kin_id_number:"",next_of_kin_occupation:"",next_of_kin_primary_telephone_number:"",next_of_kin_other_telephone_number:"",
        next_of_kin_email_address:"",next_of_kin_country: "",next_of_kin_sub_county: "", next_of_kin_other_name: "",
        paymentmode: "", bank: "", security_question: "", security_answer: "", completed: false, id: "", application_id: "",
        refugee_settlement: "",
        primary_language: "", other_language:"",
        processing_speed: "1"
        
    });

    // Resume form state
    const [resumeFormData, setResumeFormData] = useState({
        refugee_No:"", phone_number:"", security_question: "",
        security_answer: ""
    })

    
    // Resume form error state 
    const [checkresumeform, setCheckApplicationForm] = useState({});

    // Status form error state 
    const [statusform, setStatusForm] = useState({});

    // Application form state prop
    const [applicationState, setApplicationState] = useState({});

    // Resume Submission function
    const useResumeApplication = () => {

        if (resumeFormData.refugee_No==="" || resumeFormData.phone_number === "" || resumeFormData.security_question === "" || resumeFormData.security_answer === ""){
            var errordict = {}
            if (resumeFormData.refugee_No==="") errordict["refugee_No"] = true
            if (resumeFormData.phone_number === "") errordict["phone_number"] = true
            if (resumeFormData.security_question === "") errordict["security_question"] = true
            if (resumeFormData.security_answer === "") errordict["security_answer"] = true
           
            setCheckApplicationForm(errordict)

        }else {
            console.log(resumeFormData)
            axios.post(constants.url+"/applications/resume/", resumeFormData, {
                headers : {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            })
            .then(res => {

                if (res.data.status === "draft"){
                    console.log(res.data)
                    for (var key in res.data) {

                        if (res.data[key] === null){
                            sessionStorage.setItem(key,"")
                            console.log("")
                        }
                        else if (key === "dependents"){
                            sessionStorage.setItem(key,JSON.stringify(res.data[key]))
                        }
                        else {
                            sessionStorage.setItem(key,res.data[key])
                        }

                    }

                    window.location.href = "/ctd/application";

                }
                else {
                    alert("Application already submitted")
                }
                
                
            })
            .catch(err => {
                console.error(err)
                alert("Application not found \nPlease check your details and try again")
            })
        }
    }

    // Status form state prop

    const CheckApplicationStatus = () => {
        console.log("------------------------")
    }

    // Application submission form
    const [submission_location, setSubmission_locations] = useState([]);
    useEffect(() => {

        axios.get(constants.url+"/common/submission-locations/")
        .then(res => {
            setSubmission_locations(res.data)
        })
        .catch(err => {
            console.error(err)
        })

    }, [])

    // Applicant occupation state and api call
    const [occupation, setOccupation] = useState([]);

    // Nationality
    const [nationality, setNationality] = useState([]);

    // Relationships
    const [relationships, setRelationships] = useState([]);
    const [country_of_residence, setCountryOfResidence] = useState([]);

    // Fetch Nationality and Relationships
    useEffect(() => {
        axios.get(constants.url+"/common/nationalities/")
        .then(res => {
            setNationality(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        axios.get(constants.url+"/common/relationships/")
        .then(res => {
            setRelationships(res.data)
        })
        .catch(err => {
            console.error(err)
        })
        axios.get(constants.url+"/common/countries/")
        .then(res => {
            for (var i=0; i<res.data.length; i++) {
                if (res.data[i].name.toLowerCase() === "uganda") {
                    setCountryOfResidence(res.data[i].id)
                }
            }
        })



    }, [])

    // Date formate function
    function dateToYMD(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    // Application Response keys
    const [applicationkey, setApplicationkey] = useState();

    // Application Submit Click
    const [applicationsubmit_click, setApplicationSubmitClick] = useState(false);
   
    // Application form submission
    const submitApplicationform  = () => {
        setPreloader(true)

        var form_data = formData;
        
        for (var key in formData) {
            if (key === "dependents"){
                console.log(form_data[key])
                if (form_data[key].length > 0){
                    for (var i=0; i<form_data[key].length; i++){
                        form_data[key][i].date_of_birth = dateToYMD(form_data[key][i].date_of_birth)
                        delete form_data[key][i].application
                        delete form_data[key][i].id
                    }
                }
            }

            if (key === "date_of_birth"){
                try {
                    var dob = formData[key]
                    var newdate = dateToYMD(dob)
                    form_data[key] = newdate
                }
                catch {
                    form_data.key = null
                }
            }

            else if (key === "mother_primary_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }
            
            else if (key === "father_birth_country"){
                try {
                    var dob = formData[key]
                    var newdate = dateToYMD(dob)
                    form_data[key] = newdate
                }
                catch {
                    form_data.key = null
                }
            }
            else if (key === "date_of_marriage"){
                try {
                    var dob = formData[key]
                    var newdate = dateToYMD(dob)
                    form_data[key] = newdate
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "expiry_date"){
                try {
                    var dob = formData[key]
                    var newdate = dateToYMD(dob)
                    form_data[key] = newdate
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "completed"){
                try {
                    form_data[key] = true
                }
                catch {
                    form_data[key] = false
                }
            }

            else if (key === "phone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "next_of_kin_primary_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone  
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "spouse_primary_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "spouse_other_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "father_primary_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone 
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "father_other_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            

            else if (key === "mother_other_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "next_of_kin_other_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "address_in_uganda"){
                try {
                    if (form_data[key] === ""){
                        form_data[key] = null
                    }
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "country_of_residence"){
                form_data[key] = country_of_residence
            }

            

            if (formData[key] === null) {
                delete form_data[key]
            }
        }


        form_data.status = "submitted";
        console.log("");

        // console.log(form_data)

        var resume = sessionStorage.getItem("resume")
        setApplicationSubmitClick(true)
        if (resume === "true") {
            var resume_id = sessionStorage.getItem("resume_id")
            axios.put(constants.url+"/applications/applications/"+resume_id+"/", form_data, {
                headers : {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            })
            .then(res => {
                var results = res.data
                // console.log(results)
                
                setApplicationkey(results)
                for (var key in results) {

                    if (results[key] === null){
                        sessionStorage.setItem(key,"")
                    }
                    else {
                        sessionStorage.setItem(key,results[key])
                    } 
                }
                setPreloader(false)
                sessionStorage.clear()
                window.location.href = "/ctd/application/print/";
            })
            .catch(err => {
                setApplicationSubmitClick(false)
                console.error(err)
                setPreloader(false)
                sessionStorage.clear()
                
                popupctdformerror(<>
                    <p className='fw-bold text-danger'>Error</p>
                    {
                        // Try and catch
                        (err.response.data === undefined) ? <p className='fw-bold text-dark' >An error occured while saving your application. Please try again later</p> :
                        Object.keys(err.response.data).map((key, index) => {
                            return (<p key={index} className=""><span className='fw-bold text-dark'>{FormateString(key)}</span> : {err.response.data[key]}</p>)
                        })
                    }
                </>)
            })
        } else {
            // console.log("-------------------")
            // console.log(form_data)
            axios.post(constants.url+"/applications/applications/", form_data, {
                headers : {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            })
            .then(res => {
                var results = res.data
                console.log(results)
                var prns = results.prns
                // console.log(prns)

                try {
                    if (prns[0].prn != null){
                        setApplicationkey(results)
                        for (var key in results) {
    
                            if (results[key] === null){
                                sessionStorage.setItem(key,"")
                            }
                            else {
                                sessionStorage.setItem(key,results[key])
                            } 
                        }
                        setPreloader(false)
                        window.location.href = "/ctd/application/print/";
                    } else {
                        setApplicationSubmitClick(false)
                        // alert(
                        //     "Unable to generate a PRN at the moment. Try again later by clicking on Resume Application.\nTo Resume, Please use \nRefugee Number:   "+results.refugee_No+"\nPhone Number:   "+results.phone_number+"\nSecurity Question:   "+results.security_question+"\nSecurity Answer:   "+results.security_answer+"\n"    
                        // )
                        // window.location.href = "/ctd";

                        var data = {
                            refugee_No: results.refugee_No,
                            phone_number: results.phone_number,
                            security_question: results.security_question,
                            security_answer: results.security_answer
                        }

                        // popup("Unable to generate a PRN at the moment. Try again later by clicking on Resume Application.\nTo Resume, Please use \nRefugee Number:   "+results.refugee_No+"\nPhone Number:   "+results.phone_number+"\nSecurity Question:   "+results.security_question+"\nSecurity Answer:   "+results.security_answer+"\n")
                        setPreloader(false)

                        popupctdformerror(
                            <>
                                <p className='fw-bold text-danger'>Error: Unable to generate a PRN at the moment. Try again later by clicking on Resume Application.</p>
                                {
                                    Object.keys(data).map((key, index) => {
                                        return (<p key={index} className=""><span className='fw-bold text-dark'>{FormateString(key)}</span> : {data[key]}</p>)
                                    })
                                }
    
                            </>
                        )

                        
                        
                    }
                } catch (e) {
                    setPreloader(false)
                    setApplicationSubmitClick(false)
                    // alert(
                    //     "Unable to generate a PRN at the moment. Try again later by clicking on Resume Application.\nTo Resume, Please use \nRefugee Number:   "+results.refugee_No+"\nPhone Number:   "+results.phone_number+"\nSecurity Question:   "+results.security_question+"\nSecurity Answer:   "+results.security_answer+"\n"    
                    // )
                    // window.location.href = "/ctd";
                    var data = {
                        refugee_No: results.refugee_No,
                        phone_number: results.phone_number,
                        security_question: FindSecurityQuestion(results.security_question),
                        security_answer: results.security_answer
                    }
                    // popup("Unable to generate a PRN at the moment. Try again later by clicking on Resume Application.\nTo Resume, Please use \nRefugee Number:   "+results.refugee_No+"\nPhone Number:   "+results.phone_number+"\nSecurity Question:   "+results.security_question+"\nSecurity Answer:   "+results.security_answer+"\n")

                    popupctdformerror(
                        <>
                            <p className='fw-bold text-danger'>Error: Unable to generate a PRN at the moment. Try again later by clicking on Resume Application.</p>
                            {
                                Object.keys(data).map((key, index) => {
                                    return (<p key={index} className=""><span className='fw-bold text-dark'>{FormateString(key)}</span> : {data[key]}</p>)
                                })
                            }

                        </>
                    )

                    // window.location.href = "/ctd";

                }
                

            })
            .catch(err => {
                setPreloader(false)
                setApplicationSubmitClick(false)
                // console.error(err.response.data)
                // alert("An error occured while saving your application. Please try again later")
                

                popupctdformerror(<>
                    <p className='fw-bold text-danger'>Error</p>
                    {
                        // Try and catch
                        (err.response.data === undefined) ? <p className='fw-bold text-dark' >An error occured while saving your application. Please try again later</p> :
                        Object.keys(err.response.data).map((key, index) => {
                            return (<p key={index} className=""><span className='fw-bold text-dark'>{FormateString(key)}</span> : {err.response.data[key]}</p>)
                        })
                    }
                </>)

                // window.location.href = "/ctd";
            })
        }

    }

    // Find security question text using the id
    const FindSecurityQuestion = (id) => {
        for (var i=0; i<security.length; i++){
            if (security[i].id === id){
                return security[i].text
            }
        }
    }

    // Remove _ and capitalize first letter
    const FormateString = (string) => {
        var newstring = string.replace(/_/g, ' ')
        return newstring.charAt(0).toUpperCase() + newstring.slice(1)
    }

    // Save and exit
    const saveApplicationform  = () => {

        var form_data = formData;

        for (var key in formData) {
            if (key === "date_of_birth"){
                if (formData[key] === "" || formData[key] === null){
                    delete formData.key
                }
                else {
                    try {
                        var dob = formData[key]
                        var newdate = dateToYMD(dob)
                        form_data[key] = newdate
                    }
                    catch {
                        form_data.key = null
                    }
                }
                
            }
            else if (key === "application_type" || key === "given_name" || key==="place_of_birth" || key==="sex" || key === "surname"){
                if (formData[key] === ""){
                    delete formData.key
                }
            }
            else if (key === "father_birth_country"){
                try {
                    var dob = formData[key]
                    var newdate = dateToYMD(dob)
                    form_data[key] = newdate
                }
                catch {
                    form_data.key = null
                }
            }
            else if (key === "date_of_marriage"){
                try {
                    var dob = formData[key]
                    var newdate = dateToYMD(dob)
                    form_data[key] = newdate
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "expiry_date"){
                try {
                    var dob = formData[key]
                    var newdate = dateToYMD(dob)
                    form_data[key] = newdate
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "completed"){
                try {
                    form_data[key] = true
                }
                catch {
                    form_data[key] = false
                }
            }

            else if (key === "phone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "next_of_kin_primary_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone  
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "spouse_primary_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "spouse_other_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "father_primary_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone 
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "father_other_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "mother_primary_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "mother_other_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "next_of_kin_other_telephone_number"){
                try {
                    var phone = formData[key]
                    var newphone = phone.replace(/\s/g, '')
                    if (newphone === ""){
                        form_data[key] = null
                    }
                    else {
                        form_data[key] = "+"+newphone
                    }
                    
                }
                catch {
                    form_data[key] = null
                }
            }

            else if (key === "address_in_uganda"){
                try {
                    if (form_data[key] === ""){
                        form_data[key] = null
                    }
                }
                catch {
                    form_data[key] = null
                }
            }
            if (formData[key] === null) {
                delete form_data[key]
            }
        }

        axios.post(constants.url+"/applications/applications/", form_data, {
            headers : {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        .then(res => {
            var results = res.data
            window.location.href = "/ctd";

        })
        .catch(err => {
            console.error(err)
        })


    }

    // Email formate state and validation
    const [emailvalidation, setEmailValidation] = useState(false);
    const validateEmail = (email) => {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(email);
        if (result===true) {
            setEmailValidation(false)
        }
        else {
            setEmailValidation(true)
        }
    }

    // Telephone formate state and validation
    const [telephoneValidation, settelephoneValidation] = useState(false);

    const telephoneCheck = (phone) => {
        const regExp = /^\+?([0-9]{12})$/;
        const result = regExp.test(phone.replace(/\s/g, ''));
        console.log(result)
        if (result===true){
            settelephoneValidation(false)
        }
        else {
            settelephoneValidation(true)
        }
    }

    // reCAPTCHA state
    const [recaptcha, setRecaptcha] = useState()

    // Authentication state
    const [user, setUser] = useState(null)

    // Logins
    const login = (user, password) => {
        
        axios.post(constants.url+'/accounts/login', {username: user, password: password})
        .then(res => {
            var results = res.data
            sessionStorage.setItem("access", results["access"])
            sessionStorage.setItem("refresh", results["refresh"])
            window.location.href = "/ctd/backoffice";
        })
        .catch(err => {
            toast("Incorrect Username or Password")
        })
    }

    const logout = () => {
        // setUser(null)
        sessionStorage.setItem("access", null)
        sessionStorage.setItem("refresh", null)
    }

    // Fetch All Applications
    const FetchApplications = () => {
        // var applications = []
        // axios.get(constants.url+'/applications/applications/')
        // .then(res => {
        //     console.log(res.data.results)
        //     applications = res.data.results;
        //     console.log(applications)

        //     return applications

        // })
    }

    // Payment status state 
    const [paymentpage, setPaymentPage] = useState(2)

    // Application Validation
    const ValidateApplications = () => {
        console.log("Validate")
    }

    // Schedule Pages
    const [schedulePage, setSchedulePage] = useState(1)

    //Legal Status
    const [legalStatus, setLegalStatus] = useState([])

    useEffect(() => {
        axios.get(constants.url+"/common/legal-status/")
        .then(res => {
            setLegalStatus(res.data)
        })
        .catch(err => {
            console.error(err)
        })

    }, [])

    // Securty Question

    const [security, setSecurity] = useState([])

    useEffect(() => {
        axios.get(constants.url+"/applications/security-questions/")
        .then(res => {
            console.log("")
            setSecurity(res.data)
        })
        .catch(err => {
            console.error(err)
        })
        setEmailValidation(false)
    }, [])

    const [countries, setCountries] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [counties, setCounties] = useState([]);
    const [subcounties, setSubcounties] = useState([]);
    const [parishes, setParish] = useState([]);
    const [villages, setVillages] = useState([]);
    const [language, setLanguage] = useState([]);
    const [professions, setProfessions] = useState([]);
    

    // Refugee Settlements
    const [settlements, setSettlements] = useState([]);

    // Application Status
    const [status, setStatus] = useState("");
    const [applicationid, setApplicationId] = useState(0);

    useEffect(()=>{
        axios.get(constants.url+"/common/districts/")
        .then(res => {
            setDistricts(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        // axios.get(constants.url+"/common/counties/")
        // .then(res => {
        //     setCounties(res.data);
        // })
        // .catch(err => {
        //     console.error(err)
        // })

        // axios.get(constants.url+"/common/sub-counties/")
        // .then(res => {
        //     setSubcounties(res.data)
        // })
        // .catch(err => {
        //     console.error(err)
        // })

        // axios.get(constants.url+"/common/parishes/")
        // .then(res => {
        //     setParish(res.data)
        // })
        // .catch(err => {
        //     console.error(err)
        // })

        axios.get(constants.url+"/common/relationships/")
        .then(res => {
            console.log(res.data)
            setRelationships(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        // axios.get(constants.url+"/common/villages/")
        // .then(res => {
        //     setVillages(res.data)
        // })
        // .catch(err => {
        //     console.error(err)
        // })

        axios.get(constants.url+"/common/nationalities/")
        .then(res => {
            setNationality(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        axios.get(constants.url+"/common/countries/")
        .then(res => {
            setCountries(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        axios.get(constants.url+"/common/languages/")
        .then(res => {
            setLanguage(res.data)
        })
        .catch(err => {
            console.log(err)
        })

        axios.get(constants.url+"/common/professions/")
        .then(res => {
            setProfessions(res.data)
        })
        .catch(err => {
            console.error(err)
        })

    },[])

    const NameToID = (name, array) => {
        // console.log(array)
        try {
            for (var i = 0; i<array.length; i++) {
                if (name===array[i].name){
                    return array[i].id
                } 
                
            }
            return name
        } catch (e) {
            return name
        }
        

    }

    const NameToIDAsync = async (name, array) => {
        try {
            for (var i = 0; i<array.length; i++) {
                if (name===array[i].name){
                    return array[i].id
                } 
                
            }
            return name
        } catch (e) {
            return name
        }
        

    }

    return(
        
        <GlobalStateContext.Provider
        value={{
            time,setTime,formData, setFormData,
            submission_location, setSubmission_locations,submitApplicationform,
            occupation, setOccupation, emailvalidation, setEmailValidation, validateEmail,
            telephoneValidation, settelephoneValidation,telephoneCheck,
            resumeFormData, setResumeFormData, checkresumeform, setCheckApplicationForm,useResumeApplication,
            recaptcha, setRecaptcha,applicationState, setApplicationState, user, login, logout,
            relationships, setRelationships,nationality, setNationality, FetchApplications,
            paymentpage, setPaymentPage, ValidateApplications, schedulePage, setSchedulePage,
            legalStatus, security, setSecurity,
            countries, setCountries, districts, setDistricts, counties, setCounties, subcounties, setSubcounties, 
            parishes, setParish, villages, setVillages, applicationkey, saveApplicationform, settlements,
            statusform, setStatusForm, CheckApplicationStatus, status, setStatus, applicationid, setApplicationId,
            uganda, setUganda, applicationsubmit_click, setApplicationSubmitClick,
            NameToID, NameToIDAsync, language, setLanguage, professions, setProfessions,
            preloader, setPreloader

        }}
        >
            {children}
        </GlobalStateContext.Provider>

    )

}

// export const useAuth = () => {
//     return useContext(GlobalStateContext)
// }
   
  