import React, {useContext} from 'react';
import '../../styles/Slider.css';
import '../../styles/Global.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GlobalStateContext} from "../../context/index";


const RefugeeSlider = ({formData,setFormData}) => {

    const {security} = useContext(GlobalStateContext)

    return <>
        <div className="slider-container">
            
            <Row className="slider-body">
            {/* className="center" */}
                {/* <Col md={12}>
                        <img src={formData.photo_url} id="file-ip-1-preview" className="sliderimage"/>
                </Col> */}
                <Row>
                    
                    <Col md={2}>
                        <label className="slider-label">Refugee Number</label> 
                    </Col>
                    <Col md={2}>
                        <p className="slider-text p-0">{formData.refugee_No}</p>
                    </Col>
                    <Col md={2}>
                        <label className="slider-label">Phone Number</label> 
                    </Col>
                    <Col md={2}>
                        <p className="slider-text">{formData.phone_number}</p>
                    </Col>
                    <Col md={2}>
                        <label className="slider-label">Email</label> 
                    </Col>
                    <Col md={2}>
                        <p className="slider-text">{formData.email_address}</p>

                    </Col>
                    <Col md={2}>
                        <label className="slider-label">Security Question</label> 
                        
                        
                    </Col>
                    <Col md={2}>
                        <p className="slider-text">
                        {(() => {
                            for (var i=0; i<security.length; i++) {
                                
                                if (security[i].id == formData.security_question){
                                    return (
                                        <>{security[i].text}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        </p>
                    </Col>
                    
                    {/* <Col md={6}>
                        <label className="slider-label">Phone Number</label> 
                        <p className="slider-text">{formData.phone_number}</p>
                    </Col> */}
                    
                    <Col md={2}>
                        <label className="slider-label">Security Answer</label> 
                    </Col>
                    <Col md={2}>
                        <p className="slider-text">{formData.security_answer}</p>
                    </Col>
                </Row>
                
            </Row>

        </div>
    </>;
}



export default RefugeeSlider;