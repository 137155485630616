import React, {useContext, useState} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import '../../styles/Slider.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {GlobalStateContext} from "../../context/index";

const ParentsSlider = ({formData,setFormData}) => {

    const {nationality, countries, districts, counties, subcounties, parishes} = useContext(GlobalStateContext)

    const [Status, setStatus] = useState([
        {id: 1, name: "Alive"},
        {id: 2, name: "Deceased"},
        {id: 0, name: "Unknown"}
    ]);

    return <>
        <div className="slider-container">
            <Row className="slider-body">
                <Col md={2}>
                    <label className="slider-label">Father's Surname</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.father_surname}</p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Given Name</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.father_given_name}</p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Other Names</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.father_other_names}</p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Status</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">
                    {/* {formData.father_status} */}
                    {(() => {
                        for (var i=0; i<Status.length; i++) {
                            
                            if (Status[i].id == formData.father_status){
                                return (
                                    <>{Status[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                </p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Nationality</label> 
                    
                </Col>
                <Col md={2}>
                    <p className="slider-text">
                        {(() => {
                            for (var i=0; i<nationality.length; i++) {
                                
                                if (nationality[i].id == formData.father_nationality){
                                    return (
                                        <>{nationality[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        {/* {formData.father_nationality} */}
                        
                    </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Birth Country</label> 
                    
                </Col>
                <Col md={2}>
                <p className="slider-text">
                        {(() => {
                            for (var i=0; i<countries.length; i++) {
                                
                                if (countries[i].id == formData.father_birth_country){
                                    return (
                                        <>{countries[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        {/* {formData.father_birth_country} */}
                    </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Country Of Residence</label> 
                    
                </Col>
                <Col md={2}>
                <p className="slider-text">
                        {(() => {
                            for (var i=0; i<countries.length; i++) {
                                
                                if (countries[i].id == formData.father_residence_country){
                                    return (
                                        <>{countries[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                        {/* {formData.father_residence_country} */}
                    </p>
                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's District</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">
                    {(() => {
                            for (var i=0; i<districts.length; i++) {
                                
                                if (districts[i].id == formData.father_district){
                                    return (
                                        <>{districts[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                    {/* {formData.father_district} */}
                </p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's County</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">
                    {(() => {
                        for (var i=0; i<counties.length; i++) {
                            
                            if (counties[i].id == formData.father_county){
                                return (
                                    <>{counties[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                    {/* {formData.father_county} */}
                    
                </p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Sub County</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">
                    {(() => {
                        for (var i=0; i<subcounties.length; i++) {
                            
                            if (subcounties[i].id == formData.father_sub_county){
                                return (
                                    <>{subcounties[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                    {/* {formData.father_sub_county} */}
                </p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Parish</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">
                    {(() => {
                        for (var i=0; i<parishes.length; i++) {
                            
                            if (parishes[i].id == formData.father_parish){
                                return (
                                    <>{parishes[i].name}</>
                                )
                                
                            }
                            
                        }
                    })()}
                    {/* {formData.father_parish} */}
                </p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Plot Number</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.father_plot_number}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Tribe</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.father_tribe}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Primary Phone Number</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.father_primary_telephone_number}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Other Phone Number</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.father_other_telephone_number}</p>

                </Col>
                <Col md={2}>
                    <label className="slider-label">Father's Email</label> 
                </Col>
                <Col md={2}>
                <p className="slider-text">{formData.father_email_address}</p>

                </Col>
            </Row>
        </div>
    </>;
}



export default ParentsSlider;