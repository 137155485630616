import react, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/HomeMenu.css";
import "../styles/Global.css";
import "../styles/CtdHome.css";
import "../styles/Application.css";
import "../styles/Status.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";
import constants from "../constants/constants";
import axios from "axios";
import DatePicker from "react-date-picker";
import { StringToDateConverter } from "../services/Services";
import { GlobalStateContext } from "../context/index";

const HomeMenu = () => {
  const [showpassport, setShowPassport] = useState(false);
  const [showctd, setShowCtd] = useState(false);
  const [showcheckstatus, setShowStatus] = useState(false);

  const [applicationnumber, setApplicantNumber] = useState("");
  const [dob, setDOB] = useState("");
  const [surname, setSurname] = useState("");

  const handlePassportClose = () => setShowPassport(false);
  const handlePassportShow = () => setShowPassport(true);
  const handleCtdClose = () => setShowCtd(false);
  const handleCtdShow = () => setShowCtd(true);
  const handleShowCheckStatus = () => setShowStatus(true);
  const handleCheckStatusClose = () => setShowStatus(false);

  const [ctdInstruction, setCTDInstruction] = useState({});

  useEffect(() => {
    axios
      .get(constants.url + "/common/instruction/")
      .then((res) => {
        setCTDInstruction(res.data[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const CheckApplicationStatus = async () => {
    let firstChar = applicationnumber.charAt(0);
    let months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    let date =
      dob.getFullYear() + "-" + months[dob.getMonth()] + "-" + dob.getDate();
    var data = {
      application_id: applicationnumber,
      date_of_birth: date,
      surname: surname,
    };

    // console.log(data);

    if (firstChar.toLowerCase() === "t") {
      // console.log("ctd");
      axios
        .post(constants.url + "/applications/application-status/", data)
        .then((res) => {
          for (var key in res.data) {
            if (res.data[key] === null) {
              sessionStorage.setItem(key, "");
            } else {
              sessionStorage.setItem(key, res.data[key]);
            }
          }

          window.location.href = "/ctd/status";
        })
        .catch((e) => {
          alert(
            "Application Not Found \nPlease Check Your Details And Try Again"
          );
        });
    } else if (firstChar.toLowerCase() === "w") {
      window.location.href = constants.opas + "/check_status";
    } else {
      alert("Invalid Application Number");
    }
  };

  return (
    <>
      <Navbar className="menu-container navbar-dark" expand="lg">
        <Container className="mynav">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className=" mx-auto text-center">
              <Nav.Link className="menu-item text-white" href="/">
                Home
              </Nav.Link>
              <NavDropdown
                className="dropmenu"
                title="How To Apply"
                id="howto-dropdown"
              >
                <NavDropdown.Item onClick={handlePassportShow}>
                  For Passport
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleCtdShow}>
                  For Conventional Travel Document
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                className="dropmenu"
                title={<span className="">All Travel Documents</span>}
                id="home-dropdown"
              >
                <NavDropdown.Item
                  className="dropdown-items"
                  href={constants.opas_start}
                >
                  Passport
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className="dropdown-items" href="/ctd">
                  Conventional Travel Document
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className="dropdown-items" disabled href="#">
                  Interstate Pass
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className="dropdown-items" disabled href="#">
                  Temporary Movement Pass
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className="dropdown-items" disabled href="#">
                  Emergency Travel Document
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className="dropdown-items" disabled href="#">
                  Certificate Of Identification
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                className="menu-item text-white"
                onClick={handleShowCheckStatus}
              >
                Check Status
              </Nav.Link>
              <Nav.Link
                className="menu-item text-white"
                href="https://www.immigration.go.ug/contact-us"
                target="_blank"
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        centered
        size="xl"
        show={showpassport}
        onHide={handlePassportClose}
        contentClassName="model-content-design"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="model-header-content" closeButton>
          <div className="d-flex align-items-center d-flex justify-content-between w-100">
            <Modal.Title className="model-title-style2 px-5">
              PROCEDURE FOR PASSPORT APPLICATION
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="model-body-content">
          <div className="buttongroup">
            <div className="howtogroup px-2">
              <div className="row my-auto">
                <div className="col-12">
                  <p className="howtotext">
                    Please follow the following steps to make an online passport
                    application:
                  </p>
                </div>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 1:</span> Navigate to the
                  passport portal.
                </p>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 2:</span> Click the "Start
                  New Application” link to start a new application.
                </p>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 3:</span> Accept the terms
                  and conditions displayed in the Disclaimer.{" "}
                </p>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 4:</span> Answer simple
                  security questions.
                </p>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 5:</span> Complete the
                  passport application form.{" "}
                </p>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 6:</span> Print the Passport
                  Application Payment Advice Form.
                </p>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 7:</span> Make payments for
                  the selected type of passport using the selected payment
                  method.{" "}
                </p>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 8:</span> Schedule an
                  appointment for a date of your convenience using the “Schedule
                  Appointment” link on the home page.
                </p>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 9:</span> Print the Passport
                  Application Appointment Form.
                </p>
                <p className="howtotext">
                  {" "}
                  <span className="howtobold">Step 10:</span> Report to the
                  selected passport center with the passport appointment form
                  and the appropriate application requirements displayed on the
                  website for enrollment.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        size="xl"
        show={showctd}
        onHide={handleCtdClose}
        contentClassName="model-content-design"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="model-header-content" closeButton>
          <div className="d-flex align-items-center d-flex justify-content-between w-100">
            <Modal.Title className="model-title-style2 px-5">
              PROCEDURE FOR CONVENTIONAL TRAVEL DOCUMENT
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="model-body-content">
          <div className="buttongroup">
            {/* <p className='model-title-style2'>PROCEDURE FOR CONVENTIONAL TRAVEL DOCUMENT</p> */}
            <div className="howtogroup px-2">
              <div className="row my-auto">
                <div className="col-12">
                  <p className="howtotext">
                    Please follow the following steps to make an online
                    conventional travel document application:
                  </p>
                </div>

                <div
                  className="text-display"
                  dangerouslySetInnerHTML={{ __html: ctdInstruction?.text }}
                ></div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        size="xl"
        show={showcheckstatus}
        onHide={handleCheckStatusClose}
        contentClassName="model-content-design"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="model-header-content" closeButton>
          <div className="d-flex align-items-center d-flex justify-content-between w-100">
            <Modal.Title className="model-title-style2 px-5">
              CHECK APPLICATION STATUS
            </Modal.Title>
          </div>
        </Modal.Header>

        <Modal.Body className="model-body-content">
          <div className="buttongroup">
            <div className="howtogroup w-100 px-5">
              <div className="row my-auto">
                <div className="col-12 mini-form-container">
                  <div className="form-group required">
                    <label className="mylabel control-label">
                      Application Number
                    </label>
                    <input
                      type="text"
                      className={`form-control myinput`}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      value={applicationnumber}
                      onChange={(event) =>
                        setApplicantNumber(event.target.value)
                      }
                    />
                  </div>
                  <div className="form-group required">
                    <label className="mylabel control-label">
                      Date Of Birth
                    </label>
                    <DatePicker
                      className="form-control p-0 myinput m-0"
                      value={StringToDateConverter(dob)}
                      onChange={(date) => setDOB(date)}
                      format="dd-MM-y"
                      minDate={new Date("1900-01-01")}
                      maxDate={new Date("2050-01-01")}
                      placeholderText={"dd-MM-yyyy"}
                      clearIcon={null}
                    />
                    <div className="form-group required">
                      <label className="mylabel control-label">Surname</label>
                      <input
                        type="text"
                        className={`form-control myinput`}
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={surname}
                        onChange={(event) => setSurname(event.target.value)}
                      />
                    </div>
                    <Button
                      className="buttontextsize next-btn  btn-grad-secondary resume-btn  my-4 m-0"
                      variant=""
                      size="lg"
                      onClick={CheckApplicationStatus}
                    >
                      Check Application Status
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HomeMenu;
