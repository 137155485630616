import React, { useRef, useContext, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import "../styles/PrintPreview.css";
import Barcode from 'react-barcode';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dcic from '../assets/img/dcic.png';
import coatofarms from '../assets/img/coatofarms.png';
import {GlobalStateContext} from "../context/index";
import constants from '../constants/constants';
import axios from 'axios';
import * as pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ComponentToPrint = React.forwardRef(({data}, ref) => {

    
    var [previewdata, setPreviewData] = useState({});

    const {formData} = useContext(GlobalStateContext);
    const [nationality, setNationality] = useState([]);
    const [counties, setCounties] = useState([]);
    const [subcounties, setSubcounties] = useState([]);
    const [prn, setPRN] = useState({});
    const [loading, setLoading] = useState(false);
    const [applicationData, setApplicationData] = useState({});
    const [reservationdetails, setReservationDetails] = useState();
    const [timeSlot, setTimeSlot] = useState(0);
    

    function dateToYMD(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    useEffect(() => {
        var formdata = {}

        var formdata = JSON.parse(sessionStorage.getItem("application"))
        setApplicationData(formdata)

        var date = new Date(sessionStorage.getItem("appointment_date"))

        setPreviewData(formdata);

        // Get Application Details
        var application = {}
        axios.get(constants.url+"/applications/applications/"+formdata.id+"/")
        .then(res => {
            // console.log(res.data.appointment.appointment_date)
            setReservationDetails(res.data.appointment.appointment_date)
            application = res.data
            setPRN(res.data)
            setLoading(false);
        })
        .then(res => {
            axios.get(constants.url+'/reservations/appointment-slots/')
            .then(res => {
                console.log(console.log(prn))
                // setTilecontent(res.data)
                for (var i=0; i<res.data.length; i++){
                    if (res.data[i].id == application.appointment.time_slot){
                        setTimeSlot(res.data[i].slot_name)
                        break;
                    }
                }
            })
        })

        axios.get(constants.url+"/common/nationalities/")
        .then(res => {
            setNationality(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        axios.get(constants.url+"/common/counties/")
        .then(res => {
            setCounties(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        axios.get(constants.url+"/common/sub-counties/")
        .then(res => {
            setSubcounties(res.data)
        })
        .catch(err => {
            console.error(err)
        })

        // sessionStorage.clear();

    }, [])

    
    

    const Test = () => {
        console.log(data)
    }

    if (loading) {
        return <p>Loading...</p>
    }
    
    return <>
        
        <div className="container print-container" ref={ref}>
            
            <div className="row">
                <div className='col-12'>
                    <div className='print-heading'>
                        <img className="print-image" src={dcic} alt="Logo" />
                        <div className='headertext'>
                            <p className="mainheadertext">THE REPUBLIC OF UGANDA<br/>MINISTRY OF INTERNAL AFFAIRS</p>
                            <p className="systemtitle">DIRECTORATE OF CITIZENSHIP & IMMIGRATION CONTROL</p>
                        </div>
                        <img className="print-image" src={coatofarms} alt="Logo" />
                    </div>
                </div>

                <div className="col-6 print-header">Applicant</div>
                <div className="col-6 print-header">{previewdata.surname} {previewdata.given_name} {previewdata.other_names}</div>
                <div className="col-6 print-header">Appointment Date</div>
                <div className="col-6 print-header">{reservationdetails}</div> 
                <div className="col-6 print-header">Appointment Time</div>
                <div className="col-6 print-header">{timeSlot}</div> 
                <div className="col-6 print-header">Place of Submission</div>
                <div className="col-6 print-header">Kampala</div> 
                <div className="col-12 print-text barcode"><Barcode height="80" width="3" value={previewdata.application_id} /></div>
                <div className="col-12 print-text print-label">APPLICATION DETAILS</div>
                <Row>
                    <Col xs={8}>
                        <div className="row">

                            <div className="col-6 print-text">Application Number</div>
                            <div className="col-6 print-text">{previewdata.application_id}</div>
                            <div className="col-6 print-text">Applicant Name</div>
                            <div className="col-6 print-text">{previewdata.surname} {previewdata.given_name} {previewdata.other_names}</div>
                            <div className="col-6 print-text">Date Of Birth</div>
                            <div className="col-6 print-text">{previewdata.date_of_birth}</div>
                            <div className="col-6 print-text">Telephone Number</div>
                            <div className="col-6 print-text">{previewdata.phone_number}</div>
                            <div className="col-6 print-text">Email</div>
                            <div className="col-6 print-text">{previewdata.email_address}</div>
                            <div className="col-6 print-text">Signature</div>
                            <div className="col-6 print-text print-signature"></div>

                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="photo-area">
                            <p className="photo-text">
                                PASSPORT PHOTO  <br/>
                                PLEASE PASTE <br/>
                                DO NOT STAPLE <br/>
                                THE PHOTO SHOULD <br/>
                                BE COLOURED WITH A WHITE <br/>
                                BACKGROUND
                            </p>
                            
                        </div>
                    </Col>

                </Row>
                
                <div className="col-12 print-text printer-border"></div>
                <div className="col-6 print-text">Type of Application</div>
                <div className="col-6 print-text">{previewdata.application_type}</div>
                <div className="col-6 print-text">Place of Submission</div>
                <div className="col-6 print-text">Kampala</div> 
                <div className="col-6 print-text">Place of Collection</div>
                <div className="col-6 print-text">Kampala</div> 
                <div className="col-12 mt"></div>

                <div className="col-12 print-text print-label">PAYMENT DETAILS</div>
                <table className="print-table">
                    <thead>
                        <tr className="print-table-header print-text tr-bottom">
                            <th className="print-text">Description</th>
                            <th className="print-text">PRN</th>
                            <th className="print-text">Amount</th>
                        </tr>
                        
                    </thead>
                    <tbody className="mt">
                        <tr className="tr-bottom-2">
                            <td className="print-text">CTD</td>
                            {
                                (()=>{
                                    try {
                                        return <td className="print-text">{prn.prn.prn}</td>
                                    }
                                    catch (e){
                                        return (
                                            <td className="print-text"></td>
                                        )
                                    }
                                })()
                            }
                            {
                                (()=>{
                                    try {
                                        return <td className="print-text">{prn.prn.amount}</td>
                                    }
                                    catch (e){
                                        return (
                                            <td className="print-text"></td>
                                        )
                                    }
                                })()
                            }
                            {/* <td className="print-text">{prn.prn.prn}</td> */}
                            {/* <td className="print-text">{prn.prn.amount}</td> */}
                        </tr>
                        <tr className="tr-background">
                            <td className="print-label print-text ">TOTAL PAID</td>
                            <td className="print-label print-text "></td>
                            {/* <td className="print-label print-text ">{prn.prn.amount}</td> */}
                            {
                                (()=>{
                                    try {
                                        return <td className="print-text">{prn.prn.amount}</td>
                                    }
                                    catch (e){
                                        return (
                                            <td className="print-text"></td>
                                        )
                                    }
                                })()
                            }
                            <td className="print-label print-text "></td>
                        </tr>
                        

                    </tbody>
                </table>

                <div className="col-12 mt print-break"></div>
                <div className="col-12 print-text print-label">NEXT OF KIN DETAILS</div>
                <div className="col-12 print-text print-label">Your next of kin must sign and attach a copy of their ID</div>
                <div className="col-6 print-text">Next Of KIN Name</div>
                <div className="col-6 print-text">{previewdata.next_of_kin_given_name} {previewdata.next_of_kin_surname}</div>
                <div className="col-6 print-text">Next Of KIN Nationality</div>
                <div className="col-6 print-text">
                        {(() => {
                            for (var i=0; i<nationality.length; i++) {
                                
                                if (nationality[i].id == previewdata.next_of_kin_nationality){
                                    return (
                                        <>{nationality[i].name}</>
                                    )
                                    
                                }
                             
                            }
                        })()}
                </div>
                <div className="col-6 print-text">Address</div>
                <div className="col-6 print-text">
                    {(() => {
                        var address = previewdata.next_of_kin_foreign_address;

                        for (var i=0; i<counties.length; i++) {
                                
                            if (counties[i].id == previewdata.next_of_kin_county){
                                address = address+" "+counties[i].name
                                break;
                            }
                        }

                        for (var i=0; i<subcounties.length; i++) {
                                
                            if (subcounties[i].id == formData.next_of_kin_sub_county){
                                address = address+" "+subcounties[i].name
                                break;
                            }
                        }

                        return (
                            <>{address}</>
                        )

                    })()}
                </div>
                <div className="col-6 print-text">ID Number</div>
                <div className="col-6 print-text">{previewdata.next_of_kin_id_number}</div>
                <div className="col-6 print-text">Phone Number</div>
                <div className="col-6 print-text">{previewdata.next_of_kin_primary_telephone_number}</div>
                <div className="col-6 print-text">Next Of KIN Signature</div>
                <div className="col-6 print-text print-signature"></div>

                <div className="col-12 mt"></div>
                <div className="col-12 print-text print-label">CTD APPLICATION REQUIREMENTS</div>
                <div className="col-12 print-text print-label">You must bring along the following supporting documents for your appointment</div>
                <div className="col-12 print-text">All Applicants</div>
                <div className="col-12 print-text">
                    <ul>
                        <li>Recommendation Letter from OPM</li>
                        <li>Birth Certificates in the case of children</li>
                        <li>Copy of Refugee ID</li>
                        <li>Make payments as described in the fees structure</li>
                        <li>Family Attestation Letter</li>
                        <li>Receipt</li>
                    </ul>
                </div>
                <div className="col-12 print-text">ADDITIONAL ATTACHMENTS</div>
                <div className="col-12 print-text">Renewal</div>
                <div className="col-12 print-text">
                    <ul>
                        <li>Old CTD</li>
                    </ul>
                </div>
                
                <div className="col-12 print-text">Lost CTD Replacement</div>
                <div className="col-12 print-text">
                    <ul>
                        <li>Affidavit</li>
                        <li>Police Letter</li>
                        <li>Payment of fine of Shs 100,000 for the lost CTD</li>
                    </ul>
                </div>
                
                <div className="col-12 print-text">Getting a CTD for a minor (Under 18)</div>
                <div className="col-12 print-text">
                    <ul>
                        <li>Birth Certificate</li>
                        <li>Parents IDs</li>
                        <li>Any of the parents copy of CTDs</li>
                        <li>Letter of concent from parent</li>
                    </ul>
                </div>
                
            </div>
        </div>
    </>;
})

const AppointmentPreview = ({data}) => {
    const componentRef = useRef();
    const [appid, setAppid] = useState("");
    const [applicationData, setApplicationData] = useState({});
    const [newapplicationData, setNewApplicationData] = useState({});

    // useEffect(()=>{

    //     var applicationdata = JSON.parse(sessionStorage.getItem("application"))
    //     setApplicationData(applicationdata)

    //     axios.get(constants.url+"/applications/applications/"+applicationdata.id+"/")
    //     .then(res => {
            
    //         setNewApplicationData(res.data)
    //     })

    // },[])



    const clearMemory = () => {
        sessionStorage.clear();
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const DownloadPDF = () => {
        axios.get(constants.url+"/applications/print-application-pdf/"+applicationData.id+"/")
        .then(res => {
            console.log(res)
        })
        .catch(e => {
            console.log(e)
            alert("An Error Occured While Saving Document \nPlease Use Print Instead")
        })


    }

    return <div className="container">
        <button className="btn-grad-secondary btn-padding print-btn" onClick={()=>{
            handlePrint();
            clearMemory();
        }}>Print</button>
        {/* <button className="btn-grad-primary btn-padding print-btn" disabled onClick={() => {
            DownloadPDF();
            clearMemory();
        }}>Save</button> */}
        <ComponentToPrint data={newapplicationData} ref={componentRef} />
        
    </div>;
}

export default AppointmentPreview;