import axios from "axios";
import constants from "../constants/constants";

// const baseURL = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000";
const baseURL = constants.url;

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 100000,
  headers: {
    Authorization: sessionStorage.getItem("access")
      ? "Bearer " + sessionStorage.getItem("access")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    //check if response is really defined before use
    if (typeof error.response == "undefined") {
      return Promise.reject(error);
    }

    // Prevent infinite loops

    if (
      error.response.status === 401 &&
      originalRequest.url === "/token/refresh/"
    ) {
      window.location.href = "/login/";
      return Promise.reject(error);
    }

    if (
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      const refreshToken = sessionStorage.getItem("refresh");
      console.log(refreshToken)
      if (refreshToken) {
        
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        //console.log(tokenParts.exp);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post("/token/refresh/", { refresh: refreshToken })
            .then((response) => {
              console.log(response)
              sessionStorage.setItem("access", response.data.access);
              // sessionStorage.setItem("refresh", response.data.refresh);

              axiosInstance.defaults.headers["Authorization"] =
                "JWT " + response.data.access;
              originalRequest.headers["Authorization"] =
                "JWT " + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              //console.log(err);
            });
        } else {
          //console.log("Refresh token is expired", tokenParts.exp, now);
          window.location.href = "/login";
        }
      } else {
        console.log("Refresh token not available.");
        window.location.href = "/login";
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;