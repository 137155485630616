import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../styles/calenderView.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Calender Dependancies
// import {Calendar, dateFnsLocalizer, momentLocalizer} from "react-big-calendar";
// import {format, parse, startOfWeek, getDay} from "date-fns";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import DatePicker from 'react-datepicker';
// import moment from 'moment'

import { differenceInCalendarDays } from 'date-fns';
import constants from '../../constants/constants';
import axiosInstance from '../../services/axiosInstance';
import Button from 'react-bootstrap/Button'; 
import { AiFillSchedule } from "react-icons/ai";



const CalenderView = ({showPreview,handlePreviewClose, data}) => {

    const [value, setValue] = useState();
    // const [weekend, setWeekend] = useState(false);
    // Holiday sat states
    const [holidays, setHolidays] = useState([]);
    const [tilecontent, setTilecontent] = useState([]);

    // Confirmation Slot Modals
    const [open, setOpen] = useState(false);

    const disabledDates = [new Date("2022-11-25"), new Date("2022-11-27"), new Date("2022-11-30")];
    const datesToAddContentTo = [new Date("2033-11-26"), new Date("2022-11-28"), new Date("2022-12-1"), new Date("2022-12-5"), new Date("2022-12-12"), new Date("2022-12-13"), new Date("2022-12-16")];

    // Required Field States
    const [date, setDate] = useState(new Date());

    // Time Slots
    const [timeslots, setTimeslots] = useState([]);

    // Select Slot
    const [selectslot, setSelectslot] = useState();


    useEffect(() => {
        axiosInstance.get(constants.url+"/reservations/holidays/",
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        })
        .then(res => {
            var holiday = []
            var holidayname = []

            for (var i=0; i<res.data.length; i++){
                holiday.push(new Date(res.data[i].date))
                holidayname.push({"name": res.data[i].name, "date": new Date(res.data[i].date)})
            }
            // console.log(res.data)
            setHolidays(holiday)
            setTilecontent(holidayname)
        })

        axiosInstance.get(constants.url+'/reservations/appointment-slots/', 
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        }
        )
        .then(res => {
    
            setTimeslots(res.data)
            
        })


    },[])
    
    const isSameDay = (a, b) => {
        return differenceInCalendarDays(a, b) === 0;
    }

    const isWeekend = (date = new Date()) => {
        return date.getDay() === 6 || date.getDay() === 0;
    }

    const tileDisabled = ({ date, view }) => {
        
        if (view === 'month') {
            // console.log(holidays.find(dDate => isSameDay(dDate, date)))
            if (holidays.find(dDate => isSameDay(dDate, date)) !== undefined || isWeekend(date) == true) {
                return date
            }
            // return holidays.find(dDate => isSameDay(dDate, date));
        }
    }

    function tileClassName({ date, view }) {

        // Add className to tiles in month view only

        if (view === 'month') {

          // Check if a date React-Calendar wants to check is on the list of dates to add className to

          if (holidays.find(dDate => isSameDay(dDate, date)) !== undefined ) {
            // || isWeekend(date) === true
            return `calenderTile tileBg`;

          }

          return `calenderTile`;

        }
    }
      

    function tileContent({ date, view }) {
        
        if (view === 'month') {
            // datesToAddContentTo

            var x =  holidays.find(dDate => isSameDay(dDate, date))
            // console.log(x)

            if (x !== undefined) {
                var name = "";
                for (let i = 1; i < tilecontent.length; i++) {
                    

                    if (isSameDay(x,tilecontent[i].date)){
                        name = tilecontent[i].name
                    }
                   
                }
                return name;
            }
        
        //   if (holidays.find(dDate => isSameDay(dDate, date))) {
        //     return <progress className="calender-progress" id="file" value="32" max="100"> </progress>;
        //   }

        }
    }

    // Confirmation View Close
    const handleConfirmationClose = () => {
        setOpen(false)
    }

    // Select Date
    const handleDateChange = (date) => {

        setDate(date)
        setOpen(true)
    }

    // Schedule Applicant
    const ScheduleApplicant = () => {

        if (selectslot == undefined){
            console.log("Time Slot Not selected")
        }
        else {
            
            var month = parseInt(date.getMonth()) + 1
            var datestring = date.getFullYear() + '-' + month + '-' + date.getDate()
            console.log(data.application_id)
            
            // var scheduledata = {
            //     "application": data.id, "application_id":data.application_id , "appointment_date": datestring, "time_slot": selectslot
            // }

            var scheduledata = {
                "application": data.id, 
                "appointment_date": datestring, 
                "time_slot": selectslot
            }

            console.log(scheduledata)
            axiosInstance.post(constants.url+'/reservations/reservations/', scheduledata)
            .then(res => {
                if (res.status == 201){
                    setOpen(false)
                    handlePreviewClose()
                }
                else {
                    console.log(res.data)
                    console.log("Scheduling Failed")
                }  
            })
        }
    }


    return <>
        
        <Modal size="xl" centered show={showPreview} onHide={handlePreviewClose} contentClassName="portal-content-design" aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Header className="model-header-content" closeButton>
                <Modal.Title className="model-title-style calender-title-style" >Schedule Appointment For {data.given_name} { } { data.surname }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                {/* setValue */}
                    <Calendar 
                        onChange={event => {handleDateChange(event)}} 
                        className="calender-view" 
                        minDate={new Date()} 
                        value={value} 
                        tileClassName={tileClassName}
                        tileDisabled={tileDisabled} 
                        tileContent={tileContent}
                        />
                    
                </div>
            </Modal.Body>
        </Modal>

        <Modal centered show={open} onHide={handleConfirmationClose} contentClassName="portal-content-design" aria-labelledby="example-modal-sizes-title-lg">

            <Modal.Header className="model-header-content" closeButton>
                <Modal.Title className="model-title-style" >Please Select The Time Slot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <label className="slider-label">Name</label> 
                    </Col>
                    <Col md={6}>
                        <p className="slider-text">{data.given_name} {data.surname}</p>
                    </Col>
                    <Col md={6}>
                        <label className="slider-label">Application Number</label> 
                    </Col>
                    <Col md={6}>
                        <p className="slider-text">{data.application_id}</p>
                    </Col>
                    <Col md={6}>
                        <label className="slider-label">Date</label> 
                    </Col>
                    <Col md={6}>
                        <p className="slider-text">{date.toLocaleDateString()}</p>
                    </Col>
                    <Col md={6}>
                        <label className="slider-label">Time Slot</label> 
                    </Col>
                    <Col md={6}>
                        <div className="">
                            {/* <label className="mylabel control-label">Nationality</label> */}
                            <select className={`custom-select form-control myinput slotselect`} id="inputGroupSelect01"
                            value={selectslot} 
                            onChange={(event) => setSelectslot(event.target.value)}>
                                <option selected>-------</option>
                                {timeslots.map((option, index) => (
                                    <option key={index} value={option.id}>{option.start_time} {"-"} {option.end_time}</option>
                                ))}
                            </select>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {/* <p>Footer</p> */}
                <Button 
                    onClick={ScheduleApplicant}
                    className="buttontextsize btn-grad-secondary"
                    size="lg">
                        <AiFillSchedule size={25} className="slider-icon"/> 
                        Schedule Applicant
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}


export default CalenderView;