import React, {useState, useEffect, useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
import ReCAPTCHA from "react-google-recaptcha";
import constants from '../../constants/constants';
import axios from 'axios';
import {GlobalStateContext} from "../../context/index";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { telephoneChecker, emailChecker } from '../../services/Services';

const RefugeeForm = ({formData,setFormData, setRefugeeform, refugeeform}) => {

    const [email, setEmail] = useState(false)
    
    const { recaptcha, setRecaptcha,emailvalidation, setEmailValidation, telephoneValidation, settelephoneValidation,telephoneCheck, security, setSecurity} = useContext(GlobalStateContext)

    const recaptchaRef = React.createRef();

    const MyCaptcha = (value) => {
        console.log("Captcha value:", value);
        setRecaptcha(true);

    }

    const [phonestatus, setPhonestatus] = useState(false)
    const [emailstatus, setEmailstatus] = useState(false)

    const validatephone = (phone) => {
        var check = telephoneChecker(phone)
        setPhonestatus(check)
    }

    const validateEmail = (email) => {
        var check = emailChecker(email)
        setEmailstatus(check)
    }    

    return <div className="form-container">
        <Container>
            <Row>
                <Col md={12}>
                    <div className="form-group required">
                        <label className={`mylabel control-label`}>Refugee Number</label> 
                        <input type="text" className={`form-control myinput ${refugeeform.refugeeid ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default" 
                        value={formData.refugee_No} 
                        onChange={(event) => setFormData({...formData, refugee_No: event.target.value})}
                        />
                        { refugeeform.refugeeid ?  <p className="error-message">Refugee Number Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className={`mylabel control-label`}>Email</label>
                        <input placeholder="example@email.com" type="text" className={`form-control myinput ${refugeeform.email_address ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.email_address} 
                        onChange={(event) => setFormData({...formData, email_address: event.target.value})}
                        onBlur={(event) => validateEmail(event.target.value)}
                        />
                        {emailstatus ? <p className="error-message">Incorrect Email Format</p> : null}
                        { refugeeform.email_address ? <p className="error-message">Email Address Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Phone Number</label>
                        <PhoneInput
                            country={'ug'}
                            inputClass={`phone-input ${refugeeform.phonenumber ? 'error': ''}`}
                            onBlur={(event) => validatephone(event.target.value)}
                            value={formData.phone_number}
                            onChange={(event) => setFormData({...formData, phone_number: event})}
                            />
                        { refugeeform.phonenumber ? <p className="error-message">Phone Number Required</p> : null}
                        { phonestatus ? <p className="error-message">Phone Number Should Be In The Format +2567xxxxxxxx</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Security Question</label>
                        <select className={`custom-select form-control myinput ${refugeeform.securityquestion ? 'error': ''} `} id="inputGroupSelect01"
                        value={formData.security_question} 
                        onChange={(event) => setFormData({...formData, security_question: event.target.value})}
                        >
                            <option value="" selected>-------</option>
                            {
                                security.map((option, index) => (
                                    <option key={option.id} value={option.id}>{option.text}</option>
                                ))
                            }
        
                        </select>
                        {refugeeform.securityquestion ? <p className="error-message">Security Question Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Security Answer</label>
                        <input type="text" className={`form-control myinput ${refugeeform.securityanswer ? 'error': ''}`} aria-label="Default" aria-describedby="inputGroup-sizing-default"
                        value={formData.security_answer} 
                        onChange={(event) => setFormData({...formData, security_answer: event.target.value})}
                        />
                        { refugeeform.securityanswer ? <p className="error-message">Security Answer Required</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <ReCAPTCHA
                            sitekey="{process.env.GOOGLE_RECAPTCHA_SITE_KEY}"
                            onChange={MyCaptcha}
                        />
                        { recaptcha === false ?  <p className="error-message">Please Check The Box</p> : null}
                    </div>
                </Col>
                <Col md={12}>
                    
                </Col>
            </Row>
        </Container>

    </div>;
}


export default RefugeeForm;