import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/Global.css';
import '../styles/CtdHome.css';
import HomeMenu from '../components/HomeMenu';
import HomeButton from '../components/HomeButton';

const CtdHome = () => {

    const CTDApplication = (e) => {
        window.location.href = "/ctd/application";
    }

    const CTDResume = (e) => {
        window.location.href = "/ctd/resume";
    }

    const CTDStatus = (e) => {
        window.location.href = "/ctd/status";
    }

    const CTDSchedule = (e) => {
        window.location.href = "/ctd/schedule";
        // console.log("Schedule");
    }

    return (
        
        <>
            <div className="home-container">
                <HomeMenu/>
                <Container>
                    <Row className="justify-content-md-center">

                        <Col md={12}>

                            <div className="">

                                <p className="innertext">Conventional Travel Document</p>

                                <div className="buttongroup">

                                    <div className="row my-auto">
                                        <div className="col-md-6 text-center">
                                            <HomeButton btntype="btn-grad" clickeventhandler={CTDApplication} title="Apply" body="Apply for conventional Travel Document" name="Start New Application" variant="success"/>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <HomeButton btntype="btn-grad-secondary" clickeventhandler={CTDResume} title="Continue" body="Continue Application" name="Resume Saved Application" variant="success"/>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <HomeButton btntype="btn-grad-secondary" clickeventhandler={CTDStatus} title="Status" body="Continue Application" name="Check Application Status" variant="primary"/>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <HomeButton btntype="btn-grad" clickeventhandler={CTDSchedule} title="Schedule" body="Schedule Appointment" name="Schedule Appointment" variant="primary"/>
                                        </div>
                                        
                                    </div>

                                </div>

                                
                            </div>


                            {/* </div> */}

                        </Col>

                        


                    </Row>
                </Container>
                
            </div>
            
            
        </>
        
    );
}

export default CtdHome;