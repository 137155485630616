import React, {useEffect, useState} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../styles/calenderView.css';
import { differenceInCalendarDays } from 'date-fns';
import constants from '../constants/constants';
import axios from 'axios';

export default function CalenderPublic() {

    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(new Date());
    const [value, setValue] = useState();
    const [holidays, setHolidays] = useState([]);
    const [tilecontent, setTilecontent] = useState([]);

    const [selectslot, setSelectslot] = useState();
    const [data, setData] = useState([]);

    useEffect(() => {

        // Holiday List
        axios.get(constants.url+'/reservations/holidays/')
        .then(res => {
            console.log(res.data)
            setHolidays(res.data)
        })

        axios.get(constants.url+'/reservations/appointment-slots/')
        .then(res => {
            console.log(res.data)
            // setTilecontent(res.data)
        })

        // Reservation Slots
        // axios.get(constants.url+'/reservations/reservations/')
        // .then(res => {
        //     console.log(res.data)
        //     // setTilecontent(res.data)
        // })

    },[])

    // Schedule Applicant
    const ScheduleApplicant = () => {

        if (selectslot == undefined){
            console.log("Time Slot Not selected")
        }
        else {
            
            var month = parseInt(date.getMonth()) + 1
            var datestring = date.getFullYear() + '-' + month + '-' + date.getDate()
            console.log(data.application_id)
            
            // var scheduledata = {
            //     "application": data.id, "application_id":data.application_id , "appointment_date": datestring, "time_slot": selectslot
            // }

            // var scheduledata = {
            //     "application": data.id, 
            //     "appointment_date": datestring, 
            //     "time_slot": selectslot
            // }

            // console.log(scheduledata)
            // axiosInstance.post(constants.url+'/reservations/reservations/', scheduledata)
            // .then(res => {
            //     if (res.status == 201){
            //         setOpen(false)
            //         handlePreviewClose()
            //     }
            //     else {
            //         console.log(res.data)
            //         console.log("Scheduling Failed")
            //     }  
            // })
        }
    }

    function dateToYMD(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    const handleDateChange = (date) => {
        // setDate(date)
        // setOpen(true)
        // console.log(date)
        sessionStorage.setItem('appointment_date', date)
        var formdata = JSON.parse(sessionStorage.getItem("application"))
        var scheduledata = {
            "application": formdata.id, 
            "appointment_date": dateToYMD(date), 
            // "time_slot": selectslot
        }
        axios.post(constants.url+'/reservations/reservations/', scheduledata)
        .then(res => {
            if (res.status == 201){
                // console.log(res.data)
                // console.log("Success")
                // setReservationDetails(res.data)
                window.location.href = '/ctd/schedule/print'
            }
            else {
                // console.log(res.data)
                console.log("Failed")
                console.log("Scheduling Failed")
                alert("Scheduling Failed \n Please try again later")
            }  
        })
        // window.location.href = '/ctd/schedule/print'
    }

    const isSameDay = (a, b) => {
        return differenceInCalendarDays(a, b) === 0;
    }

    const isWeekend = (date = new Date()) => {
        return date.getDay() === 6 || date.getDay() === 0;
    }

    function tileContent({ date, view }) {
        
        if (view === 'month') {
            // datesToAddContentTo

            var x =  holidays.find(dDate => isSameDay(dDate, date))
            // console.log(x)

            if (x !== undefined) {
                var name = "";
                for (let i = 1; i < tilecontent.length; i++) {
                    

                    if (isSameDay(x,tilecontent[i].date)){
                        name = tilecontent[i].name
                    }
                   
                }
                return name;
            }
        
        }
    }

    const tileDisabled = ({ date, view }) => {
        
        if (view === 'month') {
            // console.log(holidays.find(dDate => isSameDay(dDate, date)))
            if (holidays.find(dDate => isSameDay(dDate, date)) !== undefined || isWeekend(date) == true) {
                return date
            }
            // return holidays.find(dDate => isSameDay(dDate, date));
        }
    }

    function tileClassName({ date, view }) {

        // Add className to tiles in month view only

        if (view === 'month') {

          // Check if a date React-Calendar wants to check is on the list of dates to add className to

          if (holidays.find(dDate => isSameDay(dDate, date)) !== undefined ) {
            // || isWeekend(date) === true
            return `calenderTile tileBg`;

          }

          return `calenderTile`;

        }
    }

    return (
        <Calendar 
            onChange={event => {handleDateChange(event)}} 
            className="calender-view" 
            minDate={new Date()} 
            value={value} 
            tileClassName={tileClassName}
            tileDisabled={tileDisabled} 
            tileContent={tileContent}
            />
    )
}
