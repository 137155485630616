import React, {useEffect, useState, useContext} from 'react';
import '../../styles/Global.css';
import '../../styles/Application.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import constants from '../../constants/constants';
import {GlobalStateContext} from "../../context/index";

const PaymentForm = ({formData,setFormData, paymentform, setpaymentform}) => {

    const [banks, setBanks] = useState([]);
    const {
        NameToID
    } = useContext(GlobalStateContext)

    useEffect(()=>{
        axios.get(constants.url+"/common/banks/")
        .then(res => {
            setBanks(res.data)
        })
        .catch(e => {
            console.log(e)
        })
    },[])

    console.log(formData.paymentmode)

    return <div className="form-container">
        <Container>
            <Row>
            <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Payment Mode</label>
                        <select className={`custom-select form-control myinput ${paymentform.paymentmode ? 'error': ''}`} id="inputGroupSelect01"
                        value={formData.paymentmode} 
                        onChange={(event) => setFormData({...formData, paymentmode: event.target.value})}>
                            <option value="" selected>-------</option>
                            <option value="Bank">Bank</option>
                            {/* <option value="Mobile Money">Mobile Money</option> */}
                        </select>
                        { paymentform.paymentmode ? <p className="error-message">Payment Mode Required</p> : null}

                    </div>
                </Col>
                <Col md={12}>
                    <div className="form-group required">
                        <label className="mylabel control-label">Payment Bank</label>
                        <select className={`custom-select form-control myinput ${paymentform.paymentbank ? 'error': ''}`} id="inputGroupSelect01"
                        value={NameToID(formData.bank, banks)} 
                        onChange={(event) => setFormData({...formData, bank: event.target.value})}>
                            <option value="" selected>-------</option>
                            {banks.map((option, index) => (
                                <option key={index} value={option.id}>{option.name}</option>
                            ))}
                            
                        </select>
                        { paymentform.bank ? <p className="error-message">Payment Bank Required</p> : null}

                    </div>
                </Col>
            </Row>
        </Container>
    </div>;
}

export default PaymentForm;