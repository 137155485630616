import React from 'react';
import '../styles/Application.css';
import '../styles/Error404.css';
import Button from 'react-bootstrap/Button'; 


const Error404 = () => {
    return (
    <div className='error-container'>
        <div>
            <h1 className='error-text error-header'>404</h1>
            <h2 className='error-text error-sub'>Page Not Found</h2>
            <p className='error-text error-body'>Sorry, the page you are looking for does not exist.</p>
            {/* <Button 
                onClick={() => {window.location.href = '/'}} 
                className="error-btn" 
                size="lg">Go to Home</Button> */}
            <div 
            className='error-btn-container'
            onClick={() => {window.location.href = '/'}}
            >
                <button className='error-btn'>
                    Go to Home
                </button>
            </div>
            
        </div>
    </div>
    );
}


export default Error404;