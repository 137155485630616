import React, {useEffect, useState} from 'react';
import Card from '../Card';
import "../../styles/Portal.css";
import "../../styles/Global.css";
import Table from "../Table";
import {GlobalStateContext} from "../../context/index";
import constants from '../../constants/constants';
import axios from 'axios';
import axiosInstance from '../../services/axiosInstance';

const Rejected = () => {

    const [data, setData] = useState([]);

    const columns = [ 
        // { Header: '#', accessor: 'id' }, 
        { Header: 'Application', accessor: 'application_id' }, 
        { Header: 'Surname', accessor: 'surname' }, 
        { Header: 'Given Name', accessor: 'given_name' },  
        { Header: 'Refugee No', accessor: 'refugee_No' },
        { Header: 'Application Type', accessor: 'application_type' },
        { Header: 'Nationality', accessor: 'nationality' },
        { Header: 'Phone Number', accessor: 'phone_number' },
        { Header: 'Place Of Submission', accessor: 'place_of_submission' },
        { Header: 'Sex', accessor: 'sex' },

    ]

    useEffect(() => {

        axiosInstance.get(constants.url+'/opm/rejected-applications/', 
        {
            headers : {
                "Authorization": "Bearer " + sessionStorage.getItem("access")
            }
        }
        )
        .then(res => {
            setData(res.data.results)
        })
    },[])

    

    return (
        <div className="inner-portal">
            <Card >
                <div className="card-container">
                    <Table columns={columns} data={data} title="Table of Deferred Applications" />
                </div>
            </Card>
        </div>
    );
}



export default Rejected;