import './App.css';
import './styles/Global.css';
import React, {useContext} from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import ResumeApplication from './screens/ResumeApplication';
import ApplicationForm from './screens/ApplicationForm';
import CtdHome from './screens/CtdHome';
import Header from './components/Header';
import Footer from './components/Footer';
import {GlobalStateContext} from "./context/index";
import Portal from "./screens/portal/Portal";
import Login from "./screens/auth/Login";
import RequireAuth from "./components/RequireAuth";
import OTP from "./screens/auth/OTP";
import PrintPreview from "./screens/PrintPreview";
import StatusHome from "./screens/status/StatusHome";
import Error404 from './screens/Error404';
import Passporthowto from './screens/howto/passporthowto';
import Ctdhowto from './screens/howto/Ctdhowto';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import ScheduleAppointment from './screens/ScheduleAppointment';
import AppointmentPreview from './screens/AppointmentPreview';


function App() {


  return (
    <Router>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/ctd" element={<CtdHome/>}/>
          <Route path="/ctd/application" element={<ApplicationForm/>}/>
          <Route path="/ctd/application/print/" element={<PrintPreview/>}/>
          <Route path="/ctd/resume" element={<ResumeApplication/>}/>
          <Route path="/ctd/login" element={<Login/>} />
          <Route path="/otp" element={<OTP/>} />
          <Route path="/ctd/backoffice" element={<RequireAuth><Portal/></RequireAuth>}/>
          <Route path="/ctd/status" element={<StatusHome/>}/>
          <Route path="/howto/passport/" element={<Passporthowto/>}/>
          <Route path="/howto/ctd/" element={<Ctdhowto/>}/>
          <Route path="/ctd/schedule" element={<ScheduleAppointment/>}/>
          <Route path="/ctd/schedule/print" element={<AppointmentPreview/>}/>
          <Route path="*" element={<Error404/>}/>
        </Routes>
        <Footer/>
      </div>
      
    </Router>
    
  );
}

export default App;
